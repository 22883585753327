import { Row } from "@enymo/react-layout";
import React from "react";
import { channelAvatar, initials } from "../common";
import { SocialChannel } from "../resources";
import AvatarWithLogo from "./AvatarWithLogo";
import Tooltip, { TooltipParent } from "./Tooltip";

export default function SocialChannelInfo({
    channels,
    maxDisplay
}: {
    channels: SocialChannel[],
    maxDisplay: number
}) {
    const aboveLimit = channels.length - maxDisplay;

    return (
        <Row gap="5px" vAlign="center">
            {channels.slice(0, maxDisplay).map(({id, has_avatar, name, icon_type}) => (
                <TooltipParent key={id}>
                    <AvatarWithLogo src={channelAvatar({id, has_avatar})} size={24} type={icon_type} initials={initials(name)} />
                    <Tooltip>{name}</Tooltip>
                </TooltipParent>
            ))}
            {aboveLimit > 0 && (
                <span>+ {aboveLimit}</span>
            )}
        </Row>
    )
}