import Medium from "@/js/Components/Medium";
import { useObjectUrl } from "@/js/Hooks/ObjectUrlHook";
import { usePostParams } from "@/js/Pages/Main/Campaigns/Posts/Post";
import { Post, PostMedium } from "@/js/resources";
import TrashSolidIcon from "@/svg/trash-solid.svg?react";
import TriangleExclamationIcon from "@/svg/triangle-exclamation-solid.svg?react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { assertNotNull } from "@enymo/ts-nullsafe";
import classNames from "classnames";
import React, { useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import "react-quill/dist/quill.snow.css";
import route from "ziggy-js";
import Tooltip, { TooltipParent } from "../Tooltip";

export function LoadingMediaComponent() {
    const { screenSize } = usePostParams();
    return (
        <div className={classNames("media-component", { mobile: screenSize === "mobile" })}>
            <Medium loading />
        </div>
    )
}

export function UploadingMediaComponent({
    medium,
    progress,
}: {
    medium: PostMedium,
    progress: number,
}) {
    const objectUrl = useObjectUrl(medium.file);
    const { screenSize } = usePostParams();

    return (
        <div className={classNames("media-component", { mobile: screenSize === "mobile" })}>
            <Medium
                src={objectUrl}
                assetType={medium.media_type}
            />
            <div className="progress-bar" style={{ width: `${progress}%` }} />
        </div>
    )
}

export function MediaComponent({
    onDelete,
    medium,
    postSettings
}: {
    onDelete: () => void,
    medium: PostMedium,
    postSettings?: Post["settings"]
}) {
    const {t} = useTranslation();
    assertNotNull(medium.id, "The media component needs an already uploaded medium.");
    const { screenSize } = usePostParams();

    const filteredRestrictions = useMemo(() => medium.restrictions?.filter(restriction => {
        switch (restriction) {
            case "facebook.page.story":
            case "facebook.instagram.story":
                return postSettings?.facebook?.story ?? false;
            case "facebook.page.reel":
            case "facebook.instagram.reel":
                return postSettings?.facebook?.reel ?? false;
            default:
                return true;
        }
    }), [postSettings, medium]);

    const {
        attributes,
        transform,
        transition,
        listeners,
        setNodeRef,
        isDragging: dragging,
    } = useSortable({
        id: medium.id,
    })

    const style = {
        transform: CSS.Transform.toString(transform),
        transition: transition,
    }

    return (
        <div 
            className={classNames("media-component", "draggable", { dragging, mobile: screenSize === "mobile" })} 
            ref={setNodeRef} 
            style={style}
        >
            <Medium
                src={route("posts.media.file", {
                    post: medium.post_id,
                    medium: medium.id,
                })}
                assetType={medium.media_type}
                dragging={dragging}
                {...attributes}
                {...listeners}
            />
            <button className="remove-media-button" onClick={onDelete}>
                <TrashSolidIcon />
            </button>
            {filteredRestrictions && filteredRestrictions.length > 0 && (
                <TooltipParent className="post-restriction-warning">
                    <a href="https://www.example.com" target="_blank">
                        <TriangleExclamationIcon />
                        <Tooltip>
                            <Trans i18nKey="posts.create.media.restrictions">
                                This medium cannot be posted as the following, as it does not meet the file requirements:
                                <ul>
                                    {filteredRestrictions.map(restriction => (
                                        <li>{t(`posts.create.media.restrictions.${restriction}`)}</li>
                                    ))}
                                </ul>
                                Click to learn more about the requirements for media files.
                            </Trans>
                        </Tooltip>
                    </a>
                </TooltipParent>
            )}
        </div>
    )
}