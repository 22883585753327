import { Column } from "@enymo/react-layout";
import React from "react";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router";
import Menu, { MenuItem } from "../Components/Menu";
import { useTitle } from "./Main";

export default function Hashtags({children}: {
    children?: React.ReactNode
}) {
    const {t} = useTranslation();

    useTitle(t("hashtags"));

    return (
        <Column gap="50px" padding="25px 50px" flex={1}>
            <Menu>
                <MenuItem to="/app/hashtags/lists">{t("hashtags.lists")}</MenuItem>
                <MenuItem to="/app/hashtags/research">{t("hashtags.research")}</MenuItem>
            </Menu>
            {children ?? <Outlet />}
        </Column>
    )
}