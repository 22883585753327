import "@/css/pages/main/campaigns/settings/posting-times.scss";
import MonthDaySelect from "@/js/Components/MonthDaySelect";
import MonthSelect from "@/js/Components/MonthSelect";
import MultiDateInput from "@/js/Components/MultiDateInput";
import WeekDaySelect from "@/js/Components/WeekDaySelect";
import WeekTimeInput, { WeekTimes, weekTimeInputIsValid } from "@/js/Components/WeekTimeInput";
import useBreakpoint from "@/js/Hooks/BreakpointHook";
import { useToaster } from "@/js/Providers/ToasterProvider";
import { months, weekdays } from "@/js/common";
import { Button, Input, SlideSwitch, SlideSwitchItem } from "@/js/glidespec";
import { useCampaigns } from "@/js/resources";
import { Form, SubmitHandler } from "@enymo/react-form-component";
import { Column, Row } from "@enymo/react-layout";
import { isNotNull, requireNotNull } from "@enymo/ts-nullsafe";
import React from "react";
import { useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { useParams } from "react-router";

interface Submit {
    frequency: "daily" | "weekly" | "monthly" | "yearly",
    daily_posts: number,
    interval: number,
    posting_times: WeekTimes<string>,
    excluded_months?: typeof months[number][], 
    weekdays?: typeof weekdays[number][],
    days_of_month?: (number | "last")[],
    dates?: {
        date: string
    }[]
}

export default function PostingTimes() {
    const {t} = useTranslation();
    const toast = useToaster();
    const [campaign ,{update}] = useCampaigns({id: Number(requireNotNull(useParams().campaignId))});
    const mobile = useBreakpoint(768);
    const form = useForm<Submit>({
        defaultValues: {
            daily_posts: 1,
            frequency: "daily"
        },
        values: isNotNull(campaign) ? {
            ...campaign,
            dates: campaign.dates?.map(({day, month}) => ({date: `${day.toString().padStart(2, "0")}.${month.toString().padStart(2, "0")}.`})),
            daily_posts: Math.max(1, ...Object.values(campaign.posting_times).map(value => value.length))
        } : undefined
    });
    const frequency = form.watch("frequency");
    const postsPerDay = form.watch("daily_posts");
    const dates = form.watch("dates");

    const handleSubmit: SubmitHandler<Submit> = async data => {
        const {dates, ...rest} = data;
        await update({
            ...rest,
            dates: dates?.map(({date}) => {
                const [, day, month] = date.match(/(\d{2})\.(\d{2})\./)!;
                return {
                    day: Number(day),
                    month: Number(month)
                };
            })
        });
        toast({
            type: "success",
            title: t("campaigns.settings.saved"),
            text: t("campaigns.settings.postingTimes.saved.text"),
            duration: 5000
        })
    }

    return (
        <Form form={form} onSubmit={handleSubmit}>
            <Row padding={mobile ? "35px 16px" : "35px 50px"}>
                <Column gap="40px" flex={1}>
                    <div className="posting-times-divider-row">
                        <Column gap="30px" maxWidth="443px" width="100%">
                            <SlideSwitch label={t("frequency")} name="frequency">
                                <SlideSwitchItem value="daily">{t("frequency.daily")}</SlideSwitchItem>
                                <SlideSwitchItem value="weekly">{t("frequency.weekly")}</SlideSwitchItem>
                                <SlideSwitchItem value="monthly">{t("frequency.monthly")}</SlideSwitchItem>
                                <SlideSwitchItem value="yearly">{t("frequency.yearly")}</SlideSwitchItem>
                            </SlideSwitch>
                            <Row gap="10px" vAlign="center">
                                <Trans i18nKey={`campaigns.settings.postingTimes.interval.${frequency}`}>
                                    Every
                                    <Input style={{width: "100px"}} name="interval" type="number" min={0} step={1} options={{
                                        required: t("input.required.short"),
                                        valueAsNumber: true
                                    }} />
                                    day
                                </Trans>
                            </Row>
                            {frequency === "weekly" && (
                                <WeekDaySelect label={t("campaigns.settings.postingTimes.weekdays")} name="weekdays" />
                            )}
                            {frequency === "monthly" && (
                                <MonthDaySelect label={t("campaigns.settings.postingTimes.monthDays")} name="days_of_month" />
                            )}
                            {frequency === "yearly" ? (
                                <MultiDateInput label={t("campaigns.settings.postingTimes.dates", {count: dates?.length})} name="dates" />
                            ) : (
                                <MonthSelect label={t("campaigns.settings.postingTimes.monthsToExclude")} name="excluded_months" />
                            )}
                        </Column>
                        <Column gap="30px" maxWidth="900px">
                            <Row>
                                <SlideSwitch name="daily_posts" label={t("campaigns.settings.postingTimes.postsPerDay")}>
                                    {Array<void>(5).fill().map((_, index) => (
                                        <SlideSwitchItem key={index} value={index + 1}>{index + 1}</SlideSwitchItem>
                                    ))}
                                </SlideSwitch>
                            </Row>
                            <WeekTimeInput inputsPerDay={postsPerDay} label={t("campaigns.settings.postingTimes.postingTimes")} name="posting_times" options={{
                                validate: value => weekTimeInputIsValid(value) || t("campaigns.settings.postingTimes.postingTimes.required")
                            }} />
                        </Column>
                    </div>
                    <Row align="end">
                        <Button variant="primary" submit>{t("save")}</Button>
                    </Row>
                </Column>
            </Row>
        </Form>
    )
}