import "@/css/components/lockscreen-logout.scss";
import RightFromBracketIcon from "@/svg/right-from-bracket-solid.svg?react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { useUser } from "../Providers/UserProvider";

export default function LockscreenLogout() {
    const {t} = useTranslation();
    const {logout} = useUser();
    const navigate = useNavigate();

    const handleClick = async () => {
        await logout();
        navigate("/login");
    }

    return (
        <button type="button" className="lockscreen-logout" onClick={handleClick}>
            <span>{t("logout")}</span>
            <RightFromBracketIcon />
        </button>
    )
}