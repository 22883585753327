import "@/css/components/week-time-input.scss";
import useHybridInput from "@enymo/react-hybrid-input-hook";
import { Column } from "@enymo/react-layout";
import classNames from "classnames";
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { weekdays } from "../common";
import { FormInputProps } from "../types";
import Error from "./Error";

export type WeekTimes<T extends string | null> = Record<typeof weekdays[number], T[]>;
interface WeekTimeInputProps extends FormInputProps<WeekTimes<string | null>> {
    label?: string,
    inputsPerDay: number
}

export function weekTimeInputIsValid(value: WeekTimes<string | null>) {
    return !Object.values(value).some(index => index.some(value => value === null));
}

export default function WeekTimeInput({
    name,
    value: externalValue,
    onChange: externalOnChange,
    options,
    error: errorProp,
    label,
    inputsPerDay
}: WeekTimeInputProps) {
    const {t} = useTranslation();
    const {value, onChange, error: formError} = useHybridInput({name, externalValue, externalOnChange, options, defaultValue: useMemo(() => Object.fromEntries(weekdays.map(weekday => [weekday, Array(inputsPerDay).fill(null)])) as WeekTimes<string | null>, [inputsPerDay])});
    const error = errorProp ?? formError?.message;

    const handleChange = (weekday: typeof weekdays[number], index: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
        onChange({
            ...value,
            [weekday]: value[weekday].map((indexValue, currentIndex) => currentIndex === index ? e.target.value || null : indexValue)
        });
    }

    useEffect(() => {
        if (Object.values(value).some(value => value.length !== inputsPerDay)) {
            onChange(Object.fromEntries(Object.entries(value).map(([weekday, value]) => [weekday, Array<void>(inputsPerDay).fill().map((_, index) => value[index] ?? `${(8 + index).toString().padStart(2, "0")}:00`)])) as WeekTimes<string | null>);
        }
    }, [value, inputsPerDay, onChange])

    return (
        <Column gap="20px">
            {label && <label className="input-label">{label}</label>}
            <div className={classNames("week-time-input-grid", {error: errorProp || formError})}>
                {weekdays.map((weekday, index) => (
                    <Column gap="5px">
                        <span key={index}>{t(`weekdays.${weekday}.short`)}</span>
                        {Array<void>(inputsPerDay).fill().map((_, index) => (
                            <input
                                type="time"
                                key={index * weekdays.length + weekday}
                                value={value[weekday][index] ?? ""}
                                onChange={handleChange(weekday, index)}
                            />
                        ))}
                    </Column>
                    
                ))}
            </div>
            {error && <Error error={error} />}
        </Column>
    )
}