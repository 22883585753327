import CampaignHeader from "@/js/Components/CampaignHeader";
import ConfirmPopup from "@/js/Components/ConfirmPopup";
import SearchField from "@/js/Components/SearchField";
import { Style } from "@/js/Components/Style";
import Table from "@/js/Components/Table";
import TableActions from "@/js/Components/TableActions";
import { useTitle } from "@/js/Layouts/Main";
import { useUser } from "@/js/Providers/UserProvider";
import { convertTimezone, deleteLinkedListItem, months, resourceDragAndDropSorting, sortLinkedList } from "@/js/common";
import { Button } from "@/js/glidespec";
import { useCampaigns, usePosts, useUsers } from "@/js/resources";
import plans from "@/json/plans.json";
import InfoIcon from "@/svg/circle-info-solid.svg?react";
import PhotoIcon from "@/svg/photo-film-regular.svg?react";
import { findToggledElement } from "@enymo/glide";
import { Column, Row } from "@enymo/react-layout";
import useScreenSize from "@enymo/react-screen-size-hook";
import { assertNotNull, requireNotNull } from "@enymo/ts-nullsafe";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";

export default function Posts() {
    const { t } = useTranslation();
    const { campaignId } = useParams();
    const navigate = useNavigate();
    assertNotNull(campaignId, "A campaign id must be provided on this page");
    const firstBreakpoint = 1250;
    const secondBreakpoint = 768;
    const { width: screenWidth } = useScreenSize();
    const isSmallScreen = screenWidth < firstBreakpoint;
    const isMobile = screenWidth < secondBreakpoint;

    const [searchText, setSearchText] = useState("");
    useTitle(t("posts"));

    const [postToDeleteId, setPostToDeleteId] = useState<number | null>(null);

    const [campaign] = useCampaigns({id: Number(campaignId)});
    const [campaignOwner] = useUsers({id: campaign?.owner.id ?? null, autoRefresh: !!campaign});
    const [posts, { store, update, destroy, loading }] = usePosts({
        params: useMemo(() => ({
            campaign: campaignId,
        }), [campaignId]),
    });
    const canActivate = useMemo(() => campaignOwner ? (plans[campaignOwner.subscription_tier].posts_per_campaign - posts.reduce((sum, post) => post.active ? sum + 1 : sum, 0)) > 0 : false, [campaignOwner, posts]);
    const {user} = useUser();
    assertNotNull(user, "User must be logged in");

    const sortedPosts = useMemo(() => sortLinkedList(posts), [posts]);

    const filteredPosts = useMemo(
        () => searchText ? sortedPosts.filter(post => post.first_text?.toLowerCase().includes(searchText.toLowerCase())) : sortedPosts,
        [posts, searchText]
    );

    const activePosts = useMemo(() => posts.filter(post => post.active).map(post => post.id), [posts]);

    const handleToggleActive = (state: number[]) => {
        const toggled = findToggledElement(activePosts, state);
        update(toggled, { active: !activePosts.includes(toggled) }, "immediate");
    }

    const handleCreatePost = async () => {
        const result = await store();
        navigate(`/app/campaigns/${campaignId}/posts/${result.id}`);
    }

    const handleDeletePost = () => {
        deleteLinkedListItem(posts, requireNotNull(postToDeleteId), update, destroy);
        setPostToDeleteId(null);
    }

    const formatDate = (date: Date) => {
        return t("posts.table.lastPostedAt.formattedDate", {
            day: date.getDate().toString().padStart(2, "0"),
            month: t(`months.${months[date.getMonth()]}`),
            hour: user.language === "de" ? date.getHours().toString().padStart(2, "0") : (date.getHours() % 12 === 0 ? "12" : (date.getHours() % 12).toString().padStart(2, "0")),
            minute: date.getMinutes().toString().padStart(2, "0"),
            ampm: date.getHours() < 12 ? "AM" : "PM",
        })
    }

    return (
        <Column flex={1}>
            <CampaignHeader campaignId={Number(campaignId)} postSettings />
            <Column padding={isSmallScreen ? isMobile ? "25px 0" : "25px" : "40px 50px"} gap="30px">
                <Row align="space" padding={isMobile ? "0 16px" : undefined}>
                    <Button variant="primary" onClick={handleCreatePost}>{t(isSmallScreen ? "posts.new.mobile" : "posts.new")}</Button>
                    <SearchField
                        placeholder={t(isSmallScreen ? "search" : "posts.search.placeholder")}
                        value={searchText}
                        onChange={setSearchText}
                        maxWidth={isSmallScreen ? "200px" : undefined}
                    />
                </Row>
                <Table
                    mobileBreakpoint={firstBreakpoint}
                    paddingVertical="12px"
                    dataLoading={loading}
                    head={isSmallScreen ? [
                        { label: t("posts.table.postText"), fillWidth: true },
                        { label: t("actions.mobile") }
                    ] : [
                        { label: t("posts.table.postText"), fillWidth: true },
                        { label: t("posts.table.numImages") },
                        { label: t("posts.table.lastPostedAt") },
                        { label: t("actions") },
                    ]}
                    rows={filteredPosts.map(post => {
                        const postTextNote = (post.editing_state === "draft" || post.dirty) && (
                            <Style color="var(--text-500)" fontSize="15px">{t(`posts.table.${post.editing_state}.note`)}</Style>
                        );
                        return {
                            id: post.id,
                            switcherDisabled: (() => {
                                if (!campaign || !campaignOwner) {
                                    return true;
                                }
                                
                                if (!post.active) {
                                    if (
                                        !canActivate 
                                        || post.text_count > plans[campaignOwner?.subscription_tier].texts_per_post
                                        || post.media_count > plans[campaignOwner?.subscription_tier].images_per_post
                                        || (!plans[campaignOwner?.subscription_tier].stories && post.settings.facebook?.story)
                                    ) {
                                        return campaignOwner.id === user.id ? t("planExceeded.owner") : t("planExceeded.share");
                                    }
                                }
                                return post.editing_state === "draft" ? t("posts.table.draftCannotBeActivated") : false;
                            })(),
                            data: isSmallScreen ? [
                                    <Column gap="8px">
                                        <div style={{
                                            display: "-webkit-box",
                                            WebkitBoxOrient: "vertical",
                                            maxHeight: "40px",
                                            WebkitLineClamp: "2",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "normal",
                                            cursor: "pointer",
                                            color: "var(--text-700)",
                                        }} onClick={() => navigate(`/app/campaigns/${campaignId}/posts/${post.id}`)}>
                                            {postTextNote} <span className="blue-on-hover">{post.first_text}</span>
                                        </div>
                                        <Row gap="10px">
                                            <PhotoIcon width="20px" fill="var(--primary-500)" style={{ flexShrink: "0" }} />
                                            <Style color="var(--text-600)">{t("posts.table.media", { number: post.media_count })}</Style>
                                        </Row>
                                        <Style fontWeight="400" fontSize="14px" color="var(--text-600)">
                                            {t("posts.table.lastPostedAt.withDate", {
                                                date: post.last_posted_at ? formatDate(convertTimezone(post.last_posted_at, user.timezone)) : "-"
                                            })}
                                        </Style>
                                    </Column>,
                                    <TableActions
                                        edit={{ to: `/app/campaigns/${campaignId}/posts/${post.draft_id ?? post.id}` }}
                                        delete={{ onClick: () => setPostToDeleteId(post.id) }}
                                    />,
                            ] : [
                                <span onClick={() => navigate(`/app/campaigns/${campaignId}/posts/${post.id}`)} style={{
                                    cursor: "pointer",
                                    color: "var(--text-700)",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                }}>
                                    {postTextNote} <span className="blue-on-hover">{post.first_text}</span>
                                </span>,
                                <Row gap="10px" vAlign="center">
                                    <PhotoIcon width="20px" fill="var(--primary-500)" />
                                    <span>{post.media_count}</span>
                                </Row>,
                                post.last_posted_at ? (
                                    formatDate(convertTimezone(post.last_posted_at, user.timezone))
                                ) : "-",
                                <TableActions
                                    edit={{ icon: "pen", to: `/app/campaigns/${campaignId}/posts/${post.id}` }}
                                    delete={{ onClick: () => setPostToDeleteId(post.id) }}
                                />,
                            ],
                        }
                    })}
                    dividerRows={[
                        { index: 0, label: t("posts.table.nextPost") },
                        { index: 1, label: t("posts.table.afterwards") },
                    ]}
                    switcherHead={t("posts.table.status")}
                    onSortingChanged={posts.length === filteredPosts.length ? resourceDragAndDropSorting(posts, update) : undefined}
                    onSwitcherSwitched={handleToggleActive}
                    switcherValues={activePosts}
                    placeholder={searchText === "" ? t("posts.table.noPosts") : t("posts.table.noPosts.search")}
                />
                {!isMobile && <Row gap="8px" vAlign="center">
                    <InfoIcon fill="var(--primary-400)" width="20px" />
                    <Style
                        color="var(--text-700)"
                        fontSize="14px"
                    >
                        {t("posts.table.sortingInfo")}
                    </Style>
                </Row>}
                {postToDeleteId !== null && (
                    <ConfirmPopup
                        variant="warning"
                        title={t("posts.deletePopup.title")}
                        confirmText={t("posts.deletePopup.button")}
                        onCancel={() => setPostToDeleteId(null)}
                        onConfirm={handleDeletePost}
                    >
                        {t("posts.deletePopup.text")}
                    </ConfirmPopup>
                )}
            </Column>
        </Column>
    )
}