import "@/css/pages/settings/payment-success.scss";
import { usePaymentSuccess } from "@/js/Layouts/Subscription";
import { Button } from "@/js/glidespec";
import CheckIcon from "@/svg/circle-check-solid.svg?react";
import WarningIcon from "@/svg/triangle-exclamation-solid.svg?react";
import { Column, Row } from "@enymo/react-layout";
import React from "react";
import { useTranslation } from "react-i18next";
import { Navigate } from "react-router";

export default function PaymentSuccess() {

    const { t } = useTranslation();

    const [paymentSuccess, setPaymentSuccess] = usePaymentSuccess();

    if (paymentSuccess === null) {
        return <Navigate to="/app/settings/subscription" replace />;
    }
    return (
        <Column gap="30px" hAlign="center" className="payment-successful">
            {paymentSuccess !== "failed" ? (
                <CheckIcon width="70px" height="70px" fill="var(--green)" />
            ) : (
                <WarningIcon width="70px" height="61px" fill="var(--error)" />
            )}
            <Column gap="20px" align="center" hAlign="center" maxWidth="580px">
                <h1>{t(`settings.paymentSuccess.${paymentSuccess}`)}</h1>
                <p style={{ textAlign: "center" }}>{t(`settings.paymentSuccess.${paymentSuccess}.text`)}</p>
                <Row align="center">
                    <Button 
                        onClick={() => setPaymentSuccess(null)}
                        to={paymentSuccess !== "failed" ? "/app/dashboard" : "/app/settings/subscription"}
                        variant="primary"
                    >
                        {t(`settings.paymentSuccess.${paymentSuccess}.button`)}
                    </Button>
                </Row>
            </Column>
        </Column>
    )
}