import React from "react";
import { CampaginsProvider, SocialAccountsProvider, SocialChannelsProvider, UsersProvider } from "../resources";

export default function CommonResourcesProvider({children}: {
    children: React.ReactNode
}) {
    return (
        <UsersProvider>
            <CampaginsProvider>
                <SocialAccountsProvider>
                    <SocialChannelsProvider>
                        {children}
                    </SocialChannelsProvider>
                </SocialAccountsProvider>
            </CampaginsProvider>
        </UsersProvider>
    )
}