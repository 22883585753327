import FacebookLogo from "@/svg/facebook-logo.svg?react";
import InstagramLogo from "@/svg/instagram-logo.svg?react";
import LinkedInLogo from "@/svg/linkedin-logo.svg?react";
import TikTokLogo from "@/svg/tiktok-logo.svg?react";
import YouTubeLogo from "@/svg/youtube-logo.svg?react";
import React from "react";
import { SocialAccountType } from "../types";

interface SocialMediaIconProps extends React.SVGProps<SVGSVGElement> {
    type: SocialAccountType,
    alwaysShowYT?: boolean
}

export default function SocialMediaIcon({type, alwaysShowYT = false, ...props}: SocialMediaIconProps) {
    switch (type) {
        case "facebook":
            return <FacebookLogo {...props} />;
        case "instagram":
            return <InstagramLogo {...props} />;
        case "tiktok":
            return <TikTokLogo {...props} />;
        case "youtube":
            return (import.meta.env.VITE_BRAND_CORRECTNESS_MODE === "false" || alwaysShowYT) && <YouTubeLogo {...props} />;
        case "linkedin":
            return <LinkedInLogo {...props} />;
    }
}