import TriangleExclamationIcon from "@/svg/triangle-exclamation-duotone.svg?react";
import { Form, SubmitHandler } from "@enymo/react-form-component";
import { Column, Row } from "@enymo/react-layout";
import { isNotNull, requireNotNull } from "@enymo/ts-nullsafe";
import * as Sentry from "@sentry/react";
import { AxiosError } from "axios";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import route from "ziggy-js";
import { useApp } from "../Providers/AppProvider";
import { useToastControl, useToaster } from "../Providers/ToasterProvider";
import { useUser } from "../Providers/UserProvider";
import { Button, EmailInput, Input } from "../glidespec";
import { SentryListener, addSentryListener, removeSentryListener } from "../sentry";
import Popup, { PopupFooter } from "./Popup";

interface Submit {
    name?: string,
    email?: string,
    comments: string
}

function ToastBody({onFeedback}: {
    onFeedback(): void
}) {
    const {close} = useToastControl();

    const handleFeedback = () => {
        close();
        onFeedback();
    }

    return (
        <Trans i18nKey="error.generic.text">
            Our team has been informed. You can help us solve the problem by <Button variant="link" onClick={handleFeedback}>providing Feedback</Button>
        </Trans>
    )
}

export default function ErrorHandler() {
    const {t} = useTranslation();
    const {user} = useUser();
    const {axios} = useApp();
    const [sentryId, setSentryId] = useState<string | null>(null);
    const [eventType, setEventType] = useState<"frontend" | "backend">("frontend");
    const toast = useToaster();
    const form = useForm<Submit>();

    const handleEvent = (eventId: string, eventType: "frontend" | "backend") => {
        toast({
            type: "error",
            duration: null,
            title: t("error.generic.title"),
            text: <ToastBody onFeedback={() => {
                setEventType(eventType);
                setSentryId(eventId);
            }} />
        });
    }

    const handleSubmit: SubmitHandler<Submit> = ({name, email, comments}) => {
        const feedback = {
            event_id: requireNotNull(sentryId, "Cannot send feedback without sentryId"),
            name: isNotNull(user) ? t("user.fullName", {
                firstName: user.first_name,
                lastName: user.last_name
            }) : requireNotNull(name, "Name needed if not logged in"),
            email: user?.email ?? requireNotNull(email, "Email needed if not logged in"),
            comments
        };
        if (eventType === "frontend") {
            Sentry.captureUserFeedback(feedback);
        }
        else {
            axios.post(route("sentry.report"), feedback);
        }
        setSentryId(null);
    }

    useEffect(() => {
        const listener: SentryListener = (event, hint) => {
            if (hint.originalException instanceof AxiosError && hint.originalException.response?.data.sentry_id) {
                handleEvent(hint.originalException.response.data.sentry_id, "backend");
                return null;
            }
            else if (event.event_id !== undefined) {
                handleEvent(event.event_id, "frontend");
            }
            return event;
        }
        addSentryListener(listener);
        return () => removeSentryListener(listener);
    }, [setSentryId]);

    if (sentryId === null) {
        return null;
    }

    return (
        <Popup onBackgroundClick={() => setSentryId(null)} width="500px">
            <Form form={form} onSubmit={handleSubmit}>
                <Column gap="30px" padding="30px 25px 50px 25px">
                    <Row gap="15px" vAlign="center">
                        <TriangleExclamationIcon width="40px" height="35px" className="warning" />
                        <h1>{t("feedback.title")}</h1>
                    </Row>
                    <p>{t("feedback.text")}</p>
                    {user === null && <>
                        <Input name="name" label={t("errorFallback.name")} options={{
                            required: t("errorFallback.name.required")
                        }} />
                        <EmailInput options={{
                            required: t("input.email.required")
                        }} />
                    </>}
                    <Input 
                        name="comments" 
                        type="textarea" 
                        className="textarea"
                        label={t("errorFallback.feedback")} 
                        placeholder={t("errorFallback.feedback.placeholder")}
                        options={{
                            required: t("errorFallback.feedback.required")
                        }}
                    />
                </Column>
                <PopupFooter height="80px" vAlign="center" gap="35px" align="end" padding="0 25px">
                    <Button variant="secondary" onClick={() => setSentryId(null)}>{t("cancel")}</Button>
                    <Button variant="primary" submit>{t("feedback.submit")}</Button>
                </PopupFooter>
            </Form>
        </Popup>
    )
}