import "@/css/pages/main/admin/ai-settings.scss";
import Loadable from "@/js/Components/Loadable";
import { useTitle } from "@/js/Layouts/Main";
import { aiStyles } from "@/js/common";
import { Button, Input } from "@/js/glidespec";
import { useAISettings } from "@/js/resources";
import { Form, SubmitHandler, setFormValues } from "@enymo/react-form-component";
import { Column, Row } from "@enymo/react-layout";
import classNames from "classnames";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

interface AISettingsForm {
    ai_text: string;
    clone_rephrase: string;
    funny_tone: string;
    inspirational_tone: string;
    informative_tone: string;
    provocative_tone: string;
    supportive_tone: string;
    narrative_tone: string;
    analytical_tone: string;
    advocatory_tone: string;
    networking_tone: string;
}

export default function AISettings() {
    const { t } = useTranslation();
    useTitle(t("sidebar.admin.ai"));

    const [settings, { loading, update }] = useAISettings({
        id: "single"
    });

    const form = useForm<AISettingsForm>();

    const handleSubmit: SubmitHandler<AISettingsForm> = async (data) => {
        await update(data);
    }

    useEffect(() => {
        if (settings) {
            setFormValues(form, { ...settings });
        }
    }, [form.reset, settings])

    return (
        <Loadable loading={loading} fade>
            <Form form={form} onSubmit={handleSubmit}>
                <Column padding="60px 50px" gap="50px" className="ai-settings" maxWidth="700px">
                    <Column gap="30px">
                        <Input
                            className={classNames("textarea", "ai-text")}
                            label={t("admin.ai.aiText")}
                            name="ai_text"
                            type="textarea"
                        />
                        <Input
                            className={classNames("textarea", "clone-rephrase")}
                            label={t("admin.ai.cloneRephrase")}
                            name="clone_rephrase"
                            type="textarea"
                        />
                    </Column>
                    <Column gap="30px">
                        <h2>{t("admin.ai.textStyles")}</h2>
                        {aiStyles.map((style, index) => (
                            <Input
                                key={index}
                                name={`${style}_tone`}
                                type="textarea"
                                className={classNames("textarea", "tone")}
                                label={t(`ai.style.${style}`)}
                            />
                        
                        ))}
                    </Column>
                    <Row align="end">
                        <Button variant="primary" submit>{t("save")}</Button>
                    </Row>
                </Column>
            </Form>
        </Loadable>
    );
}