import "@/css/pages/main/dashboard.scss";
import useBreakpoint from "@/js/Hooks/BreakpointHook";
import { useTitle } from "@/js/Layouts/Main";
import { useUser } from "@/js/Providers/UserProvider";
import CalendarIcon from "@/svg/calendars-regular.svg?react";
import AnalyticsIcon from "@/svg/chart-mixed-regular.svg?react";
import HashtagIcon from "@/svg/hashtag-regular.svg?react";
import CampaignsIcon from "@/svg/list-regular.svg?react";
import SocialIcon from "@/svg/share-nodes-regular.svg?react";
import { Column } from "@enymo/react-layout";
import { assertNotNull } from "@enymo/ts-nullsafe";
import classNames from "classnames";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const topics = ["campaigns", "social-accounts", "calendar", "hashtags", "analytics"] as const;

function DashboardCard({ topic }: {
    topic: "campaigns" | "social-accounts" | "calendar" | "hashtags" | "analytics",
}) {
    const { t } = useTranslation();
    const topicInCamelCase = useMemo(() => 
        topic.indexOf("-") > -1 ? 
            topic.split("-")
                .map((word, index) => index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1))
                .join("") : 
            topic, [topic]);
    return (
        <Link to={`/app/${topic}`}>
            <Column className="dashboard-card" gap="33px" hAlign="center">
                {(() => {
                    switch (topic) {
                        case "campaigns":
                            return <CampaignsIcon />;
                        case "social-accounts":
                            return <SocialIcon />;
                        case "calendar":
                            return <CalendarIcon />;
                        case "hashtags":
                            return <HashtagIcon />;
                        case "analytics":
                            return <AnalyticsIcon />;
                    }
                })()}
                <Column hAlign="center" gap="12px">
                    <span className="title">{t(`sidebar.${topicInCamelCase}`)}</span>
                    <span className="description">{t(`dashboard.${topicInCamelCase}.description`)}</span>
                </Column>
            </Column>
        </Link>
    )

}

export default function Dashboard() {
    const { t } = useTranslation();
    const { user } = useUser();
    assertNotNull(user);
    useTitle(t("dashboard"));

    const isMobile = useBreakpoint(1300);
    return (
        <Column padding={isMobile ? "60px 60px" : "60px 175px"} gap="50px" className="dashboard">
            <div className={classNames("dashboard-cards", { mobile: isMobile })}>
                {topics.map(topic => <DashboardCard key={topic} topic={topic} />)}
            </div>
        </Column>
    )
}