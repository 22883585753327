import CheckIcon from "@/svg/check-regular.svg?react";
import { createGlideChoice } from "@enymo/glide";
import { Column, Row } from "@enymo/react-layout";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import route from "ziggy-js";
import { useApp } from "../Providers/AppProvider";
import { Button, CheckboxList } from "../glidespec";
import { useSocialAccounts } from "../resources";
import Alert from "./Alert";
import { Avatar } from "./Avatar";
import Popup, { PopupFooter } from "./Popup";
import SocialMediaIcon from "./SocialMediaIcon";

const SocialChannelChoice = createGlideChoice({
    indicator: {
        childrenPosition: "left",
        style: {
            width: "26px",
            height: "26px",
            border: "1px solid var(--neutral-200)",
            borderRadius: "8px",
            background: "var(--bg-100)"
        },
        selectedStyle: {
            borderColor: "var(--primary-500)",
            background: "var(--primary-500)"
        },
        element: <CheckIcon width="18px" fill="white" />
    },
    childrenVerticalAlignment: "center",
    wrapperStyle: {
        borderRadius: "8px",
        border: "2px solid var(--neutral-200)",
        padding: "6px 20px"
    },
    element: ({src, children}: {
        src?: string,
        children: string
    }) => (
        <Row gap="26px" vAlign="center" flex={1}>
            <Avatar size={34} src={src} initials={children.split(" ").map(([i]) => i).join("")} />
            <span>{children}</span>
        </Row>
    )
})

interface ApiSocialChannel {
    id: string,
    type: string,
    name: string,
    avatar_url?: string,
    active: boolean
}

export default function SocialAccountSettingsPopup({
    socialAccountId,
    onClose,
    activationLimit
}: {
    socialAccountId: number,
    onClose(refresh: boolean): void,
    activationLimit: number
}) {
    const {t} = useTranslation();
    const {axios} = useApp();
    const [socialAccount] = useSocialAccounts({id: socialAccountId});
    const [socialChannels, setSocialChannels] = useState<{
        type: string,
        channels: ApiSocialChannel[]
    }[]>([]);
    const [existingChannelCount, setExistingChannelCount] = useState(0);
    const [selectedChannels, setSelectedChannels] = useState<string[]>([]);

    useEffect(() => {
        axios.get<ApiSocialChannel[]>(route("social-accounts.social-channels.index", {social_account: socialAccountId})).then(response => {
            const map: {[key: string]: ApiSocialChannel[]} = {};
            const active: string[] = [];
            for (const channel of response.data) {
                map[channel.type] ??= [];
                map[channel.type].push(channel);
                if (channel.active) {
                    active.push(channel.id);
                }
            } 
            setSocialChannels(Object.entries(map).map(([type, channels]) => ({
                type,
                channels
            })));
            setExistingChannelCount(active.length);
            setSelectedChannels(active);
        });
    }, [socialAccountId, setSocialChannels, setSelectedChannels]);

    const handleSync = async () => {
        await axios.post(route("social-accounts.social-channels.sync", {social_account: socialAccountId}), {
            ids: selectedChannels
        });
        onClose(true);
    }

    return (
        <Popup onBackgroundClick={() => onClose(false)} maxWidth="500px" width="100%">
            <Column padding="30px 25px" gap="30px">
                {socialAccount && (
                    <Row gap="15px" vAlign="center">
                        <SocialMediaIcon type={socialAccount.type} width="35px" />
                        <h1>{t(`socialAccounts.${socialAccount.type}`)}</h1>
                    </Row>
                )}
                {socialChannels.map(({type, channels}) => (
                    <Column key={type} gap="20px">
                        <span>{t(`socialAccounts.settings.${socialAccount?.type}.${type}`)}</span>
                        <Column gap="12px">
                            <CheckboxList value={selectedChannels} onChange={setSelectedChannels}>
                                {channels.map(({id, name, avatar_url}) => (
                                    <SocialChannelChoice key={id} value={id} src={avatar_url}>
                                        {name ?? ""}
                                    </SocialChannelChoice> 
                                ))}
                            </CheckboxList>
                        </Column>
                    </Column>
                ))}
                {activationLimit < socialChannels.length && (
                    <Alert title={t("socialAccounts.settings.limit.title")}>
                        {t("socialAccounts.settings.limit.text", {limit: activationLimit})}
                    </Alert>
                )}
            </Column>
            <PopupFooter>
                <Button variant="secondary" onClick={() => onClose(false)}>{t("cancel")}</Button>
                <Button variant="primary" onClick={handleSync} disabled={selectedChannels.length - existingChannelCount > activationLimit}>{t("add")}</Button>
            </PopupFooter>
        </Popup>
    )
}