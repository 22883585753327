import React from "react";
import "@/css/components/back.scss";
import { useTranslation } from "react-i18next";
import BackIcon from "@/svg/chevron-left-regular.svg?react";
import { useNavigate } from "react-router";

export default function Back({ 
    children,
    to,
}: {
    children?: React.ReactNode,
    to?: string,
}) {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <button className="back" onClick={() => to && navigate(to)} type="button">
            {children ?? (
                <>
                    <BackIcon />
                    <span>{t("back")}</span>
                </>
            )}
        </button>
    )
}