import "@/css/components/medium.scss";
import classNames from "classnames";
import React, { useCallback, useEffect, useState } from "react";

interface ImageProps extends React.ImgHTMLAttributes<HTMLDivElement> {
    src?: string,
    dragging?: boolean,
    assetType?: "image" | "video",
}

export default function Medium({
    src,
    assetType = "image",
    dragging,
    loading: loadingProp,
    className,
    ...props
}: Omit<ImageProps, "loading"> & { loading?: boolean }) {
    const [loaded, setLoaded] = useState<string>();
    const videoRef = React.useRef<HTMLVideoElement>(null);

    useEffect(() => {
        if (videoRef.current) {
            if (dragging && !videoRef.current.paused) {
                videoRef.current.pause();
            }
        }
    }, [dragging, videoRef]);

    const loading = loadingProp ?? src !== loaded;

    const handleLoad = useCallback<React.ReactEventHandler<HTMLVideoElement | HTMLImageElement>>((e) => {
        setLoaded(e.currentTarget.src);
    }, [setLoaded]);

    return (
        <div className={classNames("medium", className, { loading })} {...props}>
            {src && (assetType === "image" ? (
                <img src={src} onLoad={handleLoad} />
            ) : (
                <video 
                    ref={videoRef}
                    onLoadedData={handleLoad}
                    src={src}
                    onMouseEnter={(e) => !loading && e.currentTarget.play()}
                    onMouseLeave={(e) => {
                        if (!loading) {
                            e.currentTarget.pause()
                            e.currentTarget.currentTime = 0
                        }
                    }}
                    loop
                    muted
                    disablePictureInPicture
                />
            ))}
        </div>
    )
}