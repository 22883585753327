import { assertNotNull } from "@enymo/ts-nullsafe";
import React, { useMemo } from "react";
import { Outlet, useParams } from "react-router";
import { PostsProvider as ResourcePostsProvider } from "../resources";

export default function PostsProvider() {
    const { campaignId } = useParams();
    assertNotNull(campaignId, "Campaign ID is required for PostsProvider");
    return (
        <ResourcePostsProvider params={useMemo(() => ({ campaign: campaignId }), [campaignId])}>
            <Outlet />
        </ResourcePostsProvider>
    )
}