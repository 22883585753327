import "@/css/components/avatar.scss";
import { assertNotNull } from "@enymo/ts-nullsafe";
import React from "react";
import route from "ziggy-js";
import { useUser } from "../Providers/UserProvider";
import { Avatar } from "./Avatar";

export function UserAvatar({ size }: {
    size: number
}) {

    const { user } = useUser();
    assertNotNull(user, "User must be logged in");

    return (
        <Avatar
            size={size}
            src={user.has_avatar ? route("users.avatar.show", { user: "me", v: user.avatar_version }) : undefined}
            initials={`${user.first_name[0]}${user.last_name[0]}`}
        />
    )
}
