import "@/css/components/avatar.scss";
import React from "react";

export interface AvatarProps {
    size: number,
    src?: string,
    initials?: string
}

export function Avatar({ size, src, initials }: AvatarProps) {
    return (
        <div className="avatar" style={{width: `${size}px`, height: `${size}px`}}>
            {(src ? (
                <img src={src} alt="" />
            ) : (
                <span style={{fontSize: `${size * 0.4}px`}}>{initials}</span>
            ))}
        </div>
    )
}
