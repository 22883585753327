import "@/css/components/confirm-popup.scss";
import TriangleExclamationIcon from "@/svg/triangle-exclamation-duotone.svg?react";
import { Column, Row } from "@enymo/react-layout";
import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../glidespec";
import Popup, { PopupFooter } from "./Popup";

export default function ConfirmPopup({
    variant,
    confirmText,
    onConfirm,
    onCancel,
    title,
    children
}: {
    variant: "warning" | "default",
    confirmText: string,
    onConfirm(): void | Promise<void>,
    onCancel(): void,
    title: React.ReactNode,
    children: React.ReactNode
}) {
    const {t} = useTranslation();

    return (
        <Popup maxWidth="500px" width="100%" onBackgroundClick={onCancel} className="confirm-popup">
            <Column gap="30px" padding="30px 25px">
                <Row gap="15px" vAlign="center">
                    {variant === "warning" ? <TriangleExclamationIcon className="icon warning" /> : <>{/* TODO */}</>}
                    <h1>{title}</h1>
                </Row>
                <p>{children}</p>
            </Column>
            <PopupFooter>
                <Button variant="secondary" onClick={onCancel}>{t("cancel")}</Button>
                <Button variant={variant === "warning" ? "danger" : "primary"} onClick={onConfirm}>{confirmText}</Button>
            </PopupFooter>
        </Popup>
    )
}