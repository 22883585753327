import { isNotNull } from "@enymo/ts-nullsafe";
import { useEffect, useState } from "react";

export function useObjectUrl(file?: File | null) {
    const [objectUrl, setObjectUrl] = useState<string>();
    
    useEffect(() => {
        if (!isNotNull(file)) {
            setObjectUrl(undefined);
            return;
        }
        
        const url = URL.createObjectURL(file);
        setObjectUrl(url);
        return () => URL.revokeObjectURL(url);
    }, [file, setObjectUrl]);
    
    return objectUrl;
}