import TriangleExclamationIcon from "@/svg/triangle-exclamation-duotone.svg?react";
import { Column, Row } from "@enymo/react-layout";
import React from "react";
import { Button } from "../glidespec";
import Popup, { PopupFooter } from "./Popup";

export default function InfoPopup({variant, title, onClose, confirmText, children}: {
    variant: "warning" | "default",
    title: React.ReactNode,
    confirmText: React.ReactNode
    onClose: () => void | Promise<void>,
    children: React.ReactNode
}) {
    return (
        <Popup maxWidth="500px" width="100%" onBackgroundClick={onClose} className="confirm-popup">
            <Column gap="30px" padding="30px 25px">
                <Row gap="15px" vAlign="center">
                    {variant === "warning" ? <TriangleExclamationIcon className="icon warning" /> : <>{/* TODO */}</>}
                    <h1>{title}</h1>
                </Row>
                <p>{children}</p>
            </Column>
            <PopupFooter>
                <Button variant={variant === "warning" ? "danger" : "primary"} onClick={onClose}>{confirmText}</Button>
            </PopupFooter>
        </Popup>
    )
}