import "@/css/components/avatar-with-logo.scss";
import FacebookLogo from "@/svg/facebook-logo.svg?react";
import InstagramLogo from "@/svg/instagram-logo.svg?react";
import LinkedInLogo from "@/svg/linkedin-logo.svg?react";
import TikTokLogo from "@/svg/tiktok-logo.svg?react";
import YoutubeLogo from "@/svg/youtube-logo.svg?react";
import React from "react";
import { SocialAccountType } from "../types";
import { Avatar, AvatarProps } from "./Avatar";

interface AvatarWithLogoProps extends AvatarProps {
    type: SocialAccountType
}

export default function AvatarWithLogo({type, size, ...props}: AvatarWithLogoProps) {
    return (
        <div className="avatar-with-logo">
            <Avatar size={size} {...props} />
            {!(import.meta.env.VITE_BRAND_CORRECTNESS_MODE === "true" && type === "youtube") && React.createElement((() => {
                switch (type) {
                    case "facebook":
                        return FacebookLogo;
                    case "linkedin":
                        return LinkedInLogo;
                    case "tiktok":
                        return TikTokLogo;
                    case "youtube":
                        return YoutubeLogo;
                    case "instagram":
                        return InstagramLogo;
                }
            })(), {
                className: "logo",
                width: `${size * 0.375}px`,
                height: `${size * 0.375}px`
            })}
        </div>
    )
}