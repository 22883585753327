import "@/css/app.scss";
import "@/css/pages/error-fallback.scss";
import TriangleExclamationIcon from "@/svg/triangle-exclamation-solid.svg?react";
import { Form, SubmitHandler } from "@enymo/react-form-component";
import { Column, Row } from "@enymo/react-layout";
import { requireNotNull } from "@enymo/ts-nullsafe";
import * as Sentry from "@sentry/react";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FallbackProps, useSentryUser } from "../Components/ErrorBoundary";
import { Button, EmailInput, Input } from "../glidespec";

interface Submit {
    name?: string,
    email?: string,
    comments: string
}

export default function ErrorFallback({eventId, resetError}: FallbackProps) {
    const {t} = useTranslation();
    const form = useForm<Submit>();
    const [loading, setLoading] = useState(false);
    const {user} = useSentryUser();

    const handleSubmit: SubmitHandler<Submit> = async ({name, email, comments}) => {
        setLoading(true);
        if (eventId !== undefined) {
            Sentry.captureUserFeedback({
                event_id: eventId,
                name: requireNotNull(user?.name ?? name, "Error in feedback submit"),
                email: requireNotNull(user?.email ?? email, "Error in feedback submit"),
                comments
            });
        }
        resetError();
    }
    
    return (
        <Column flex={1} align="center" hAlign="center">
            <Column width="450px" gap="40px" className="error-fallback">
                <Column gap="25px" hAlign="center">
                    <TriangleExclamationIcon className="error" height="70px" width="80px" />
                    <h1>{t("errorFallback.title")}</h1>
                    <p>{t("errorFallback.text")}</p>
                </Column>
                <Form form={form} onSubmit={handleSubmit} loading={loading}>
                    <Column gap="20px">
                        {user === null && <>
                            <Input name="name" label={t("errorFallback.name")} options={{
                                required: t("errorFallback.name.required")
                            }} />
                            <EmailInput options={{
                                required: t("input.email.required")
                            }} />
                        </>}
                        <Input 
                            name="comments" 
                            className="textarea"
                            type="textarea" 
                            label={t("errorFallback.feedback")} 
                            placeholder={t("errorFallback.feedback.placeholder")}
                            options={{
                                required: t("errorFallback.feedback.required")
                            }}
                            
                        />
                        <Row gap="40px" align="end" vAlign="center">
                            <Button variant="secondary" onClick={resetError}>{t("errorFallback.skip")}</Button>
                            <Button variant="primary" submit>{t("errorFallback.submit")}</Button>
                        </Row>
                    </Column>
                </Form>
            </Column>
        </Column>
    )
}