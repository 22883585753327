import "@/css/components/weekday-select.scss";
import { createGlideChoice } from "@enymo/glide";
import { Column } from "@enymo/react-layout";
import React from "react";
import { useTranslation } from "react-i18next";
import { weekdays } from "../common";
import { CheckboxList } from "../glidespec";
import { FormInputProps } from "../types";

const WeekDaySelectItem = createGlideChoice({
    wrapperStyle: {
        borderRadius: "12px",
        background: "var(--bg-400)",
        fontSize: "15px",
        fontWeight: 500,
        color: "var(--text-500)",
        cursor: "pointer"
    },
    labelStyle: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    selectedWrapperStyle: {
        border: "2px solid var(--primary-400)",
        color: "var(--primary-500)"
    }
});

interface WeekdaySelectProps extends FormInputProps<typeof weekdays[number][]> {
    label?: string
}

export default function WeekDaySelect({
    label,
    ...props
}: WeekdaySelectProps) {
    const {t} = useTranslation();

    return (
        <Column gap="8px">
            {label && <label className="input-label">{label}</label>}
            <CheckboxList className="weekday-select-grid" handlesError {...props}>
                {weekdays.map(weekday => (
                    <WeekDaySelectItem key={weekday} value={weekday}>{t(`weekdays.${weekday}.short`)}</WeekDaySelectItem>
                ))}
            </CheckboxList>
        </Column>
    )
}