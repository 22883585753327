import supportedCountries from "@/json/supported-countries.json";
import usStates from "@/json/us-states.json";
import { Column, Row } from "@enymo/react-layout";
import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Input } from "../glidespec";

export interface InvoiceAddressForm {
    invoice_name: string;
    invoice_address_1: string;
    invoice_address_2: string;
    invoice_postal_code: string;
    invoice_city: string;
    invoice_country: string;
    invoice_state?: string;
    invoice_vat_id: string;
    invoice_vat_type: string;
}

export default function InvoiceAddressFields({ vatIdRequired = false }: {
    vatIdRequired?: boolean
}) {

    const { t } = useTranslation();
    const form = useFormContext();

    const country = form.watch("invoice_country") as keyof typeof supportedCountries | "";

    useEffect(() => {
        if (country !== "") {
            form.setValue("invoice_vat_type", supportedCountries[country]?.[0] ?? "");
        }
    }, [country, form.setValue]);

    const countryCodes = Object.keys(supportedCountries);


    return (
        <Column gap="30px" className="invoice-address-fields">
            <h3>{t("settings.checkout.invoiceAddress")}</h3>
            <Column gap="20px">
                <Input
                    name="invoice_name"
                    label={t("settings.checkout.nameCompanyName")}
                    options={{
                        required: t("settings.checkout.nameCompanyName.required")
                    }}
                />
                <Input
                    name="invoice_address_1"
                    label={t("settings.checkout.address1")}
                    options={{
                        required: t("settings.checkout.address1.required")
                    }}
                />
                <Input
                    name="invoice_address_2"
                    label={t("settings.checkout.address2")}
                />
                <Row gap="10px">
                    <Input
                        name="invoice_postal_code"
                        label={t("settings.checkout.postalCode")}
                        options={{
                            required: t("settings.checkout.postalCode.required")
                        }}
                    />
                    <Input
                        name="invoice_city"
                        label={t("settings.checkout.city")}
                        options={{
                            required: t("settings.checkout.city.required")
                        }}
                    />
                </Row>
                <Row gap="10px">
                    <Input
                        flex={1}
                        name="invoice_country"
                        label={t("settings.checkout.country")}
                        type="select"
                    >
                        <option value="">{t("input.pleaseSelect")}</option>
                        {countryCodes.sort()?.map((country) => (
                            <option key={country} value={country}>{t(`countries.${country.toLowerCase()}`)}</option>
                        ))}
                    </Input>
                    {country === "us" && <Input
                        flex={1}
                        name="invoice_state"
                        label={t("settings.checkout.state")}
                        type="select"
                    >
                        <option value="">{t("input.pleaseSelect")}</option>
                        {Object.entries(usStates).map(([key, value]) => (
                            <option key={key} value={key}>{value}</option>
                        ))}
                    </Input>}
                </Row>

                <Row gap="10px">
                    {country !== "" && (
                        <Input
                            flex={1}
                            name="invoice_vat_type"
                            label={t("settings.checkout.vatIdType")}
                            type="select"
                        >
                            {supportedCountries[country]?.map((vatType: string) => (
                                <option key={vatType} value={vatType}>{vatType.replaceAll('_', ' ').toUpperCase()}</option>
                            ))}
                        </Input>
                    )}
                    <Input
                        flex={3}
                        name="invoice_vat_id"
                        label={t("settings.checkout.vatId")}
                        options={{
                            required: vatIdRequired ? t("settings.checkout.vatId.required") : undefined,
                        }}
                    />
                </Row>
            </Column>
        </Column>
    )
}