import "@/css/components/tooltip-icon.scss";
import CircleInfoIcon from "@/svg/circle-info-duotone.svg?react";
import React from "react";
import Tooltip, { TooltipParent, TooltipProps } from "./Tooltip";

export default function TooltipIcon(props: TooltipProps) {
    return (
        <TooltipParent className="tooltip-icon">
            <CircleInfoIcon />
            <Tooltip {...props} />
        </TooltipParent>
    )
}