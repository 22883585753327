import "@/css/components/menu-button.scss";
import DotsIcon from "@/svg/ellipsis-vertical-regular.svg?react";
import { Clickable, ClickableProps } from "@enymo/react-clickable-router";
import React from "react";

export default function MenuButton(props: Omit<ClickableProps, "children">) {
    return (
        <Clickable className="menu-button" {...props}>
            <DotsIcon />
        </Clickable>
    )
}