import { createRequiredContext } from "@enymo/react-better-context";
import { assertNotNull } from "@enymo/ts-nullsafe";
import React, { useCallback, useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { DeepPartial } from "ts-essentials";
import route from "ziggy-js";
import { useSentryUser } from "../Components/ErrorBoundary";
import { languages, OPENAI_PRIVACY_URL, OPENAI_TOS_URL } from "../common";
import { User, userTransformer, UserUpdate, useUsers } from "../resources";
import { useApp } from "./AppProvider";
import { useConfirmPopup } from "./ConfirmPopupProvider";


type Login = (email: string, password: string, remember: boolean, resetToken?: string) => Promise<void>
type SignUp = (firstName: string, lastName: string, email: string, password: string) => Promise<void>
type Update = (update: DeepPartial<UserUpdate>, updateMethod?: "on-success" | "immediate" | "local-only") => Promise<void>
type Delete = (password?: string) => Promise<void>
type Logout = () => Promise<void>
type SetAvatar = (avatar: File | null) => Promise<void>
type Refresh = () => Promise<void>
type RequestOpenAi = () => Promise<boolean>

const [Provider, useUser] = createRequiredContext<{
    user: User | null,
    login: Login,
    signUp: SignUp,
    update: Update,
    deleteAccount: Delete,
    logout: Logout,
    refresh: Refresh,
    setAvatar: SetAvatar,
    requestOpenAi: RequestOpenAi,
    loading: boolean
}>("UserProvider must be present in the component tree");
export { useUser };

export default function UserProvider({children}: {
    children: React.ReactNode
}) {
    const {t, i18n} = useTranslation();
    const withPopup = useConfirmPopup();
    const {axios, setJwt} = useApp();
    const [user, {store, update, destroy, refresh, loading}] = useUsers({
        id: "me"
    });

    const {setUser: setSentryUser} = useSentryUser();

    const login = useCallback<Login>(async (email, password, remember, resetToken) => {
        const response = await (resetToken === undefined
            ? axios.post(route("users.login"), {email, password, remember}) 
            : axios.post(route("users.reset-password", {email, password, remember, token: resetToken})));
        await update(userTransformer(response.data), "local-only");
    }, [update, axios]);

    const signUp = useCallback<SignUp>(async (firstName, lastName, email, password) => {
        await update((await store({
            first_name: firstName,
            last_name: lastName,
            email,
            password,
            language: i18n.language as typeof languages[number],
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone ?? "Europe/Berlin"
        }, "on-success")), "local-only");
    }, [update, store, i18n.language]);

    const deleteAccount = useCallback<Delete>(async password => {
        await destroy(undefined, password ? {
            headers: {
                Authorization: `Password ${password}`
            }
        } : undefined);
    }, [axios]);

    const logout = useCallback<Logout>(async () => {
        await axios.post(route("users.logout"));
        await destroy("local-only");
    }, [destroy]);

    const setAvatar = useCallback<SetAvatar>(async avatar => {
        assertNotNull(user, "User must be logged in to change avatar");
        if (avatar !== null) {
            const fd = new FormData();
            fd.append("file", avatar);
            fd.append("_method", "put");
            await axios.post(route("users.avatar.update", {user: "me"}), fd);
            await update({
                has_avatar: true, 
                avatar_version: user.avatar_version + 1
            }, "local-only");
        }
        else {
            await axios.delete(route("users.avatar.destroy", {user: "me"}));
            await update({has_avatar: false}, "local-only");
        }
    }, [user, axios, update]);

    const requestOpenAi = useCallback(async () => {
        assertNotNull(user, "User must be loaded before calling this");
        if (user.openai) {
            return true;
        }
        return withPopup(
            "default",
            t("accept"),
            t("openai.title"), 
            <Trans i18nKey="openai.text">
                In order to provide FRED's AI-powered features, your inputs such as company description, post texts and prompt will be sent to OpenAI for processing.
                Usage of these features is subject to OpenAI's <a className="link" href={OPENAI_TOS_URL} target="_blank">Terms of Use</a> and <a className="link" href={OPENAI_PRIVACY_URL} target="_blank">Privacy Policy</a>
            </Trans>,
            async () => {
                await update({openai: true})
            }
        )
    }, [user, withPopup, update]);

    useEffect(() => {
        setJwt(user?.jwt ?? null);
        setSentryUser(user !== null ? {
            name: t("user.fullName", {
                firstName: user.first_name,
                lastName: user.last_name
            }),
            email: user.email
        } : null);
    }, [user]);

    useEffect(() => {
        if (user !== null && user.language !== i18n.language) {
            i18n.changeLanguage(user.language);
        }
    }, [user?.language, i18n]);

    return (
        <Provider value={{
            user,
            login,
            signUp,
            update,
            deleteAccount,
            logout,
            setAvatar,
            requestOpenAi,
            refresh,
            loading
        }}>
            {children}
        </Provider>
    )
}