import "@/css/layouts/lockscreen.scss";
import React from "react";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router";
import LanguageSelect from "../Components/LanguageSelect";
import { currentYear, languages } from "../common";

export default function Lockscreen({
    children
}: {
    children?: React.ReactNode
}) {
    const {t, i18n} = useTranslation();

    const handleChangeLanguage = (lng: typeof languages[number]) => {
        i18n.changeLanguage(lng);
    }

    return (
        <div className="lockscreen">
            <div className="main-wrap">
                <main>{children ?? <Outlet />}</main>
            </div>
            <div className="footer">
                <LanguageSelect languages={languages} value={i18n.language as typeof languages[number]} onChange={handleChangeLanguage} />
                <span>{t("copyright", {currentYear})}</span>
            </div>
        </div>
    )
}