import React from "react";
import App from "./App";
import ErrorBoundary from "./Components/ErrorBoundary";
import ErrorHandler from "./Components/ErrorHandler";
import ErrorFallback from "./Pages/ErrorFallback";
import AppProvider from "./Providers/AppProvider";
import ConfirmPopupProvider from "./Providers/ConfirmPopupProvider";
import ToasterProvider from "./Providers/ToasterProvider";
import UserProvider from "./Providers/UserProvider";

export default function Bootstrapper() {
    return (
        <React.StrictMode>
            <ErrorBoundary fallback={props => <ErrorFallback {...props} />}>    
                <ToasterProvider>
                    <ConfirmPopupProvider>
                        <AppProvider>
                            <UserProvider>
                                <App />
                                <ErrorHandler />
                            </UserProvider>
                        </AppProvider>
                    </ConfirmPopupProvider>
                </ToasterProvider>
            </ErrorBoundary>
        </React.StrictMode>
    )
}