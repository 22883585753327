import "@/css/components/menu.scss";
import { Clickable, ClickableProps } from "@enymo/react-clickable-router";
import { isNotNull } from "@enymo/ts-nullsafe";
import classNames from "classnames";
import React, { useMemo } from "react";
import { useLocation } from "react-router";

export default function Menu({children}: {
    children: React.ReactNode
}) {
    return (
        <div className="menu">
            {children}
        </div>
    )
}

export function MenuItem({className, to, ...props}: ClickableProps) {
    const location = useLocation();
    const active = useMemo(() => isNotNull(to) ? location.pathname.startsWith(to) : false, [location.pathname, to]);

    return (
        <Clickable className={classNames("menu-item", {active}, className)} to={to} {...props} />
    )
}