import Hashtag from "@/js/Components/Hashtag";
import { usePostParams } from "@/js/Pages/Main/Campaigns/Posts/Post";
import { useApp } from "@/js/Providers/AppProvider";
import { useUser } from "@/js/Providers/UserProvider";
import { resolveSpintax } from "@/js/common";
import { Input } from "@/js/glidespec";
import { Hashtag as HashtagInterface, PostText, SocialChannel } from "@/js/resources";
import MaximizeIcon from "@/svg/arrows-maximize-regular.svg?react";
import MinimizeIcon from "@/svg/arrows-minimize-regular.svg?react";
import ChevronDownIcon from "@/svg/chevron-down-regular.svg?react";
import EmojiIcon from "@/svg/face-smile-regular.svg?react";
import GrabHandleIcon from "@/svg/grip-dots-vertical-solid.svg?react";
import HashtagIcon from "@/svg/hashtag-solid.svg?react";
import CloneIcon from "@/svg/pen-swirl-solid.svg?react";
import TrashIcon from "@/svg/trash-regular.svg?react";
import AiTextIcon from "@/svg/wand-magic-sparkles-solid.svg?react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import emojiData from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import { createContext } from "@enymo/react-better-context";
import useOnClickOutside from "@enymo/react-click-outside-hook";
import { Form, SubmitHandler } from "@enymo/react-form-component";
import { Column, Row } from "@enymo/react-layout";
import { AxiosError } from "axios";
import classNames from "classnames";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import route from "ziggy-js";
import DropdownButton, { DropdownButtonItem } from "../DropdownButton";
import HashtagListInsertion from "../HashtagListInsertion";
import Loader from "../Loader";
import TextLimit from "../TextLimit";
import { useTextParams } from "./TextsColumn";

const additionalOptions = ["hashtags", "ai-text", "clone-rephrase"] as const;
type AdditionalOption = typeof additionalOptions[number];
const [SelectedOptionProvider, useSelectedOption] = createContext<AdditionalOption | null>(null);

function AdditionalOptionTab({
    option,
    onClick,
    disabled,
    loading,
}: {
    option: AdditionalOption | "ai-tools",
    onClick?: () => void,
    disabled?: boolean,
    loading?: boolean,
}) {
    const { t } = useTranslation();
    const selectedOption = useSelectedOption();

    return (
        <button
            onClick={onClick}
            className={classNames("additional-option-tab", {
                active: selectedOption === option,
                secondary: option !== "hashtags",
                disabled,
                loading,
            })}
            disabled={disabled}
        >
            <div>
                {(() => {
                    switch (option) {
                        case "hashtags":
                            return <>
                                <HashtagIcon />
                                <span>{t("posts.create.texts.hashtags")}</span>
                            </>
                        case "ai-text":
                            return <>
                                <AiTextIcon />
                                <span>{t("posts.create.texts.aiText")}</span>
                            </>
                        case "clone-rephrase":
                            return <>
                                <CloneIcon />
                                <span>{t("posts.create.texts.cloneRephrase")}</span>
                            </>
                        case "ai-tools":
                            return <>
                                <AiTextIcon />
                                <span>{t("posts.create.texts.aiTools")}</span>
                                <ChevronDownIcon />
                            </>
                    }
                })()}
            </div>
            {loading && <Loader />}
        </button>
    )
}

export function LoadingTextComponent() {
    return (
        <div className={classNames("text-wrapper-outer", "loading")} />
    )
}

interface AITextSubmit {
    text: string;
}

function AITextForm({
    postText,
    onTextChange,
}: {
    postText: PostText,
    onTextChange: (text: string) => void,
}) {
    const { t } = useTranslation();

    const form = useForm<AITextSubmit>();
    const { requestOpenAi } = useUser();
    const { axios } = useApp();

    const [generationLoading, setGenerationLoading] = useState(false);

    const handleSubmit: SubmitHandler<AITextSubmit> = async (data) => {
        if (await requestOpenAi()) {
            setGenerationLoading(true);
            try {
                const response = await axios.post(route("posts.texts.ai-text", {
                    post: postText.post_id,
                    postText: postText.id,
                }), data);
                setGenerationLoading(false);

                if (response.data.text) {
                    onTextChange(response.data.text);
                }
            }
            catch (error) {
                if (error instanceof AxiosError && (error.response?.status === 400 || (error.response?.status === 500 && error.response.data.code !== undefined))) {
                    form.setError("text", {
                        type: "manual",
                        message: t(error.response.data.code),
                    });
                    setGenerationLoading(false);
                }
                else {
                    throw error;
                }
            }
        }
    }

    return (
        <Form form={form} onSubmit={handleSubmit}>
            <Column padding="0 0 0 16px" gap="28px" className="ai-text-option">
                <Input
                    name="text"
                    className="ai-text-input"
                    label={t("posts.create.texts.aiText")}
                    placeholder={t("posts.create.texts.aiText.placeholder")}
                    type="textarea"
                    options={{
                        required: t("posts.create.texts.aiText.required")
                    }}
                />
                <button className={classNames("generate-text", {
                    "parent-has-error": form.formState.errors.text,
                })} type="submit">
                    {generationLoading ? (
                        <Loader />
                    ) : t("posts.create.texts.aiText.generate")}

                </button>
            </Column>
        </Form>
    )
}

export default function TextComponent({
    text: postText,
    onTextChange,
    onTitleChange,
    onHashtagsChange,
    onDelete,
    onCloneRephrase,
    channels
}: {
    text: PostText,
    onTextChange: (text: string) => void,
    onTitleChange: (youtubeTitle: string) => void,
    onHashtagsChange: (hashtags: HashtagInterface[]) => void,
    onDelete?: () => void,
    onCloneRephrase: () => Promise<void>,
    channels: SocialChannel[]
}) {
    const { requestOpenAi } = useUser();
    const { t, i18n } = useTranslation();
    const {
        maximizedId: [maximizedTextId, setMaximizedTextId],
    } = useTextParams();
    const { screenSize, textLimitReached } = usePostParams();

    const maximized = maximizedTextId === postText.id;

    const {
        attributes,
        listeners,
        transform,
        transition,
        setNodeRef,
        isDragging: dragging,
    } = useSortable({
        id: postText.id,
    });

    const style = {
        transform: CSS.Translate.toString(transform),
        transition: transition
    };
    const [cloneRephraseLoading, setCloneRephraseLoading] = useState(false);

    const timerId = useRef<number | null>(null);
    const [postTextLength, setPostTextLength] = useState(0);
    const editorRef = useRef<ReactQuill | null>(null);
    const setEditorRef = useCallback((quill: ReactQuill | null) => {
        if (quill) {
          editorRef.current = quill;
          setPostTextLength(resolveSpintax(quill.getEditor()?.getText() ?? "", 'longest').length)
        } else {
          editorRef.current = null;
        }
      }, [setPostTextLength]);
    const hashtagLegth = useMemo(
        () => postText.hashtags.reduce((acc, { name }) => acc + name.length + 2, 0), // 2 for # and space
        [postText.hashtags]
    );
    const [text, setText] = useState(postText.text);
    const [youtubeTitle, setYoutubeTitle] = useState<string>(postText.title ?? "");
    const [focused, setFocused] = useState(false);

    const [showEmojiPicker, setShowEmojiPicker] = useState(false);
    const emojiRef = useOnClickOutside<HTMLDivElement>(() => setShowEmojiPicker(false), [setShowEmojiPicker]);

    const [openOption, setOpenOption] = useState<AdditionalOption | null>(null);

    const handleSetOpenOption = (option: AdditionalOption) => () => {
        setOpenOption(openOption === option ? null : option);
    }

    const handleSetText = (text: string) => {
        setText(text);
        setPostTextLength(resolveSpintax(editorRef.current?.getEditor()?.getText() ?? "", 'longest').length);
    }

    const handleSetAiText = (text: string) => {
        setOpenOption(null);
        handleSetText(text);
    }

    const handleChangeSelection = () => {
        setFocused(editorRef.current?.getEditor()?.getSelection() !== null);
    }

    const handleEmojiSelect = (emoji: any) => {
        const quill = editorRef.current?.getEditor();
        if (quill) {
            const cursorPosition = quill.getSelection()?.index ?? quill.getLength();
            quill.insertText(cursorPosition, emoji.native);
            quill.setSelection(cursorPosition + emoji.native.length);
        }
    };

    const handleDeleteHashtag = (hashtagId: number) => () => {
        onHashtagsChange(postText.hashtags.filter(({ id }) => id !== hashtagId));
    }

    const handleAddHashtag = (hashtags: HashtagInterface | HashtagInterface[]) => {
        if (!Array.isArray(hashtags)) {
            hashtags = [hashtags];
        }
        const newHashtags = hashtags.filter(({ id }) => !postText.hashtags.some((hashtag) => hashtag.id === id));
        onHashtagsChange([...postText.hashtags, ...newHashtags]);
    }

    const handleCloneRephrase = async () => {
        if (await requestOpenAi()) {
            setCloneRephraseLoading(true);
            try {
                await onCloneRephrase();
            }
            finally {
                setCloneRephraseLoading(false);
            }
        }
    }

    useEffect(() => {
        if (timerId.current) {
            clearTimeout(timerId.current);
        }
        if (postText.text === text) {
            return;
        }
        timerId.current = setTimeout(() => {
            onTextChange(text);
        }, 1000);
    }, [text, postText.text, onTextChange, timerId]);

    useEffect(() => {
        if (timerId.current) {
            clearTimeout(timerId.current);
        }
        if ((postText.title ?? "") === youtubeTitle) {
            return;
        }
        timerId.current = setTimeout(() => {
            onTitleChange(youtubeTitle);
        }, 1000);
    }, [youtubeTitle, postText.title, onTitleChange, timerId]);

    useEffect(() => {
        if (maximizedTextId !== postText.id) {
            setFocused(false)
        }
    }, [maximizedTextId, setFocused]);

    const hasYoutubeAccount = useMemo(() => channels.find(channel => channel.account.type === "youtube"), [channels]);

    return (
        <div
            ref={setNodeRef}
            className={classNames("text-wrapper-outer", { dragging })}
            style={style}
            {...attributes}
        >
            <GrabHandleIcon {...listeners} />
            <Column gap={openOption === null ? "7px" : "22px"} flex={1}>
                <Column gap="18px">
                    {maximized && hasYoutubeAccount && (
                        <Column gap="7px" className="youtube-title">
                            <Input 
                                value={youtubeTitle}
                                onChange={(e) => setYoutubeTitle(e.slice(0, 200))}
                                placeholder={t("posts.create.texts.youtubeTitle")} 
                            />
                            <span>{t("posts.create.texts.youtubeTitle.limit", {
                                number: youtubeTitle.length,
                                limit: 200,
                            })}</span>
                        </Column>
                    )}
                    <div
                        className={classNames("text-wrapper", { minimized: !maximized, maximized, active: focused })}
                        onClick={() => !maximized && setMaximizedTextId(postText.id)}
                    >
                        {maximized ? (
                            <>
                                <button onClick={() => setMaximizedTextId(null)} className="minimize-button">
                                    <MinimizeIcon />
                                </button>
                                <Column gap="12px">
                                    <ReactQuill
                                        ref={setEditorRef}
                                        theme="bubble"
                                        value={text}
                                        onChange={handleSetText}
                                        onChangeSelection={handleChangeSelection}
                                        modules={{
                                            toolbar: false,
                                        }}
                                        formats={[]}
                                    />
                                    <div className="hashtags">
                                        {postText.hashtags.map((hashtag) => (
                                            <Hashtag
                                                key={hashtag.id}
                                                type="in-post"
                                                hashtag={hashtag}
                                                onDelete={handleDeleteHashtag(hashtag.id)}
                                                showDelete
                                            />
                                        ))}
                                    </div>
                                </Column>
                                <Row align="space">
                                    <SelectedOptionProvider value={openOption}>
                                        {screenSize !== "mobile" ? (
                                            <Row gap="6px">
                                                {(["hashtags", "ai-text"] as AdditionalOption[]).map((option) => (
                                                    <AdditionalOptionTab key={option} option={option} onClick={handleSetOpenOption(option)} />
                                                ))}
                                                <AdditionalOptionTab
                                                    option="clone-rephrase"
                                                    onClick={handleCloneRephrase}
                                                    disabled={textLimitReached}
                                                    loading={cloneRephraseLoading}
                                                />
                                            </Row>
                                        ) : (
                                            <Row gap="6px">
                                                <AdditionalOptionTab option="hashtags" onClick={handleSetOpenOption("hashtags")} />
                                                <DropdownButton menuButton={<AdditionalOptionTab option="ai-tools" />}>
                                                    <DropdownButtonItem
                                                        onClick={handleSetOpenOption("ai-text")}
                                                    >
                                                        {t("posts.create.texts.aiText")}
                                                    </DropdownButtonItem>
                                                    <DropdownButtonItem
                                                        onClick={handleCloneRephrase}
                                                        disabled={textLimitReached || cloneRephraseLoading}
                                                    >
                                                        {cloneRephraseLoading ? t("posts.create.texts.cloneRephrase.loading") : t("posts.create.texts.cloneRephrase")}
                                                    </DropdownButtonItem>
                                                </DropdownButton>
                                            </Row>
                                        )}
                                    </SelectedOptionProvider>
                                    <div className="emoji-wrapper" ref={emojiRef}>
                                        <button
                                            className={classNames("text-format-button", { active: showEmojiPicker })}
                                            onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                                        >
                                            <EmojiIcon />
                                        </button>
                                        {showEmojiPicker && (
                                            <Picker
                                                locale={i18n.language}
                                                data={emojiData}
                                                theme="light"
                                                onEmojiSelect={handleEmojiSelect}
                                            />
                                        )}
                                    </div>
                                </Row>
                            </>
                        ) : (
                            <>
                                <div dangerouslySetInnerHTML={{
                                    __html: text,
                                }} className="text" />
                                <button onClick={() => setMaximizedTextId(postText.id)}>
                                    <MaximizeIcon />
                                </button>
                            </>
                        )}
                    </div>
                </Column>
                {maximized && (
                    <Column gap="20px">
                        {(() => {
                            switch (openOption) {
                                case "hashtags":
                                    return (
                                        <HashtagListInsertion onAddHashtag={handleAddHashtag} />
                                    )
                                case "ai-text":
                                    return (
                                        <AITextForm
                                            postText={postText}
                                            onTextChange={handleSetAiText}
                                        />
                                    )
                            }
                        })()}
                        <Row align="space" className="text-wrapper-footer">
                            <Row gap="20px" vAlign="center" flex={1}>
                                <span className="text-limit">
                                    {t("posts.create.texts.textLimit", {
                                        number: postTextLength + 2 + hashtagLegth, // 2 for the two line breaks
                                    })}
                                </span>
                                <Row gap="6px" vAlign="center" flex={1}>
                                    <span className="channel-limit-title">{t("posts.create.texts.textLimit.channels")}</span>
                                    <Row gap="3px" wrap="wrap" flex={1}>
                                        {channels.map(({ id, icon_type, character_limit }) => (
                                            <TextLimit
                                                key={id}
                                                type={icon_type}
                                                limit={character_limit}
                                                exceeded={(postTextLength + 2 + hashtagLegth) > character_limit}
                                            />
                                        ))}
                                    </Row>
                                </Row>
                            </Row>
                            <button disabled={!onDelete} onClick={onDelete}>
                                <TrashIcon />
                                <span>{t("remove")}</span>
                            </button>
                        </Row>
                    </Column>
                )}
            </Column>
        </div>
    )
}