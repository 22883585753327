import "@/css/pages/settings/checkout.scss";
import Back from "@/js/Components/Back";
import Divider from "@/js/Components/Divider";
import InvoiceAddressFields, { InvoiceAddressForm } from "@/js/Components/InvoiceAddressFields";
import Loadable from "@/js/Components/Loadable";
import Tooltip, { TooltipParent } from "@/js/Components/Tooltip";
import Wallet from "@/js/Components/Wallet";
import { usePaymentSuccess } from "@/js/Layouts/Subscription";
import { useUser } from "@/js/Providers/UserProvider";
import { SubscriptionTier, SubscriptionTierPrices, stripePromise, subscriptionTiers } from "@/js/common";
import { Button, Input } from "@/js/glidespec";
import { ApiSubscripitonData, usePaymentMethods, userTransformer } from "@/js/resources";
import plans from "@/json/plans.json";
import ShieldIcon from "@/svg/shield-halved-solid.svg?react";
import { Form, setFormValues } from "@enymo/react-form-component";
import { Column, Row } from "@enymo/react-layout";
import useSocket from "@enymo/react-socket-hook";
import { assertNotNull } from "@enymo/ts-nullsafe";
import { Elements, ElementsConsumer, PaymentElement } from "@stripe/react-stripe-js";
import { Stripe, StripeElements } from "@stripe/stripe-js";
import axios from "axios";
import classNames from "classnames";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import route from "ziggy-js";

interface CheckoutForm extends InvoiceAddressForm {
    additional_social_accounts: number;
    payment_method: string;
}

export default function Checkout() {
    const { t } = useTranslation();
    const { user, update, refresh } = useUser();
    assertNotNull(user);
    const [searchParams, setSearchParams] = useSearchParams();
    const form = useForm<CheckoutForm>({
        shouldUnregister: true,
    });
    const navigate = useNavigate();
    const [, setPaymentSuccess] = usePaymentSuccess();

    useEffect(() => {
        setFormValues(form, {
            invoice_name: user.invoice_name ?? "",
            invoice_address_1: user.invoice_address_1 ?? "",
            invoice_address_2: user.invoice_address_2 ?? "",
            invoice_postal_code: user.invoice_postal_code ?? "",
            invoice_city: user.invoice_city ?? "",
            invoice_country: user.invoice_country?.toLowerCase() ?? "de",
            invoice_vat_id: user.invoice_vat_id ?? "",
            invoice_state: user.invoice_state ?? "",
            invoice_vat_type: user.invoice_vat_type ?? undefined,
            additional_social_accounts: user.billed_subscription.additional_social_accounts,
        });
    }, [user, setFormValues]);

    const [paymentMethods, { loading: paymentMethodsLoading }] = usePaymentMethods();
    const showWallet = paymentMethods?.length > 0 || paymentMethodsLoading;

    const selectedBillingPeriod = searchParams.get("period") as "monthly" | "yearly";
    const selectedSubscriptionTier = searchParams.get("plan") as SubscriptionTier;
    const selectedPlan = plans[selectedSubscriptionTier];

    const [subscriptionPrices, setSubscriptionPrices] = useState<SubscriptionTierPrices | null>(null);
    const additionalAccounts = form.watch("additional_social_accounts") ?? 0;

    useEffect(() => {
        axios.get(route("stripe-prices.index")).then((response) => setSubscriptionPrices(response.data));
    }, [selectedSubscriptionTier, selectedBillingPeriod, setSubscriptionPrices]);

    const currentSubscriptionPrices = useMemo(() => {
        if (!subscriptionPrices || user.billed_subscription.tier === "free") {
            return {
                subscription: 0,
                additional_social_account: 0,
            };
        }
        return subscriptionPrices[user.billed_subscription.tier][user.billed_subscription.billing_period];
    }, [user.subscription_tier, subscriptionPrices]);

    const selectedSubscriptionPrices = useMemo(() => {
        if (!subscriptionPrices || selectedSubscriptionTier === "free") {
            return undefined;
        }
        return subscriptionPrices[selectedSubscriptionTier][selectedBillingPeriod];
    }, [selectedSubscriptionTier, subscriptionPrices]);

    const invoiceCountry = form.watch("invoice_country");
    const invoiceState = form.watch("invoice_state");
    const vatId = form.watch("invoice_vat_id");
    const vatIdType = form.watch("invoice_vat_type");
    const abortController = useRef<AbortController | null>(null);
    const timerId = useRef<number | null>(null);
    const [taxCheckLoading, setTaxCheckLoading] = useState<boolean>(false);
    const [taxRate, setTaxRate] = useState<number | null>(null);
    const [countrySupported, setCountrySupported] = useState<boolean>(true);
    const [vatIdValid, setVatIdValid] = useState<boolean>(false);

    const handleTaxCheck = useCallback(async () => {
        if (vatId !== "" && vatIdType === undefined) {
            return;
        }
        const controller = new AbortController();
        abortController.current = controller;
        setTaxCheckLoading(true);
        try {
            const response = await axios.post(route("checkout.tax"), {
                country: invoiceCountry,
                state: invoiceState,
                subscription_tier: selectedSubscriptionTier,
                billing_period: selectedBillingPeriod,
                additional_social_accounts: additionalAccounts,
                vat_id: vatId === "" ? undefined : vatId,
                vat_id_type: vatIdType,
            }, {
                signal: controller.signal
            });
            if (response.data.has_error) {
                setVatIdValid(false);
                setTaxRate(null);
                setCountrySupported(true);
            }
            else {
                setTaxRate(response.data.tax_rate !== null && response.data.tax_rate !== undefined ? response.data.tax_rate / 100 : null);
                setCountrySupported(!response.data.country_not_supported);
                setVatIdValid(response.data.vat_id_valid);
                if (response.data.country_not_supported) {
                    form.setError("invoice_vat_id", { message: t("settings.checkout.invoice_vat_id.required") as string});
                }
            }
        }
        catch (e: any) {
            if (e.code !== "ERR_ABORTED") {
                // Do nothing
                return;
            }
            throw e;
        }
        setTaxCheckLoading(false);
    }, [setTaxRate, setCountrySupported, setTaxCheckLoading, setVatIdValid, invoiceCountry, invoiceState, selectedSubscriptionTier, selectedBillingPeriod, additionalAccounts, vatId, vatIdType]);

    useEffect(() => {
        abortController.current?.abort();
        setTaxCheckLoading(false);
        setVatIdValid(false);
        setTaxRate(null);
        if (timerId.current) {
            clearTimeout(timerId.current);
        }
        if ((invoiceCountry && invoiceCountry !== "" && invoiceCountry !== "us") || invoiceState) {
            if (!vatId) {
                handleTaxCheck();
            }
            else {
                setTimeout(() => {
                    handleTaxCheck();
                }, 3000)
            }

        }
    }, [invoiceCountry, invoiceState, vatId, handleTaxCheck]);

    const redirected = searchParams.get("redirect_status") === "succeeded";
    const redirectType = searchParams.get("redirect_type");

    const additionalAccountsPrice = useMemo(() => (selectedSubscriptionPrices?.additional_social_account ?? 0) * additionalAccounts, [selectedSubscriptionPrices, additionalAccounts]);
    const subtotal = useMemo(() => (selectedSubscriptionPrices?.subscription ?? 0) + additionalAccountsPrice, [selectedSubscriptionPrices, additionalAccountsPrice]);
    const tax = useMemo(() => subtotal * (taxRate ?? 0), [subtotal, vatIdValid, taxRate]);
    const perYear = useMemo(() => subtotal + tax, [subtotal, tax]);

    const todayToPay = useMemo(() => {
        if (
            subscriptionTiers.indexOf(selectedSubscriptionTier) < subscriptionTiers.indexOf(user.billed_subscription.tier) ||
            (
                selectedSubscriptionTier === user.billed_subscription.tier &&
                additionalAccounts <= user.billed_subscription.additional_social_accounts
            )
        ) {
            return 0; // There is nothing to pay if is a downgrade or billing period change
        }
        if (!currentSubscriptionPrices || !subscriptionPrices || selectedSubscriptionTier === "free") {
            return 0;
        }
        const currentBillingPeriod = user.billed_subscription.tier === "free" ? selectedBillingPeriod : user.billed_subscription.billing_period;
        let cycleEndDay = user.billed_subscription.valid_from;
        const daysToAdd = currentBillingPeriod === "monthly" ? 30 : 365;
        const now = new Date();
        while (cycleEndDay.getTime() < now.getTime()) {
            cycleEndDay = new Date(cycleEndDay.setDate(cycleEndDay.getDate() + daysToAdd));
        }
        const differenceInDays = Math.ceil(Math.abs(cycleEndDay.getTime() - now.getTime()) / (1000 * 60 * 60 * 24));
        const billingPeriodDays = currentBillingPeriod === "monthly" ? 30 : 365
        const ratioLeft = differenceInDays / billingPeriodDays;
        const alreadyPaid = (currentSubscriptionPrices.subscription + currentSubscriptionPrices.additional_social_account * user.billed_subscription.additional_social_accounts) * (1 + (taxRate ?? 0));
        const upgradedPrice = subscriptionPrices[selectedSubscriptionTier][currentBillingPeriod];
        const upgradedCost = (upgradedPrice.subscription + upgradedPrice.additional_social_account * additionalAccounts) * (1 + (taxRate ?? 0))
        return upgradedCost * ratioLeft - alreadyPaid * ratioLeft;
    }, [user, currentSubscriptionPrices, subscriptionPrices, selectedSubscriptionTier, taxRate, additionalAccounts]);

    const formatPrice = (price?: number) => (price?.toFixed(2).replaceAll('.', ',') ?? "") + " €";

    const [formLoading, setFormLoading] = useState(false);
    const pageLoading = useMemo(() => !subscriptionPrices || showWallet === null, [subscriptionPrices, showWallet]);

    const isPlanDifferent = useMemo(() => (
        user.billed_subscription.tier !== selectedSubscriptionTier ||
        user.billed_subscription.additional_social_accounts != additionalAccounts ||
        user.billed_subscription.billing_period !== selectedBillingPeriod
    ), [user.billed_subscription, selectedSubscriptionTier, additionalAccounts, selectedBillingPeriod]);

    useEffect(() => {
        if (redirected) {
            if (redirectType !== "checkout") {
                setSearchParams({ 
                    period: selectedBillingPeriod,
                    plan: selectedSubscriptionTier,
                });
                return;
            }
            if (isPlanDifferent) { // After redirecting the user has been updated, so if the plan is different, we have to wait for the socket event
                setFormLoading(true);
                // Refreshing the user manually as the refresh function would activate the routing gate.
                axios.get(route("users.show", { user: "me" })).then((response) => {
                    update(userTransformer(response.data), 'local-only');
                })
            }
            else {
                const toPay = searchParams.has("paid_amount") ? Number(searchParams.get("paid_amount")) : todayToPay;
                setPaymentSuccess(toPay > 0 ? "successful-payment" : "successful-modification");
                navigate("/app/settings/subscription/payment-success");
            }
        }
    }, [redirected, navigate, isPlanDifferent, todayToPay, setPaymentSuccess, setFormLoading, redirectType, setSearchParams, selectedBillingPeriod, selectedSubscriptionTier, update, userTransformer, searchParams]);
    
    useSocket(`subscriptions.updated`, (payload: {
        billed_subscription: ApiSubscripitonData;
        next_billed_subscription: ApiSubscripitonData;
        subscription_tier: SubscriptionTier;
    }) => {
        update(userTransformer({
            billed_subscription: payload.billed_subscription,
            next_billed_subscription: payload.next_billed_subscription,
            subscription_tier: payload.subscription_tier,
        }), 'local-only');
        const toPay = searchParams.has("paid_amount") ? Number(searchParams.get("paid_amount")) : todayToPay;
        setPaymentSuccess(toPay > 0 ? "successful-payment" : "successful-modification");
        navigate("/app/settings/subscription/payment-success");
    }, [update, navigate, userTransformer, setPaymentSuccess, todayToPay, searchParams]);

    const handleSubmit = (elements: StripeElements | undefined, stripe: Stripe | null) => async (data: CheckoutForm) => {
        setFormLoading(true);

        if (!showWallet && (await elements?.submit())?.error !== undefined) {
            setFormLoading(false);
            return;
        }

        update(userTransformer({
            invoice_address_1: data.invoice_address_1,
            invoice_address_2: data.invoice_address_2,
            invoice_city: data.invoice_city,
            invoice_country: data.invoice_country,
            invoice_name: data.invoice_name,
            invoice_postal_code: data.invoice_postal_code,
            invoice_state: data.invoice_state,
            invoice_vat_id: data.invoice_vat_id,
            invoice_vat_type: data.invoice_vat_type,
        }), 'local-only'); // Update the user locally

        const response = await axios.post<{
            type: "payment" | "setup" | "no-action";
            client_secret: string;
        }>(route("checkout"), {
            ...data,
            subscription_tier: selectedSubscriptionTier,
            billing_period: selectedBillingPeriod,
        });

        if (response.data.type === "no-action") {
            // No action with stripe required, still have to wait for the socket event
            return;
        }

        const confirmIntent = response.data.type === "setup" ? stripe?.confirmSetup : stripe?.confirmPayment;

        await confirmIntent?.({
            elements,
            clientSecret: response.data.client_secret,
            confirmParams: {
                return_url: `${window.location.href}${window.location.href.includes('?') ? '&' : '?'}redirect_type=checkout&paid_amount=${todayToPay}`,
                payment_method_data: {
                    billing_details: {
                        address: {
                            country: data.invoice_country,
                            city: data.invoice_city,
                            postal_code: data.invoice_postal_code,
                            line1: data.invoice_address_1,
                            line2: data.invoice_address_2,
                        },
                        name: data.invoice_name,
                        email: user.email,
                    }
                },
            },
            redirect: "if_required",
        });
    }

    if (selectedSubscriptionTier === "free" || !['monthly', 'yearly'].includes(selectedBillingPeriod)) {
        return <Navigate to="/app/settings/subscription" />
    }

    return (
        <Loadable loading={pageLoading} fade>
            <Elements stripe={stripePromise} options={{
                mode: "subscription",
                amount: ((selectedSubscriptionPrices?.subscription ?? 0) * 100 * (1 + (taxRate ?? 0))), // in cents
                currency: "eur",
                payment_method_types: ["card", "paypal"],
            }}>
                <ElementsConsumer>
                    {({ elements, stripe }) => (
                        <Form form={form} onSubmit={handleSubmit(elements ?? undefined, stripe)}>
                            <div className="checkout">
                                <Column gap="30px" maxWidth="450px" width="100%" className="details">
                                    <Back to="/app/settings/subscription" />
                                    {showWallet ? (
                                        <Wallet
                                            name="payment_method"
                                            options={{
                                                required: t("settings.checkout.paymentMethod.required")
                                            }}
                                        />
                                    ) : (
                                        !pageLoading && (
                                            <PaymentElement options={{
                                                layout: "accordion",
                                                fields: {
                                                    billingDetails: {
                                                        name: "never",
                                                        email: "never",
                                                        address: {
                                                            city: "never",
                                                            country: "never",
                                                            line1: "never",
                                                            line2: "never",
                                                            postalCode: "never",
                                                        }
                                                    },
                                                }
                                            }} />
                                        )
                                    )}
                                    <InvoiceAddressFields
                                        vatIdRequired={!countrySupported}
                                    />
                                </Column>
                                <Column hAlign="center" flex={1}>
                                    <Column gap="25px" className="summary-wrapper">
                                        <Column className="summary" gap="50px">
                                            <h3>{t("settings.checkout.summary")}</h3>
                                            <Column gap="20px">
                                                <Row align="space">
                                                    <span>
                                                        {t("settings.checkout.summary.item", {
                                                            socialAccounts: selectedPlan.social_accounts,
                                                            tier: t(`subscriptionTier.${selectedSubscriptionTier}`),
                                                        })}
                                                    </span>
                                                    <span className="price">
                                                        {formatPrice(selectedSubscriptionPrices?.subscription)}
                                                    </span>
                                                </Row>
                                                {selectedPlan.max_additional_accounts > 0 && (
                                                    <Row align="space" vAlign="center">
                                                        <Row gap="12px" vAlign="center">
                                                            <Input
                                                                name="additional_social_accounts"
                                                                type="select"
                                                            >
                                                                {Array.from({ length: selectedPlan.max_additional_accounts + 1 }, (_, i) => i).map((i) => (
                                                                    <option key={i} value={i}>{i}</option>
                                                                ))}
                                                            </Input>
                                                            <span>
                                                                {t("settings.checkout.summary.additionalAccounts")}
                                                            </span>
                                                        </Row>
                                                        <span className="price">
                                                            {formatPrice(additionalAccountsPrice)}
                                                        </span>
                                                    </Row>
                                                )}
                                            </Column>
                                            <Column gap="15px">
                                                <Divider />
                                                <Row align="space">
                                                    <span>{t("settings.checkout.summary.subtotal")}</span>
                                                    <span className="price">
                                                        {formatPrice(subtotal)}
                                                    </span>
                                                </Row>
                                                <Row gap="12px" vAlign="bottom" align="space">
                                                    {taxCheckLoading ? (
                                                        <span>{t("settings.checkout.summary.vat.loadingNote")}</span>
                                                    ) : (
                                                        taxRate === null ? (
                                                            <span>{t("settings.checkout.summary.vat.missingNote")}</span>
                                                        ) : (
                                                            <>
                                                                <span>{t("settings.checkout.summary.vat", { vat: taxRate * 100 })}</span>
                                                                {vatIdValid ? (
                                                                    <span className="note">{t("settings.checkout.summary.vat.reverseCharge")}</span>
                                                                ) : (
                                                                    <span className="price">{formatPrice(tax)}</span>
                                                                )} 
                                                            </>
                                                        )
                                                    )}

                                                </Row>
                                                <Divider />
                                                <Row align="space">
                                                    <span>{t("settings.checkout.summary.total")}</span>
                                                    <table>
                                                        <tbody>
                                                            <tr>
                                                                <td className="total-note">{t("settings.checkout.summary.billingPeriod", {
                                                                    billingPeriod: t(`billingPeriod.${selectedBillingPeriod}`)
                                                                })}</td>
                                                                <td className="total">{formatPrice(perYear)}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="total-note">{t("settings.checkout.summary.todayToPay")}</td>
                                                                <td className={classNames("total", "green")}>{formatPrice(todayToPay)}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </Row>
                                            </Column>
                                        </Column>
                                        <TooltipParent disabled={taxRate !== null} style={{ width: "100%" }}>
                                            <Button variant="primary" loading={formLoading} disabled={!isPlanDifferent || taxRate === null} submit>
                                                {isPlanDifferent ? t("settings.checkout.submit") : t("planCard.currentPlan")}
                                                <Tooltip>{t(`settings.checkout.submit.tooltip.${taxCheckLoading ? "loading" : "fieldsMissing"}`)}</Tooltip>
                                            </Button>
                                        </TooltipParent>
                                        <Column className="checkout-privacy-note" hAlign="center" gap="6px">
                                            <ShieldIcon />
                                            <span>{t("settings.checkout.privacyNote")}</span>
                                        </Column>
                                    </Column>
                                </Column>
                            </div>
                        </Form>
                    )}
                </ElementsConsumer>
            </Elements>
        </Loadable >
    )
}