import "@/css/pages/settings/payment-info.scss";
import InvoiceAddressFields, { InvoiceAddressForm } from "@/js/Components/InvoiceAddressFields";
import Wallet from "@/js/Components/Wallet";
import { useUser } from "@/js/Providers/UserProvider";
import { Button } from "@/js/glidespec";
import { PaymentMethod, usePaymentMethods } from "@/js/resources";
import { Form, SubmitHandler, setFormValues } from "@enymo/react-form-component";
import { Column, Row } from "@enymo/react-layout";
import { assertNotNull } from "@enymo/ts-nullsafe";
import axios from "axios";
import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import route from "ziggy-js";

export default function PaymentInfo() {

    const { t } = useTranslation();
    const { user, update: updateUser } = useUser();
    assertNotNull(user);

    const form = useForm<InvoiceAddressForm>();
    const [paymentMethods, { update }] = usePaymentMethods();
    const defaultPaymentMethodId = useMemo(
        () => (paymentMethods.find((paymentMethod) => paymentMethod.in_use) ?? paymentMethods?.[0])?.id ?? null,
        [paymentMethods]
    );
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<string | undefined>(defaultPaymentMethodId);

    useEffect(() => {
        setSelectedPaymentMethod(defaultPaymentMethodId);
    }, [defaultPaymentMethodId, setSelectedPaymentMethod]);


    const handleChangePaymentMethod = async () => {
        const paymentMethods = (await axios.post<PaymentMethod[]>(route("payment-methods.default", {
            paymentMethod: selectedPaymentMethod 
        }))).data;
        for (const paymentMethod of paymentMethods) {
            update(paymentMethod.id, paymentMethod, 'local-only');
        }
    }

    useEffect(() => {
        setFormValues(form, {
            invoice_name: user.invoice_name ?? "",
            invoice_address_1: user.invoice_address_1 ?? "",
            invoice_address_2: user.invoice_address_2 ?? "",
            invoice_postal_code: user.invoice_postal_code ?? "",
            invoice_city: user.invoice_city ?? "",
            invoice_country: user.invoice_country?.toLowerCase() ?? "de",
            invoice_vat_id: user.invoice_vat_id ?? "",
            invoice_state: user.invoice_state ?? "",
            invoice_vat_type: user.invoice_vat_type ?? undefined,
        });
    }, [user, setFormValues]);

    const handleSubmit: SubmitHandler<InvoiceAddressForm> = async (data) => {
        await updateUser(data);
    }

    return (
        <div className="payment-info">
            <Column gap="40px" className="payment-method-wrapper" flex={1}>
                <Wallet
                    value={selectedPaymentMethod}
                    onChange={setSelectedPaymentMethod}
                />
                <Row align="end">
                    <Button
                        variant="primary"
                        disabled={defaultPaymentMethodId === selectedPaymentMethod}
                        onClick={handleChangePaymentMethod}
                    >
                        {t("settings.paymentInfo.saveChange")}
                    </Button>
                </Row>
            </Column>
            <Column className="invoice-address-wrapper" flex={1}>
                <Form form={form} onSubmit={handleSubmit}>
                    <Column maxWidth="450px" gap="40px">
                        <InvoiceAddressFields vatIdRequired={!!user.invoice_vat_id} />
                        <Row align="end">
                            <Button variant="primary" submit>{t("save")}</Button>
                        </Row>
                    </Column>
                </Form>
            </Column>
        </div>
    );
}