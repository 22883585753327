import { FacebookButton, GoogleButton, LinkedInButton } from "@/js/Components/SocialButtons";
import Tooltip, { TooltipParent } from "@/js/Components/Tooltip";
import { useUser } from "@/js/Providers/UserProvider";
import { Button, PasswordInput } from "@/js/glidespec";
import { Form, SubmitHandler } from "@enymo/react-form-component";
import { Column, Row } from "@enymo/react-layout";
import { assertNotNull } from "@enymo/ts-nullsafe";
import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

interface Submit {
    current_password?: string,
    password: string
}

export default function Password() {
    const {t} = useTranslation();
    const form = useForm<Submit>();
    const {user, update} = useUser();

    assertNotNull(user, "User must be logged in");
    
    const handleSubmit: SubmitHandler<Submit> = async data => {
        await update(data);
        form.reset();
    }

    const handleRemoveGoogle = async () => {
        await update({google_id: null});
    }

    const handleRemoveFacebook = async () => {
        await update({facebook_id: null});
    }

    const handleRemoveLinkedIn = async () => {
        await update({linkedin_id: null});
    }

    return (
        <Column gap="20px">
            <Column gap="30px" maxWidth="450px" width="100%">
                <h2>{user.has_password ? t("settings.password.changePassword") : t("settings.password.addPassword")}</h2>
                <Form form={form } onSubmit={handleSubmit}>
                    <Column gap="40px">
                        <Column gap="20px">
                            {user.has_password && (
                                <PasswordInput name="current_password" label={t("settings.password.currentPassword")} options={{
                                    required: t("settings.password.currentPassword.required")
                                }} />
                            )}
                            <PasswordInput name="password" label={t("settings.password.newPassword")} options={{
                                required: t("settings.password.newPassword.required")
                            }} />
                        </Column>
                        <Row align="end">
                            <Button variant="primary" submit>{t("save")}</Button>
                        </Row>
                    </Column>
                </Form>
            </Column>
            {(user.has_google || user.has_facebook || user.has_linkedin) && (
                <Column gap="30px" hAlign="left">
                    <h2>{t("settings.password.activeSocialLogins")}</h2>
                    {user.has_google && (
                        <Row gap="20px" vAlign="center">
                            <GoogleButton disabled />
                            <TooltipParent>
                                <Button variant="dangerText" onClick={handleRemoveGoogle} disabled={!user.has_password && !user.has_facebook && !user.has_linkedin}>{t("settings.password.activeSocialLogins.remove")}</Button>
                                {!user.has_password && !user.has_facebook && !user.has_linkedin && <Tooltip>{t("settings.password.activeSocialLogins.remove.disabled")}</Tooltip>}
                            </TooltipParent>
                        </Row>
                    )}
                    {user.has_facebook && (
                        <Row gap="20px" vAlign="center">
                            <FacebookButton disabled />
                            <TooltipParent>
                                <Button variant="dangerText" onClick={handleRemoveFacebook} disabled={!user.has_password && !user.has_google && !user.has_linkedin}>{t("settings.password.activeSocialLogins.remove")}</Button>
                                {!user.has_password && !user.has_google && !user.has_linkedin && <Tooltip>{t("settings.password.activeSocialLogins.remove.disabled")}</Tooltip>}
                            </TooltipParent>
                        </Row>
                    )}
                    {user.has_linkedin && (
                        <Row gap="20px" vAlign="center">
                            <LinkedInButton disabled />
                            <TooltipParent>
                                <Button variant="dangerText" onClick={handleRemoveLinkedIn} disabled={!user.has_password && !user.has_facebook && !user.has_google}>{t("settings.password.activeSocialLogins.remove")}</Button>
                                {!user.has_password && !user.has_facebook && !user.has_google && <Tooltip>{t("settings.password.activeSocialLogins.remove.disabled")}</Tooltip>}
                            </TooltipParent>
                        </Row>
                    )}
                </Column>
            )}
            {(!user.has_google || !user.has_facebook || !user.has_linkedin) && (
                <Column gap="30px">
                    <h2>{t("settings.password.addSocialLogin")}</h2>
                    <Row gap="10px">
                        {!user.has_google && <GoogleButton />}
                        {!user.has_facebook && <FacebookButton />}
                        {!user.has_linkedin && <LinkedInButton />}
                    </Row>
                </Column>
            )}
        </Column>
    )
}