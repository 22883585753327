import "@/css/components/dropdown-button.scss";
import useOnClickOutside from "@enymo/react-click-outside-hook";
import { Clickable, ClickableProps } from "@enymo/react-clickable-router";
import classNames from "classnames";
import React, { useMemo, useState } from "react";
import { ReactElements } from "../types";
import MenuButtonComponent from "./MenuButton";

export interface DropdownButtonItemProps extends ClickableProps {
    variant?: "normal" | "danger"
}

export function DropdownButtonItem({className, variant = "normal", ...props}: DropdownButtonItemProps) {
    return <Clickable
        className={classNames(className, variant)}
        {...props}
    />
}

export default function DropdownButton({
    children,
    menuButton: menuButtonProp,
}: {
    children: ReactElements<DropdownButtonItemProps>,
    menuButton?: React.ReactElement<{onClick: () => void}>
}) {
    const [showMenu, setShowMenu] = useState(false);
    const ref = useOnClickOutside<HTMLDivElement>(() => setShowMenu(false), [setShowMenu]);

    const menuButton = useMemo(() => (
        menuButtonProp ? React.cloneElement(menuButtonProp, {
            onClick: () => setShowMenu((showMenu) => !showMenu),
        }) : <MenuButtonComponent onClick={() => setShowMenu((showMenu) => !showMenu)} />
    ), [menuButtonProp, setShowMenu]);

    
    return (
        <div ref={ref} className="dropdown-wrapper">
            {menuButton}
            {showMenu && (
                <div className="dropdown">{children}</div>
            )}
        </div>
    )
}