import "@/css/components/divider.scss";
import React from "react";

export default function Divider({ children }: {
    children?: React.ReactNode
}) {
    return (
        <div className="divider">
            <div />
            {children && (
                <>
                    <span>{children}</span>
                    <div />
                </>
            )}
        </div>
    )
}