import { CollisionDetection } from "@dnd-kit/core";
import compare from "@enymo/comparison";
import { ReturnList } from "@enymo/react-resource-hook";
import { assertNotNull, isNotNull, requireNotNull } from "@enymo/ts-nullsafe";
import { loadStripe } from "@stripe/stripe-js";
import { DeepPartial } from "ts-essentials";
import route from "ziggy-js";
import { SocialChannel } from "./resources";

export { version } from "../../package.json";

export const TOS_URL = "https://superfred.com/terms-of-use";
export const PRIVACY_URL = "https://superfred.com/privacy-policy";
export const SUPPORT_URL = "https://help.superfred.com/";

export const OPENAI_TOS_URL = "https://openai.com/policies/terms-of-use/";
export const OPENAI_PRIVACY_URL = "https://openai.com/policies/privacy-policy/";

export const languages = <const>["de", "en"];

export const socialAccountTypes = <const>["facebook", "instagram", "tiktok", "youtube", "linkedin"];

export const weekdays = <const>["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];
export const months = <const>["january", "february", "march", "april", "may", "june", "july", "august", "september", "october", "november", "december"];

export const aiStyles = <const>["funny", "inspirational", "informative", "provocative", "supportive", "narrative", "analytical", "advocatory", "networking"];

export const currentYear = new Date().getFullYear();

export const EmailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

export const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLIC_KEY);

export const supportedCountries = <const>[
    "de",
    "at",
];

export const hashtagDifficulties = <const>["easy", "medium", "tricky", "heavy", "hard", "brutal"];

export const hashtagDifficultyColors = <const>{
    "easy": "var(--blue)",
    "medium": "var(--green)",
    "tricky": "var(--yellow)",
    "heavy": "var(--light-orange)",
    "hard": "var(--orange)",
    "brutal": "var(--red)",
};
export type HashtagDifficulty = "easy" | "medium" | "tricky" | "heavy" | "hard" | "brutal";

export const subscriptionTiers = <const>["free", "plus", "pro", "agency_1", "agency_2"];
export type SubscriptionTier = typeof subscriptionTiers[number];

export interface SubscriptionTierPrice {
    subscription: number;
    additional_social_account: number;
}

export interface SubscriptionTierPrices {
    "plus": {
        "monthly": SubscriptionTierPrice;
        "yearly": SubscriptionTierPrice;
    },
    "pro": {
        "monthly": SubscriptionTierPrice;
        "yearly": SubscriptionTierPrice;
    },
    "agency_1": {
        "monthly": SubscriptionTierPrice;
        "yearly": SubscriptionTierPrice;
    },
    "agency_2": {
        "monthly": SubscriptionTierPrice;
        "yearly": SubscriptionTierPrice;
    }
}

export function resourcesToLinkedList<T extends ResourceLinkedListNode>(resources: T[]): (T & LinkedListNode)[] {
    return resources.filter((resource) => resource.id !== undefined) as unknown as (T & LinkedListNode)[];
}

interface ResourceLinkedListNode {
    id?: number;
    previous_id: number | null;
}

interface LinkedListNode {
    id: number;
    previous_id: number | null;
}

export type LinkedList = LinkedListNode[];

export function getLastLinkedListItem<T extends LinkedListNode>(list: T[]): T | null {
    const elementsWithNext = new Set(list.map(({ previous_id }) => previous_id));
    return list.find(({ id }) => !elementsWithNext.has(id)) ?? null;
}

/**
 * Function to sort a linked list.
 */
export function sortLinkedList<T extends LinkedListNode>(list: T[]) {
    const sortedList: T[] = [];
    const idMap = new Map<number, T>(list.map(node => [node.id, node]));
    let currentNode = getLastLinkedListItem(list);
    while (currentNode !== null) {
        sortedList.push(currentNode);
        currentNode = !isNotNull(currentNode.previous_id) ? null : idMap.get(currentNode.previous_id) ?? null;
    }
    return sortedList.reverse();
}

export function linkedListFindById<T extends LinkedListNode>(list: T[], id: number) {
    return list.find(node => node.id === id);
}

/**
 * Creates a function that can be used to sort a linked list. This corresponds to the default sorting behaviour of the table component.
 * @param data The data corresponding to the table data
 * @param setData The setter for the data
 */
export function defaultDragAndDropSorting<T extends LinkedListNode>(data: T[], setData: (data: T[]) => void) {
    return (fromId: number | string, toId: number | string, direction: "up" | "down") => {
        let newData = [...data];

        const initial = newData.find(row => row.id === fromId) ?? null;
        assertNotNull(initial);

        let initialNext = newData.find(row => row.previous_id === fromId) ?? null;
        let afterNext: T | null;
        let afterPrevious: T | null;

        if (direction === "down") {
            afterNext = newData.find(row => row.previous_id === toId) ?? null;
            afterPrevious = newData.find(row => row.id === toId) ?? null;
        } else {
            afterNext = newData.find(row => row.id === toId) ?? null;
            afterPrevious = newData.find(row => row.id === afterNext?.previous_id) ?? null;
        }

        if (initialNext !== null) {
            initialNext.previous_id = initial.previous_id;
        }
        if (afterNext !== null) {
            afterNext.previous_id = initial.id;
        }
        initial.previous_id = afterPrevious?.id ?? null;


        setData(newData);

    }
}

export function resourceDragAndDropSorting<T extends ResourceLinkedListNode>(data: T[], update: ReturnList<T, T, null>["update"]) {
    return (fromId: number | string, toId: number | string, direction: "up" | "down") => {
        const initial = requireNotNull(data.find(node => node.id === fromId));
        const initialNext = data.find(node => node.previous_id === fromId);
        const [afterNext, afterPrevious] = direction === "down" ? [
            data.find(node => node.previous_id === toId),
            data.find(node => node.id === toId)
        ] : (() => {
            const afterNext = data.find(node => node.id === toId);
            return [
                afterNext,
                data.find(node => node.id === afterNext?.previous_id)
            ];
        })();

        if (isNotNull(initialNext)) {
            update(initialNext.id, {
                previous_id: initial.previous_id
            } as DeepPartial<T>, "local-only");
        }
        if (isNotNull(afterNext)) {
            update(afterNext.id, {
                previous_id: initial.id
            } as DeepPartial<T>, "local-only");
        }

        return update(initial.id, {
            previous_id: afterPrevious?.id ?? null
        } as DeepPartial<T>, "immediate");
    }
}

export async function deleteLinkedListItem<T extends ResourceLinkedListNode>(list: T[], id: number, update: ReturnList<T, T, null>["update"], destroy: ReturnList<T, T, null>["destroy"]) {
    const node = requireNotNull(list.find(node => node.id === id));
    const nextNode = list.find(node => node.previous_id === id);
    
    if (isNotNull(nextNode)) {
        update(nextNode.id, {
            previous_id: node.previous_id
        } as DeepPartial<T>, "local-only");
    }
    return destroy(id, "immediate");
}

interface Rect {
    left: number;
    right: number;
    top: number;
    bottom: number;
}

function intersectionArea(rect1: Rect, rect2: Rect) {
    return Math.max(0,
        Math.min(rect1.right, rect2.right) -
        Math.max(rect1.left, rect2.left)
    ) * Math.max(
        0,
        Math.min(rect1.bottom, rect2.bottom) -
        Math.max(rect1.top, rect2.top)
    );
}

interface Point {
    x: number;
    y: number;
}

function distance(point1: Point, point2: Point) {
    return Math.sqrt(
        Math.pow(point1.x - point2.x, 2) +
        Math.pow(point1.y - point2.y, 2)
    );
}

function rectCenter(rect: Rect) {
    return {
        x: rect.left + rect.right / 2,
        y: rect.top + rect.bottom / 2,
    };
}

export const variableSizeItemsCollisionDetection: CollisionDetection = ({
    active,
    droppableRects,
    droppableContainers,
}) => {
    // Which colliding element is the farthest from the initial position of the dragged element
    // Calculate the distance between the bottom of the dragged element and the top of the colliding element
    // The colliding element is the one with the smallest distance which also has an overlap of at least 50%

    if (!active.rect.current.initial || !active.rect.current.translated) {
        return [];
    }

    // Get all colliding elements with an overlap of at least 50%
    const currentRect = {
        right: Math.max(
            active.rect.current.translated.right,
            active.rect.current.initial.right
        ),
        left: Math.min(
            active.rect.current.translated.left,
            active.rect.current.initial!.left
        ),
        top: Math.min(
            active.rect.current.translated.top,
            active.rect.current.initial.top
        ),
        bottom: Math.max(
            active.rect.current.translated.bottom,
            active.rect.current.initial.bottom
        ),
    };
    
    // Find the candidate with the largest distance from the initial position of the dragged element
    const initialCenter = rectCenter(active.rect.current.initial);

    return droppableContainers.filter(({ id }) => {
        const dropZone = droppableRects.get(id);
        if (!dropZone) return false;
        const dropZoneSurface = dropZone.width * dropZone.height;
        const overlap = intersectionArea(currentRect, dropZone);
        return overlap / dropZoneSurface >= 0.5;
    }).sort((a, b) => -compare(
        distance(rectCenter(droppableRects.get(a.id)!), initialCenter),
        distance(rectCenter(droppableRects.get(b.id)!), initialCenter),
    ));
}

export function getTimeZoneI18nKey(timeZone: string) {
    return `timezone.${timeZone.replaceAll("/", ".").toLowerCase().replace(/(?:_|(?<!gmt)-)(.)/g, (_, group) => group.toUpperCase())}`;
}

export function sleep(ms: number) {
    return new Promise<void>(resolve => setTimeout(resolve, ms));
}

export enum DateFormat {
    DATE = 0b1 << 0,
    TIME = 0b1 << 1
}

export function formatDate(date: Date | null | undefined, flags: number) {
    if (!isNotNull(date)) {
        return date;
    }

    const result = [];
    if (flags & DateFormat.DATE) {
        result.push(`${date.getDate().toString().padStart(2, "0")}.${(date.getMonth() + 1).toString().padStart(2, "0")}.${date.getFullYear()}`);
    }
    if (flags & DateFormat.TIME) {
        result.push(`${date.getHours()}:${date.getMinutes().toString().padStart(2, "0")}`);
    }
    return result.join(" ");
}

export const pad = (n: number) => `${Math.floor(Math.abs(n))}`.padStart(2, '0');

export function convertTimezone(date: Date, timezone: string) {
    return new Date(date.toLocaleString("en-US", { timeZone: timezone }));
}

export function toISOStringWithTimezone(date: Date | null | undefined, dateOnly: boolean = false) {
    if (!isNotNull(date)) {
        return date;
    }
    const tzOffset = -date.getTimezoneOffset();
    const diff = tzOffset >= 0 ? '+' : '-';
    const dateString = date.getFullYear() +
        '-' + pad(date.getMonth() + 1) +
        '-' + pad(date.getDate());
    return dateOnly ? dateString : dateString +
        'T' + pad(date.getHours()) +
        ':' + pad(date.getMinutes()) +
        ':' + pad(date.getSeconds()) +
        diff + pad(tzOffset / 60) +
        ':' + pad(tzOffset % 60);
};

export function randomString(length: number) {
    const chars = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    return Array<void>(length).fill().map(() => chars[Math.floor(Math.random() * chars.length)]).join("");
}

export function initials(name: string): string {
    return name.split(" ").map(([i]) => i).join("");
}

export function calendarInfo(date: Date) {
    const prevMonth = new Date(date);
    prevMonth.setDate(0);
    
    const currentMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    const dayToWeekday = new Map<number, number>();
    for (let i = 0; i < currentMonth.getDate(); i++) {
        dayToWeekday.set(i + 1, (getDay(new Date(date.getFullYear(), date.getMonth(), i)) + 1) % 7);
    }

    const result = {
        prevMonthDays: prevMonth.getDate(),
        prevMonthPartialWeek: (getDay(prevMonth) + 1) % 7,
        currentMonthDays: currentMonth.getDate(),
        currentMonthPartialWeek: 6 - getDay(currentMonth),
        dayToWeekday
    }
    return result;
}

export function getDay(date: Date): number {
    const day = date.getDay() - 1;
    return day < 0 ? day + 7 : day;
}

export function sameDate(a: Date, b: Date): boolean {
    return a.getDate() === b.getDate()
        && a.getMonth() === b.getMonth()
        && a.getFullYear() === b.getFullYear();
}

export function randomItem<T>(input: T[]): T {
    return input[Math.floor(Math.random() * input.length)];
}

export function channelAvatar(channel: Pick<SocialChannel, "id" | "has_avatar">): string | undefined {
    return channel.has_avatar ? route("social-channels.avatar", {social_channel: channel.id}) : undefined;
}

export function resolveSpintax(input: string, mode: "random" | "longest" = "random") {
    const stack: number[] = [];
    for (let i = 0; i < input.length; i++) {
        switch (input[i]) {
            case "\\":
                i++;
                break;
            case "{":
                stack.push(i);
                break;
            case "}":
                const start = stack.pop()!;
                const elements = input.slice(start + 1, i).split(/(?<!(?<!\\)\\)\|/);
                const selected = mode === "random" ? randomItem(elements) : elements.reduce((a, b) => a.length > b.length ? a : b);
                input = input.slice(0, start) + selected + input.slice(i + 1);
                i = start + selected.length - 1;
                break;
        }
    }

    return input.replace(/\\(.)/g, "$1");
}