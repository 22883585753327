import "@/css/components/switcher-input.scss";
import { Column, Row } from "@enymo/react-layout";
import { isNotNull } from "@enymo/ts-nullsafe";
import React, { useId } from "react";
import { useFormContext } from "react-hook-form";
import Switcher, { SwitcherProps } from "./Switcher";

export interface SwitcherInputProps extends SwitcherProps {
    label?: string,
    activeLabel: string,
    inactiveLabel: string,
    description?: string
}

export function SwitcherInput({
    label,
    activeLabel,
    inactiveLabel,
    description,
    ...props
}: SwitcherInputProps) {
    const id = useId();
    const active = isNotNull(props.name) ? useFormContext()?.watch(props.name) : props.value ?? false

    return (
        <div className="switcher-input">
            {label && <label htmlFor={id}>{label}</label>}
            <Row gap="16px">
                <Switcher {...props} />
                <Column gap="5px" flex="initial">
                    <span className="active-label">{active ? activeLabel : inactiveLabel}</span>
                    {description && <span className="description">{description}</span>}
                </Column>
            </Row>
        </div>
    )
}