import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { PRIVACY_URL, TOS_URL } from "../common";
import { Checkbox } from "../glidespec";

export default function TosCheckbox() {
    const {t} = useTranslation();

    return (
        <Checkbox name="tos" options={{
            required: t("signUp.tos.required")
        }}>
            <Trans i18nKey="signUp.tos">
                I accept the Fred <a className="link" href={TOS_URL} target="_blank" referrerPolicy="no-referrer">Terms of Service</a> and <a className="link" href={PRIVACY_URL} target="_blank" referrerPolicy="no-referrer">Privacy Policy</a>
            </Trans>
        </Checkbox>
    )
}