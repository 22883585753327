import Popup, { PopupFooter } from "@/js/Components/Popup";
import SearchField from "@/js/Components/SearchField";
import SocialChannelInfo from "@/js/Components/SocialChannelInfo";
import Table from "@/js/Components/Table";
import TableActions from "@/js/Components/TableActions";
import useBreakpoint from "@/js/Hooks/BreakpointHook";
import { useTitle } from "@/js/Layouts/Main";
import { useConfirmPopup } from "@/js/Providers/ConfirmPopupProvider";
import { useUser } from "@/js/Providers/UserProvider";
import { DateFormat, formatDate, resourceDragAndDropSorting, sortLinkedList } from "@/js/common";
import { Button, Input } from "@/js/glidespec";
import { useCampaigns, useSocialChannels, useUsers } from "@/js/resources";
import { findToggledElement } from "@enymo/glide";
import { Form, SubmitHandler } from "@enymo/react-form-component";
import { Column, Row } from "@enymo/react-layout";
import { assertNotNull, isNotNull } from "@enymo/ts-nullsafe";
import React, { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

interface Submit {
    name: string
}

export default function Campaigns() {
    const { t } = useTranslation();
    const {user} = useUser();
    const [users] = useUsers();
    assertNotNull(user, "Must be logged in");
    const [campaigns, {store, update, destroy, loading}] = useCampaigns();
    const canActivateCampaign = useMemo(() => Object.fromEntries(users.map(({id, can_activate_campaigns}) => [id, (can_activate_campaigns - campaigns.reduce((sum, campaign) => campaign.owner.id === id && campaign.active ? sum + 1 : sum, 0)) > 0])), [campaigns, users]);
    const sortedCampaigns = useMemo(() => sortLinkedList(campaigns), [campaigns]);
    const [search, setSearch] = useState("");
    const filteredCampaigns = useMemo(() => search ? sortedCampaigns.filter(campaign => campaign.name.toLowerCase().includes(search.toLowerCase())) : sortedCampaigns, [sortedCampaigns, search]);
    const activeCampaigns = useMemo(() => campaigns.flatMap(({id, active}) => active ? [id] : []), [campaigns]);
    const [showAddPopup, setShowAddPopup] = useState(false);
    const [socialChannels] = useSocialChannels();
    const hasUnowned = useMemo(() => campaigns.some(({owner: {id}}) => id !== user.id), [campaigns, user]);
    const form = useForm<Submit>();

    const tableMobile = useBreakpoint(1330);
    const mobile = useBreakpoint(768);

    const withPopup = useConfirmPopup();

    useTitle(t("campaigns"));

    const handleCreateCampaign: SubmitHandler<Submit> = async data => {
        await store(data);
        handleClosePopup();
    }

    const handleToggleActive = (state: number[]) => {
        const toggled = findToggledElement(activeCampaigns, state);
        update(toggled, {active: !activeCampaigns.includes(toggled)}, "immediate");
    }

    const handleClosePopup = () => {
        form.reset();
        setShowAddPopup(false);
    }

    const handleDeleteCampaign = (id: number) => () => {
        withPopup("warning", t("campaigns.delete.button"), t("campaigns.delete.title"), t("campaigns.delete.text"), async () => {
            await destroy(id, "immediate");
        });
    }

    return (
        <Column padding={mobile ? "25px 0" : "50px 60px"} gap={mobile ? "25px" : "30px"}>
            <Row align="space" gap="20px" padding={mobile ? "0 16px" : undefined}>
                <Button variant="primary" style={{flexShrink: 0}} onClick={() => setShowAddPopup(true)}>{mobile ? t("campaigns.add.mobile") : t("campaigns.add")}</Button>
                <SearchField value={search} onChange={setSearch} maxWidth={mobile ? "200px" : undefined} placeholder={mobile ? t("search") : t("campaigns.search.placeholder")} />
            </Row>
            <Table
                dataLoading={loading}
                head={[...(tableMobile ? [{
                    label: t("campaigns.campaign")
                }] : [{
                    label: t("campaigns.name"),
                    fillWidth: true
                }, {
                    label: t("campaigns.table.socialAccounts")
                }, ...(hasUnowned ? [{
                    label: t("campaigns.owner")
                }] : []), {
                    label: t("campaigns.startDate")
                }, {
                    label: t("campaigns.endDate")
                }]), {
                    label: t("actions")
                }]}
                rows={filteredCampaigns.map(({id, name, start_date, end_date, active_social_channels, active, owner: {id: owner_id, first_name, last_name}}) => ({
                    id,
                    switcherDisabled: !active && !canActivateCampaign[owner_id] ? (owner_id === user.id ? t("planExceeded.owner") : t("planExceeded.share")) : false,
                    data: [
                        ...(tableMobile ? [
                            <Column gap="8px">
                                <Link style={{
                                    fontWeight: 500,
                                    color: "var(--text-700)",
                                    whiteSpace: "normal"
                                }} to={`/app/campaigns/${id}/posts`}>{name}</Link>
                                {isNotNull(start_date) || isNotNull(end_date) && <>
                                    {formatDate(start_date, DateFormat.DATE) ?? t("campaigns.startDate.undefined")} - {formatDate(end_date, DateFormat.DATE) ?? t("campaigns.endDate.undefined")}
                                </>}
                                <SocialChannelInfo maxDisplay={5} channels={socialChannels.filter(({id}) => active_social_channels.includes(id))} />
                                {hasUnowned && <span style={{
                                    fontSize: "14px",
                                    color: "var(--text-600)",
                                    fontWeight: 400
                                }}>
                                    {t("campaigns.owner.is", {owner: owner_id === user.id ? t("campaigns.owner.you") : `${first_name} ${last_name}`})}
                                </span>}
                            </Column>
                        ] : [
                            <Link className="blue-on-hover" to={`/app/campaigns/${id}/posts`}>{name}</Link>,
                            <SocialChannelInfo maxDisplay={5} channels={socialChannels.filter(({id}) => active_social_channels.includes(id))} />,
                            ...(hasUnowned ? [owner_id === user.id ? t("campaigns.owner.you") : `${first_name} ${last_name}`] : []),
                            formatDate(start_date, DateFormat.DATE) ?? "-",
                            formatDate(end_date, DateFormat.DATE) ?? "-"
                        ]),
                        <TableActions edit={{to: `/app/campaigns/${id}/settings`}} delete={{onClick: handleDeleteCampaign(id)}} />
                    ]
                }))}
                switcherHead={t("campaigns.table.status")}
                switcherValues={activeCampaigns}
                onSwitcherSwitched={handleToggleActive}
                onSortingChanged={resourceDragAndDropSorting(sortedCampaigns, update)}
                mobileBreakpoint={1330}
                paddingVertical="10px"
                placeholder={search ? t("campaigns.table.placeholder.search") : t("campaigns.table.placeholder")}
            />
            {showAddPopup && (
                <Popup width="500px" onBackgroundClick={handleClosePopup}>
                    <Form form={form} onSubmit={handleCreateCampaign}>
                        <Column gap="30px" padding="30px 25px">
                            <h1>{t("campaigns.add.title")}</h1>
                            <Input name="name" label={t("campaigns.add.name")} options={{
                                required: t("campaigns.add.name.required")
                            }} />
                        </Column>
                        <PopupFooter>
                            <Button variant="secondary" onClick={handleClosePopup}>{t("cancel")}</Button>
                            <Button variant="primary" submit>{t("save")}</Button>
                        </PopupFooter>
                    </Form>
                </Popup>
            )}
        </Column>
    )
}