import { isNotNull } from "@enymo/ts-nullsafe";
import { useEffect, useRef, useState } from "react";

export function useHovering<T extends HTMLElement>() {
    const ref = useRef<T>(null);
    const mouseEventCounter = useRef(0);
    const [hover, setHover] = useState(false);

    useEffect(() => {
        if (isNotNull(ref.current)) {
            const handleMouseEnter = () => {
                mouseEventCounter.current++;
                if (mouseEventCounter.current > 0) {
                    setHover(true);
                }
            }

            const handleMouseLeave = () => {
                mouseEventCounter.current--;
                if (mouseEventCounter.current === 0) {
                    setHover(false);
                }
            }

            ref.current.addEventListener("mouseenter", handleMouseEnter);
            ref.current.addEventListener("mouseleave", handleMouseLeave);

            return () => {
                ref.current?.removeEventListener("mouseenter", handleMouseEnter);
                ref.current?.removeEventListener("mouseleave", handleMouseLeave);
            }
        }
    }, [ref, mouseEventCounter, setHover]);

    return [ref, hover] as const;
}