import "@/css/components/hashtag.scss";
import HashtagIcon from "@/svg/hashtag-solid.svg?react";
import XIcon from "@/svg/xmark-solid.svg?react";
import { Row } from "@enymo/react-layout";
import classNames from "classnames";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { hashtagDifficultyColors } from "../common";
import { Hashtag as HashtagInterface } from "../resources";
import HashtagDifficultyTooltip from "./HashtagDifficultyTooltip";
import Loader from "./Loader";
import Tooltip, { TooltipParent } from "./Tooltip";

export default function Hashtag({
    type = "in-list",
    hashtag,
    onClick,
    onDelete,
    showDelete = false,
    deleteLoading: deleteLoadingProp,
}: {
    type?: "in-list" | "in-search" | "in-post" | "in-post-selection",
    hashtag: HashtagInterface,
    onClick?: () => void,
    onDelete?: () => void | Promise<void>,
    showDelete?: boolean,
    deleteLoading?: boolean,
}) {
    const { t } = useTranslation();

    const [deleteLoadingState, setDeleteLoadingState] = useState(false);
    const deleteLoading = deleteLoadingProp ?? deleteLoadingState;
    const handleDelete = async () => {
        if (onDelete) {
            setDeleteLoadingState(true);
            await onDelete();
            setDeleteLoadingState(false);
        }
    }

    return (
        <div className={classNames("hashtag", type, {
            deletable: !!onDelete,
            "delete-loading": deleteLoading,
            "show-delete": showDelete,
        })}>
            <HashtagIcon />
            <button className={classNames("name", { clickable: onClick })} onClick={onClick}>{hashtag.name}</button>
            <Row className="dot-delete-wrapper" vAlign="center">
                <TooltipParent>
                    {hashtag.difficulty ? (
                        <div className="dot" style={{
                            backgroundColor: hashtagDifficultyColors[hashtag.difficulty]
                        }} />
                    ) : (
                        <Loader className="dot" />
                    )}
                    {hashtag.difficulty && <HashtagDifficultyTooltip difficulty={hashtag.difficulty} />}
                </TooltipParent>
                {deleteLoading ? (
                    <Loader className="delete-loader" />
                ) : (
                    <TooltipParent>
                        <button
                            className="delete"
                            disabled={!onDelete}
                            onClick={handleDelete}
                        >
                            <XIcon />
                        </button>
                        <Tooltip>
                            {t("hashtag.deleteTooltip")}
                        </Tooltip>
                    </TooltipParent>
                )}
            </Row>
        </div>
    );
}
