import "@/css/pages/main/hashtags/research.scss";
import Hashtag from "@/js/Components/Hashtag";
import HashtagDifficultyBar from "@/js/Components/HashtagDifficultyBar";
import Loadable from "@/js/Components/Loadable";
import Loader from "@/js/Components/Loader";
import Popup, { PopupFooter } from "@/js/Components/Popup";
import { Style } from "@/js/Components/Style";
import useBreakpoint from "@/js/Hooks/BreakpointHook";
import { useUser } from "@/js/Providers/UserProvider";
import { Button, CheckboxSmall, Input } from "@/js/glidespec";
import { Hashtag as HashtagInterface, HashtagSearch, useHashtagLists, useHashtagSearches } from "@/js/resources";
import InfoIcon from "@/svg/circle-info-solid.svg?react";
import XIcon from "@/svg/xmark-regular.svg?react";
import { Form, SubmitHandler } from "@enymo/react-form-component";
import { Column, Row } from "@enymo/react-layout";
import { assertNotNull } from "@enymo/ts-nullsafe";
import axios from "axios";
import classNames from "classnames";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import route from "ziggy-js";

function SearchResultTab({ search: search, onDelete }: {
    search: HashtagSearch,
    onDelete: () => void | Promise<void>,
}) {
    const [searchParams, setSearchParams] = useSearchParams();

    const active = searchParams.get("search") === search.id.toString();

    const handleClick = () => {
        setSearchParams({ search: search.id.toString() });
    }

    return (
        <button className={classNames("search-result-tab", { active })} onClick={handleClick}>
            <span>{search.search_term}</span>
            <XIcon onClick={onDelete} />
        </button>
    )
}

function AddToListPopup({
    onBackgroundClick,
    onSubmit,
}: {
    onBackgroundClick: () => void | Promise<void>,
    onSubmit: (id: number) => void | Promise<void>,
}) {
    const { t } = useTranslation();
    const { user } = useUser();
    assertNotNull(user);
    const [inputType, setInputType] = useState<"select" | "new">("select");
    const [listName, setListName] = useState("");
    const [listId, setListId] = useState<number | null>(null);
    const [idError, setIdError] = useState<string | null>(null);
    const [nameError, setNameError] = useState<string | null>(null);

    const [hashtagLists, { store, loading }] = useHashtagLists();

    const handleSubmit = async () => {
        if (inputType === "select") {
            if (!listId) {
                setIdError(t("hashtags.research.popup.id.required"));
                return;
            }
            await onSubmit(listId);
        }
        else {
            if (!listName) {
                setNameError(t("hashtags.research.popup.name.required"))
                return;
            }
            const id = ((await store({ name: listName }))).id;

            await onSubmit(id);
        }
    }

    return (
        <Popup width="100%" maxWidth="500px" onBackgroundClick={onBackgroundClick}>
            <Column padding="30px 25px 40px" gap="30px">
                <h1>{t("hashtags.research.popup.title")}</h1>
                <Loadable loading={loading}>
                    <Column gap="20px">
                        {inputType === "select" ? (
                            <Input
                                disabled={loading}
                                label={t("hashtags.list")}
                                type="select"
                                value={listId?.toString()}
                                onChange={(value) => setListId(value ? Number(value) : null)}
                                error={idError ?? undefined}
                            >
                                <option value="">{t("input.pleaseSelect")}</option>
                                {hashtagLists.map(list => (
                                    <option key={list.id} value={list.id}>{list.name}</option>
                                ))}
                            </Input>
                        ) : (
                            <Input
                                disabled={loading}
                                label={t("hashtags.list")}
                                value={listName}
                                onChange={(value) => setListName(value)}
                                error={nameError ?? undefined}
                            />
                        )}
                        <button
                            onClick={() => setInputType(inputType === "new" ? "select" : "new")}
                            style={{ alignSelf: "flex-end" }}
                        >
                            <Style
                                fontSize="15px"
                                fontWeight="500"
                                color="var(--links)"
                            >
                                {t(`hashtags.research.popup.switchType.${inputType}`)}
                            </Style>
                        </button>
                    </Column>
                </Loadable>
            </Column>
            <PopupFooter height="80px" vAlign="center" align="end" gap="25px" padding="0 25px">
                <Button variant="secondary" onClick={onBackgroundClick}>{t("cancel")}</Button>
                <Button variant="primary" onClick={handleSubmit}>{t("add")}</Button>
            </PopupFooter>
        </Popup>
    )
}

function NumberOfPostsFormat({ number }: { number: number }) {
    const { t } = useTranslation();
    if (number < 1000) {
        return <>{number}</>;
    }
    if (number < 1000000) {
        return <>{t("hashtags.research.results.totalPosts.thousands", { number: (number / 1000).toFixed(0) })}</>;
    }
    return <>{t("hashtags.research.results.totalPosts.millions", { number: (number / 1000000).toFixed(1) })}</>;

}

interface SearchForm {
    search_term: string;
}

export default function Research() {
    const { t } = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();
    const { user } = useUser();
    assertNotNull(user);
    const isMobile = useBreakpoint(1550);

    const [showPopup, setShowPopup] = useState(false);

    const [hashtagSearches, { loading: hashtagSearchesLoading, store, destroy }] = useHashtagSearches();

    const form = useForm<SearchForm>();

    const [savedHashtags, setSavedHashtags] = useState<HashtagInterface[]>([]);

    const savedHashtagIds = useMemo(() => savedHashtags.map(hashtag => hashtag.id), [savedHashtags]);

    const handleAddToSaved = (hashtag: HashtagInterface) => {
        setSavedHashtags([...savedHashtags, hashtag]);
    }

    const removeFromSaved = useCallback((id: number) => {
        setSavedHashtags((savedHashtags) => savedHashtags.filter(hashtag => hashtag.id !== id));
    }, [setSavedHashtags]);

    const selectedSearchId = searchParams.has("search") ? Number(searchParams.get("search")) : null;
    const setSelectedSearchId = useCallback((id: number | null) => {
        if (id === null) {
            setSearchParams({});
        }
        else {
            setSearchParams({ search: id.toString() });
        }
    }, [searchParams, setSearchParams]);

    const selectedSearch = useMemo(
        () => hashtagSearches.find(search => search.id === selectedSearchId),
        [selectedSearchId, hashtagSearches]
    );

    useEffect(() => {
        if (selectedSearchId === null && hashtagSearches.length > 0) {
            setSelectedSearchId(hashtagSearches[0]?.id ?? null);
        }

        if (selectedSearchId !== null && hashtagSearches.find(search => search.id === selectedSearchId) === undefined) {
            setSelectedSearchId(null);
        }
    }, [selectedSearchId, hashtagSearches, setSelectedSearchId])

    const [searchLoading, setSearchLoading] = useState(false);

    const handleSearch: SubmitHandler<SearchForm> = useCallback(async ({ search_term }) => {
        const searchTerms = search_term.split(',');
        if (searchTerms.length + hashtagSearches.length > import.meta.env.VITE_HASHTAG_SEARCH_LIMIT) {
            form.setError("search_term", {
                message: t("hashtags.research.keywords.tooManySearches", { limit: import.meta.env.VITE_HASHTAG_SEARCH_LIMIT }),
            });
            return;
        }
        setSearchLoading(true);
        const firstSearch = searchTerms[0];
        const additionalSearches = searchTerms.slice(1);
        const id = (await store({ search_term: firstSearch })).id;
        await Promise.all(additionalSearches.map(async searchTerm => await store({ search_term: searchTerm })))
        form.setValue("search_term", "");
        setSelectedSearchId(id ?? null);
        setSearchLoading(false);
    }, [form, store, setSelectedSearchId, setSearchLoading]);

    const addToSearch = useCallback((searchTerm: string) => {
        setSearchParams({
            searchTerm,
            ...(selectedSearchId === null ? {} : { search: selectedSearchId.toString() })
        });
    }, [setSearchParams, selectedSearchId])

    useEffect(() => { // In useEffect so that it can be triggered at navigation to the page
        if (searchParams.has("searchTerm") && !hashtagSearchesLoading) {
            const searchTerm = searchParams.get("searchTerm")!;
            const hashtagSearch = hashtagSearches.find(search => search.search_term === searchTerm);
            if (hashtagSearch !== undefined) {
                setSelectedSearchId(hashtagSearch.id);
                return;
            }
            setSearchParams({
                ...(selectedSearchId === null ? {} : { search: selectedSearchId.toString() })
            });
            const pastSearchTerm = form.getValues("search_term");
            if (!pastSearchTerm?.split(',').map(term => term.trim()).includes(searchTerm)) {
                form.setValue("search_term", `${pastSearchTerm ? `${pastSearchTerm}, ` : ""}${searchTerm}`);
            }
        }
    }, [searchParams, selectedSearchId, form, handleSearch, setSearchParams, setSelectedSearchId, hashtagSearches, hashtagSearchesLoading]);

    const handleAddToList = async (id: number) => {
        await axios.post(route("hashtag-lists.hashtags.mass-insert", {
            hashtag_list: id,
        }), {
            hashtags: savedHashtagIds,
        });
        setSavedHashtags([]);
        setShowPopup(false);
    }

    const searchWrapper = useMemo(() => (
        <Column gap="30px">
            {!isMobile && <Row className="research-info" gap="15px">
                <InfoIcon />
                <span>{t("hashtags.research.info")}</span>
            </Row>}
            <Form form={form} onSubmit={handleSearch}>
                <Row className={classNames("search-wrapper", { mobile: isMobile })} gap="20px" vAlign="bottom">
                    <Input
                        disabled={hashtagSearchesLoading || searchLoading}
                        flex={1}
                        label={t("hashtags.research.keywords")}
                        placeholder={t("hashtags.research.keywords.placeholder")}
                        name="search_term"
                        options={{ required: t("hashtags.research.keywords.required") }}
                    />
                    <Button
                        variant="primary"
                        submit
                        loading={searchLoading}
                    >
                        {t("search")}
                    </Button>
                </Row>
            </Form>
        </Column>
    ), [isMobile, form, handleSearch, hashtagSearchesLoading, searchLoading, t]);

    const saved = useMemo(() => (
        <Column className={classNames("saved", { mobile: isMobile })} gap="30px">
            <h3>{t("hashtags.research.saved")}</h3>
            <Column padding="20px 20px 16px 20px" gap="16px" className="saved-hashtags-wrapper">
                <div>
                    {savedHashtags.map((hashtag) => (
                        <Hashtag
                            key={hashtag.id}
                            hashtag={hashtag}
                            type="in-search"
                            onDelete={() => removeFromSaved(hashtag.id)}
                            onClick={() => addToSearch(hashtag.name)}
                            showDelete
                        />
                    ))}
                </div>
                <Button
                    variant="primary"
                    disabled={savedHashtags.length === 0}
                    onClick={() => setShowPopup(true)}
                >
                    {t("hashtags.research.saved.addToList")}
                </Button>
            </Column>
        </Column>
    ), [savedHashtags, removeFromSaved, addToSearch, setShowPopup, t, isMobile])

    return (
        <div className={classNames("hashtag-research", { mobile: isMobile })}>
            {isMobile && searchWrapper}
            <div className={classNames("results-wrapper", { mobile: isMobile })}>
                <Column maxWidth={isMobile ? undefined : "800px"} className="results" flex={1} gap="30px">
                    <Row vAlign="center" align="space">
                        <h3>{t("hashtags.research.results")}</h3>
                        {!isMobile && <span className="note">{t("hashtags.research.results.note")}</span>}
                    </Row>
                    <Row gap="6px" className="tabs">
                        {hashtagSearchesLoading ? (
                            <>
                                <div className={classNames("search-result-tab", "loading")} />
                                <div className={classNames("search-result-tab", "loading")} />
                            </>
                        ) : (
                            hashtagSearches.map((search) => (
                                <SearchResultTab key={search.id} search={search} onDelete={() => { destroy(search.id, 'immediate') }} />
                            ))
                        )}
                    </Row>
                    <div className="search-table">
                        <table >
                            <thead>
                                <tr>
                                    <th />
                                    <th />
                                    <th>{t("hashtags.research.results.difficulty")}</th>
                                    <th>{t("hashtags.research.results.totalPosts")}</th>
                                    <th>{t("hashtags.research.results.postsPerHour")}</th>
                                    <th>{t("hashtags.research.results.averageLikes")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {(!hashtagSearchesLoading && selectedSearch) ? (
                                    selectedSearch?.hashtags.map((hashtag) => (
                                        <tr key={hashtag.id}>
                                            <td>
                                                <CheckboxSmall
                                                    checked={savedHashtagIds.includes(hashtag.id)}
                                                    onChange={(checked) => checked ? handleAddToSaved(hashtag) : removeFromSaved(hashtag.id)}
                                                />
                                            </td>
                                            <td className="hashtag-col">
                                                <button onClick={() => addToSearch(hashtag.name)}>
                                                    {`#${hashtag.name}`}
                                                </button>
                                            </td>
                                            <td><HashtagDifficultyBar difficulty={hashtag.difficulty} /></td>
                                            <td><NumberOfPostsFormat number={hashtag.total_posts ?? 0} /></td>
                                            <td>{hashtag.posts_per_hour}</td>
                                            <td>{hashtag.average_likes}</td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan={6} className="placeholder">
                                            {hashtagSearchesLoading ? <Loader /> : t("hashtags.research.results.noResults")}
                                        </td>
                                    </tr>

                                )}
                            </tbody>
                        </table>
                    </div>
                </Column>
                {isMobile && saved}
            </div>
            {!isMobile && (
                <Column flex={1} gap="50px">
                    {searchWrapper}
                    {saved}
                </Column>
            )}
            {showPopup && (
                <AddToListPopup
                    onBackgroundClick={() => setShowPopup(false)}
                    onSubmit={handleAddToList}
                />
            )}
        </div>
    )
}