import { createRequiredContext } from "@enymo/react-better-context";
import * as Sentry from "@sentry/react";
import React, { useCallback, useState } from "react";

export interface SentryUser {
    email: string,
    name: string
}

export interface FallbackProps {
    eventId: string,
    resetError: () => void
}

const [Provider, useSentryUser] = createRequiredContext<{
    user: SentryUser | null,
    setUser: (user: SentryUser | null) => void
}>("ErrorBoundary must be present in component tree");

export { useSentryUser };

export default function ErrorBoundary({fallback, children}: {
    fallback: Sentry.FallbackRender,
    children: React.ReactNode
}) {
    const [user, setUser] = useState<SentryUser | null>(null);

    const handleSetUser = (user: SentryUser | null) => {
        setUser(user);
        if (user !== null) {
            Sentry.setUser({
                email: user.email,
                username: `${user.name}`
            });
        }
        else {
            Sentry.setUser(null);
        }
    }

    const onReset = useCallback(() => {
        window.history.replaceState(null, "", "/");
    }, [])

    return (
        <Provider value={{user, setUser: handleSetUser}}>
            <Sentry.ErrorBoundary onReset={onReset} fallback={fallback}>
                {children}
            </Sentry.ErrorBoundary>
        </Provider>
    )
}