import "@/css/components/drawer.scss";
import { requireNotNull } from "@enymo/ts-nullsafe";
import React, { useMemo } from "react";

export default function Drawer<T extends string>({items, selected, onChangeSelected}: {
    items: {
        name: T,
        title: React.ReactNode,
        children: React.ReactNode
    }[],
    selected: T,
    onChangeSelected(selected: T): void
}) {
    const { children } = useMemo(() => requireNotNull(items.find(({name}) => name === selected)), [items, selected]);

    return (
        <div className="drawer">
            <div className="drawer-menu">
                {items.map(({name, title}) => (
                    <button
                        key={name}
                        type="button"
                        className={name === selected ? "selected" : undefined}
                        onClick={() => onChangeSelected(name)}
                    >
                        {title}
                    </button>
                ))}
            </div>
            {children}
        </div>
    )
}