import "@/css/components/wallet.scss";
import Mastercard from "@/svg/Mastercard_2019_logo.svg?react";
import Visa from "@/svg/Visa_Inc._logo.svg?react";
import Amex from "@/svg/amex-svgrepo-com.svg?react";
import Sepa from "@/svg/building-columns-solid.svg?react";
import Paypal from "@/svg/paypal.svg?react";
import TrashIcon from "@/svg/trash-regular.svg?react";
import WarningIcon from "@/svg/triangle-exclamation-duotone.svg?react";
import { useRadioGroup } from "@enymo/glide";
import useHybridInput from "@enymo/react-hybrid-input-hook";
import { Column, Row } from "@enymo/react-layout";
import { assertNotNull, requireNotNull } from "@enymo/ts-nullsafe";
import { Elements, ElementsConsumer, PaymentElement } from "@stripe/react-stripe-js";
import { Stripe, StripeElements } from "@stripe/stripe-js";
import axios from "axios";
import classNames from "classnames";
import React, { useMemo, useState } from "react";
import { RegisterOptions } from "react-hook-form";
import { useTranslation } from "react-i18next";
import route from "ziggy-js";
import { useUser } from "../Providers/UserProvider";
import { stripePromise } from "../common";
import { Button, RadioGroup } from "../glidespec";
import { PaymentMethod, usePaymentMethods } from "../resources";
import Popup, { PopupFooter } from "./Popup";
import Tooltip, { TooltipParent } from "./Tooltip";

export default function Wallet({
    name,
    value: externalValue,
    onChange: externalOnChange,
    options,
}: {
    name?: string;
    value?: string;
    onChange?: (value: string) => void;
    options?: RegisterOptions;
}) {
    const { t } = useTranslation();
    const { user } = useUser();
    assertNotNull(user);

    const { value, onChange, error } = useHybridInput({ name, externalOnChange, externalValue, options });

    const [paymentMethods, { loading, destroy }] = usePaymentMethods({
        onCreated: () => {
            if (activePopup?.popup === "add") {
                setActivePopup(null);
            }
            setSetupLoading(false);
        }
    });

    const [activePopup, setActivePopup] = useState<null | {
        popup: "add" | "remove";
        id?: string;
    }>(null);
    
    const [setupLoading, setSetupLoading] = useState(false);

    const handleAddPaymentMethod = async (elements: StripeElements | null, stripe: Stripe | null) => {
        if (activePopup?.popup === "add") {
            if (!elements || !stripe) {
                return;
            }
            setSetupLoading(true);
            if ((await elements?.submit())?.error !== undefined) {
                setSetupLoading(false);
                return;
            }

            const clientSecret = (await axios.post(route("payment-methods.setup-intent"))).data;
            
            await stripe?.confirmSetup({
                clientSecret,
                elements: elements ?? undefined,
                confirmParams: {
                    return_url: `${window.location.href}${window.location.href.includes('?') ? '&' : '?'}redirect_type=payment_method`,
                    payment_method_data: {
                        billing_details: {
                            name: t("user.fullName", { firstName: user.first_name, lastName: user.last_name }),
                            email: user.email,
                        }
                    },
                },
                
                redirect: "if_required",
            });
            // The backend sends a socket event when the payment method is added
        }
    }

    const handleRemovePaymentMethod = async () => {
        if (activePopup?.id) {
            await destroy(activePopup.id);
            setActivePopup(null);
        }
    }

    return (
        <>
            <Column gap="30px" className="wallet">
                <Row align="space">
                    <span className="title">
                        {t("paymentMethod")}
                    </span>
                    {!loading && (
                        <button className="add-payment-method" type="button" onClick={() => setActivePopup({ popup: "add" })}>
                            {`+ ${t("paymentMethod.add")}`}
                        </button>
                    )}
                </Row>
                <Column gap="17px">
                    {loading ? (
                        <>
                            <LoadingPaymentMethod />
                            <LoadingPaymentMethod />
                        </>
                    ) : (
                        <Column gap="10px">
                            <RadioGroup value={value} onChange={onChange}>
                                {paymentMethods?.length > 0 ? (
                                    paymentMethods.map((paymentMethod) => (
                                        <PaymentMethodComponent
                                            key={paymentMethod.id}
                                            paymentMethod={paymentMethod}
                                            onRemovePaymentMethod={() => setActivePopup({ popup: "remove", id: paymentMethod.id })}
                                            error={error?.message}
                                        />
                                    ))
                                ) : (
                                    <span className="no-payment-methods">
                                        {t("paymentMethod.noPaymentMethods")}
                                    </span>
                                )}
                            </RadioGroup>

                            {error?.message && <span className="error-message">{error.message}</span>}
                        </Column>
                    )}
                </Column>
            </Column>
            {activePopup?.popup === "remove" && (
                <Popup
                    onBackgroundClick={() => setActivePopup(null)}
                    maxWidth="500px"
                    width="100%"
                >
                    <Column padding="30px 25px 40px" gap="30px">
                        <Row gap="15px" vAlign="center">
                            <WarningIcon width="40px" height="35px" fill="var(--warning)" />
                            <h1>{t("paymentMethod.removePopup.title")}</h1>
                        </Row>
                        <p>{t("paymentMethod.removePopup.text")}</p>
                    </Column>
                    <PopupFooter>
                        <Button variant="secondary" onClick={() => setActivePopup(null)}>
                            {t("cancel")}
                        </Button>
                        <Button variant="danger" onClick={handleRemovePaymentMethod}>
                            {t("remove")}
                        </Button>
                    </PopupFooter>
                </Popup>
            )}
            {activePopup?.popup === "add" && (
                <Elements stripe={stripePromise} options={{
                    mode: "setup",
                    currency: "eur",
                    payment_method_types: ["card", "paypal"],
                    paymentMethodCreation: "manual",
                }}>
                    <ElementsConsumer>
                        {({ elements, stripe }) => (
                            <Popup
                                onBackgroundClick={() => setActivePopup(null)}
                                maxWidth="500px"
                                width="100%"
                            >
                                <Column padding="30px 25px 40px" gap="30px">
                                    <h1>{t("paymentMethod.addPopup.title")}</h1>
                                    <PaymentElement options={{
                                        layout: "accordion",
                                        fields: {
                                            billingDetails: {
                                                name: "never",
                                                email: "never",
                                            },
                                        }
                                    }} />
                                </Column>
                                <PopupFooter>
                                    <Button variant="secondary" onClick={() => setActivePopup(null)}>
                                        {t("cancel")}
                                    </Button>
                                    <Button 
                                        variant="primary" 
                                        loading={setupLoading} 
                                        onClick={() => handleAddPaymentMethod(elements, stripe)}
                                    >
                                        {t("add")}
                                    </Button>
                                </PopupFooter>
                            </Popup>
                        )}
                    </ElementsConsumer>
                </Elements>
            )}
        </>
    )

}

function LoadingPaymentMethod() {
    return (
        <div className="payment-method-radio">
            <div className={classNames("indicator")}>
                <div />
            </div>
            <Row className={classNames("payment-method-tile", "loading")} align="space">
                <span />
            </Row>
        </div>
    )
}

function PaymentMethodComponent({
    paymentMethod,
    onRemovePaymentMethod,
    error,
}: {
    paymentMethod: PaymentMethod;
    onRemovePaymentMethod: () => void;
    error?: string;
}) {
    const { t } = useTranslation();

    const { value, toggle } = requireNotNull(useRadioGroup());

    const selected = useMemo(() => value === paymentMethod.id, [value, paymentMethod.id]);

    return (
        <label className="payment-method-radio">
            <input
                type="radio"
                checked={selected}
                onChange={() => toggle(paymentMethod.id)}
            />
            <div className={classNames("indicator", { selected, error })}>
                <div />
            </div>
            <Row className={classNames("payment-method-tile", { selected, error })} align="space" vAlign="center">
                <Row gap={paymentMethod.type === "amex" ? "5px" : "10px"} vAlign={paymentMethod.type === "sepa" ? "center" : undefined}>
                    {(() => {
                        switch (paymentMethod.type) {
                            case "visa":
                                return <Visa width="30px" height="13px" style={{ paddingTop: "3px" }} />;
                            case "mastercard":
                                return <Mastercard width="30px" height="19px" />;
                            case "amex":
                                return <Amex width="40px" height="30px" />;
                            case "sepa":
                                return <Sepa width="20px" height="20px" fill="var(--neutral-500)" />
                            case "paypal":
                                return <Paypal width="20px" height="24px" fill="var(--primary-500)" />
                        }
                    })()}
                    <Column gap="4px" align="center">
                        <span className="payment-method-type">
                            {paymentMethod.last4 !== undefined ? t("paymentMethod.withLastFour", {
                                lastFour: paymentMethod.last4,
                                type: t(`paymentMethod.${paymentMethod.type}`)
                            }) : t(`paymentMethod.${paymentMethod.type}`)}
                        </span>
                        {paymentMethod.type !== "sepa" && (
                            <span className="payment-method-addition">
                                {paymentMethod.type === "paypal" ? paymentMethod.email! : t("paymentMethod.expires", {
                                    month: paymentMethod.exp_month?.toString().padStart(2, "0"),
                                    year: paymentMethod.exp_year
                                })}
                            </span>
                        )}
                    </Column>
                </Row>
                <TooltipParent disabled={!paymentMethod.in_use}>
                    <button className="remove-payment-method" type="button" onClick={onRemovePaymentMethod} disabled={paymentMethod.in_use}>
                        <Row gap="6px" vAlign="center">
                            <TrashIcon width="12px" />
                            <span>{t("remove")}</span>
                        </Row>
                        <Tooltip>{t("paymentMethod.activeSubscriptionTooltip")}</Tooltip>
                    </button>
                </TooltipParent>
            </Row>
        </label>
    )
}