import "@/css/components/hashtag-difficulty-tooltip.scss";
import { createRequiredContext } from "@enymo/react-better-context";
import { Column, Row } from "@enymo/react-layout";
import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import { HashtagDifficulty, hashtagDifficulties } from "../common";
import HashtagDifficultyBar from "./HashtagDifficultyBar";
import Tooltip from "./Tooltip";

function Difficulty({ difficulty }: { 
    difficulty: HashtagDifficulty,
}) {
    const { t } = useTranslation();

    const highlight = useHighlighterDifficulty() === difficulty;

    return (
        <Row align="space" vAlign="center" gap="3px" className={classNames("difficulty-row", { highlight })}>
            <span>{t(`hashtagDifficulty.${difficulty}`)}</span>
            <HashtagDifficultyBar difficulty={difficulty} type="in-tooltip" highlight={highlight} />
        </Row>
    )
}

const [HighlightedDifficultyProvider, useHighlighterDifficulty] = createRequiredContext<HashtagDifficulty>("HashtagDifficultyTooltip must be in the tree")

export default function HashtagDifficultyTooltip({ difficulty }: { 
    difficulty: HashtagDifficulty 
}) {
    const { t } = useTranslation();
    
    return (
        <Tooltip padding="8px">
            <Column gap="8px" className="hashtag-difficulty-tooltip">
                <h5>{t("hashtagDifficulty")}</h5>
                <HighlightedDifficultyProvider value={difficulty}>
                    <Column gap="2px">
                        {hashtagDifficulties.map((d) => (
                            <Difficulty key={d} difficulty={d} />
                        ))}
                    </Column>
                </HighlightedDifficultyProvider>
            </Column>
        </Tooltip>
    );
}
