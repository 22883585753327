import "@/css/components/graph.scss";
import classNames from "classnames";
import React from "react";
import { Line } from "react-chartjs-2";

export default function Graph({className, title, values, labels}: {
    className?: string,
    title: React.ReactNode
    values: number[],
    labels: string[]
}) {
    return (
        <div className={classNames("graph", className)}>
            <h1>{title}</h1>
            <Line style={{width: "100%"}} options={{
                responsive: true,
                maintainAspectRatio: false,
            }} data={{
                labels,
                datasets: [{
                    data: values,
                    borderColor: '#217EED',
                    backgroundColor: "rgb(33, 126, 237, 0.1)",
                    fill: true,
                    tension: 0.3
                }]
            }}  />
        </div>
    )
}