import "@/css/components/toaster.scss";
import CircleCheckIcon from "@/svg/circle-check-solid.svg?react";
import TriangleExclamationIcon from "@/svg/triangle-exclamation-solid.svg?react";
import XMarkIcon from "@/svg/xmark-regular.svg?react";
import { createRequiredContext } from "@enymo/react-better-context";
import { assertNotNull, isNotNull } from "@enymo/ts-nullsafe";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { CSSTransition, Transition, TransitionGroup } from "react-transition-group";
import { TransitionProps } from "react-transition-group/Transition";

type ToastType = "success" | "warning" | "error";
type ToastFunction = (props: {
    type: ToastType,
    title?: React.ReactNode,
    text?: React.ReactNode,
    duration?: number | null
}) => void;

const [ToasterContextProvider, useToaster] = createRequiredContext<ToastFunction>("ToasterProvider must be present in component tree");
const [ToastControlProvider, useToastControl] = createRequiredContext<{
    close(): void
}>("ToastControl can only be used within Toast");

function Toast({
    id,
    title,
    text,
    type,
    duration,
    onDone,
    ...props
}: {
    id: number,
    title?: React.ReactNode,
    text?: React.ReactNode,
    type: ToastType,
    duration: number | null,
    onDone(id: number): void,
} & Omit<TransitionProps, "timeout" | "nodeRef">) {
    const ref = useRef<HTMLDivElement>(null);
    const innerTransitionRef = useRef<HTMLDivElement>(null);
    const [height, setHeight] = useState<string>();
    const [show, setShow] = useState(true);

    const handleDone = useCallback(() => {
        onDone(id)
    }, [onDone, id]);

    useEffect(() => {
        assertNotNull(ref.current, "Toast ref must be assigned on first render");
        setHeight(getComputedStyle(ref.current).height);
    }, [ref, setHeight]);

    useEffect(() => {
        if (isNotNull(duration)) {
            const timeout = setTimeout(() => setShow(false), duration);
            return () => clearTimeout(timeout);
        }
    }, [setShow, duration]);

    return (
        <Transition timeout={500} nodeRef={ref} {...props}>
            {state => (
                <div
                    ref={ref}
                    className="toast-wrap"
                    style={state === "exiting" ? {
                        height: 0,
                        paddingTop: 0
                    } : {
                        height,
                        paddingTop: "20px"
                    }}
                >
                    <CSSTransition
                        in={show}
                        timeout={800}
                        onExited={handleDone}
                        nodeRef={innerTransitionRef}
                        appear
                    >
                        <div ref={innerTransitionRef} className={`toast ${type}`}>
                            <div className="border" />
                            {type === "success" ? <CircleCheckIcon className="icon" /> : <TriangleExclamationIcon className="icon" />}
                            <div className="body">
                                <ToastControlProvider value={{close: () => setShow(false)}}>
                                    {title && <span className="title">{title}</span>}
                                    {text && <span className="text">{text}</span>}
                                </ToastControlProvider>
                            </div>
                            <button className="close" onClick={() => setShow(false)}>
                                <XMarkIcon />
                            </button>
                        </div>
                    </CSSTransition>
                </div>
            )}
        </Transition>
    )
}

export { useToastControl, useToaster };
export default function ToasterProvider({children}: {
    children: React.ReactNode
}) {
    const nextId = useRef(0);    
    const [toasts, setToasts] = useState<{
        id: number,
        title?: React.ReactNode,
        text?: React.ReactNode,
        type: ToastType,
        duration: number | null,
    }[]>([]);

    const toast = useCallback<ToastFunction>(({duration = 5000, ...props}) => {
        setToasts(toasts => [...toasts, {
            id: nextId.current++,
            duration,
            ...props
        }]);
    }, [nextId, setToasts]);

    const handleToastExited = useCallback((id: number) => {
        setToasts(toasts => toasts.filter(toast => toast.id !== id));
    }, [setToasts])

    return (
        <>
            <ToasterContextProvider value={toast}>
                {children}
            </ToasterContextProvider>
            <TransitionGroup className="toaster">
                {toasts.map(props => (
                    <Toast
                        key={props.id}
                        onDone={handleToastExited}
                        {...props}
                    />
                ))}
            </TransitionGroup>
        </>
    )
}