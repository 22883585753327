import { SwitcherInput } from "@/js/Components/SwitcherInput";
import useBreakpoint from "@/js/Hooks/BreakpointHook";
import { useToaster } from "@/js/Providers/ToasterProvider";
import { toISOStringWithTimezone } from "@/js/common";
import { Button, Input } from "@/js/glidespec";
import { useCampaigns } from "@/js/resources";
import { Form, SubmitHandler, setFormValues } from "@enymo/react-form-component";
import { Column, Row } from "@enymo/react-layout";
import { isNotNull, requireNotNull } from "@enymo/ts-nullsafe";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";

interface Submit {
    rotate: boolean,
    name: string,
    start_date: Date,
    end_date: Date,
}

export default function General() {
    const {t} = useTranslation();
    const {campaignId} = useParams();
    const form = useForm<Submit>();
    const [campaign, {update}] = useCampaigns({id: Number(requireNotNull(campaignId))});
    const toast = useToaster();
    const mobile = useBreakpoint(768);

    const handleSubmit: SubmitHandler<Submit> = async ({start_date, end_date, ...rest}) => {
        await update({
            start_date: isNaN(start_date as any) ? null : start_date,
            end_date: isNaN(end_date as any) ? null : end_date,
            ...rest
        });
        toast({
            type: "success",
            title: t("campaigns.settings.saved"),
            text: t("campaigns.settings.saved.text")
        })
    }

    useEffect(() => {
        if (isNotNull(campaign)) {
            setFormValues(form, {
                rotate: campaign.rotate,
                name: campaign.name,
                start_date: toISOStringWithTimezone(campaign.start_date, true) as any,
                end_date: toISOStringWithTimezone(campaign.end_date, true) as any,
            });
        }
    }, [form, campaign]);

    return (
        <Column padding={mobile ? "35px 16px" : "35px 50px"}>
            <Form form={form} onSubmit={handleSubmit}>
                <Column gap="40px" maxWidth="450px" width="100%">
                    <Column gap="20px">
                        <SwitcherInput
                            name="rotate"
                            label={t("campaigns.settings.general.rotate")}
                            activeLabel={t("activeSwitch.active")}
                            inactiveLabel={t("activeSwitch.inactive")}
                            description={t("campaigns.settings.general.rotate.description")}
                        />
                        <Input name="name" label={t("campaigns.settings.general.name")} options={{
                            required: t("campaigns.settings.general.name.required")
                        }} />
                        <Input name="start_date" type="date" label={t("campaigns.settings.general.startDate")} options={{
                            valueAsDate: true
                        }} />
                        <Input name="end_date" type="date" label={t("campaigns.settings.general.endDate")} options={{
                            valueAsDate: true
                        }} />
                    </Column>
                    <Row align="end">
                        <Button variant="primary" submit>{t("save")}</Button>
                    </Row>
                </Column>
            </Form>
        </Column>
    )
}