import "@/css/components/splash.scss";
import Logo from "@/svg/logo.svg?react";
import { Column } from "@enymo/react-layout";
import React from "react";

export default function Splash({title, children}: {
    title: string,
    children?: React.ReactNode
}) {
    return (
        <div className="splash">
            <Logo className="logo" />
            <Column gap="15px" hAlign="center">
                <h1>{title}</h1>
                {children && <p>{children}</p>}
            </Column>
        </div>
    )
}