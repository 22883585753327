import React from 'react'

export function Style({
    fontSize,
    fontWeight,
    color,
    children,
    className,
    style,
}: {
    fontSize?: string,
    fontWeight?: string,
    color?: string,
    children: React.ReactNode,
    className?: string,
    style?: React.CSSProperties,
}) {
    return (
        <span className={className} style={{ ...style, fontSize, fontWeight, color }}>{children}</span>
    )
}