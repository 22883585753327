import React from 'react';
import Loader from './Loader';
import "@/css/components/loadable.scss";
import classNames from 'classnames';

export default function Loadable({ loading, fade = false, children }: { 
    loading: boolean,
    fade?: boolean,
    children: React.ReactNode
}) {
    return (
        <div className="loadable">
            {loading ? (
                <>
                    <Loader className='loadable-loader' />
                    <div className={classNames('loadable-content')}>
                        {children}
                        {fade && <div className='loadable-fade' />}
                    </div>
                </>
            ) : (
                children
            )}
        </div>
    )
}