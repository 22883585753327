import Loadable from "@/js/Components/Loadable";
import useBreakpoint from "@/js/Hooks/BreakpointHook";
import { useToaster } from "@/js/Providers/ToasterProvider";
import { Button, Input } from "@/js/glidespec";
import { useCampaigns } from "@/js/resources";
import { Form, SubmitHandler, setFormValues } from "@enymo/react-form-component";
import { Column, Row } from "@enymo/react-layout";
import { assertNotNull, isNotNull } from "@enymo/ts-nullsafe";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";

interface Submit {
    thumbnail_time: number,
    notes: string | null,
}

export default function Misc() {
    const { t } = useTranslation();
    const { campaignId } = useParams();
    assertNotNull(campaignId, "A campaign id must be provided on this page");
    const mobile = useBreakpoint(768);
    const toast = useToaster();
    const [campaign, { update, loading }] = useCampaigns({
        id: Number(campaignId)
    });

    const form = useForm<Submit>();

    useEffect(() => {
        if (isNotNull(campaign)) {
            setFormValues(form, {
                thumbnail_time: campaign.thumbnail_time,
                notes: campaign.notes,
            });
        }
    }, [campaign, form]);

    const handleSubmit: SubmitHandler<Submit> = async (data) => {
        await update(data);
        toast({
            type: "success",
            title: t("campaigns.settings.saved"),
            text: t("campaigns.settings.saved.text")
        })
    }

    return (
        <Column gap="30px" maxWidth="550px" width="100%" padding={mobile ? "35px 16px 0 16px" : "35px 50px 0 50px"}>
            <Loadable loading={loading}>
                <Form form={form} onSubmit={handleSubmit}>
                    <Column gap="40px">
                        <Input 
                            name="thumbnail_time" 
                            label={t("campaigns.settings.misc.thumbnailTime")} 
                            type="number"
                            options={{
                                min: 0,
                                required: t("campaigns.settings.misc.thumbnailTime.required")
                            }}
                        />
                        <Input name="notes" type="textarea" rows={4} label={t("campaigns.settings.general.notes")} />
                        <Row align="end">
                            <Button variant="primary" submit>{t("save")}</Button>
                        </Row>
                    </Column>
                </Form>
            </Loadable>
        </Column>
    )
}