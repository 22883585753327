import { Style } from "@/js/Components/Style";
import Table from "@/js/Components/Table";
import { useUser } from "@/js/Providers/UserProvider";
import DownloadIcon from "@/svg/download-regular.svg?react";
import { Column } from "@enymo/react-layout";
import { assertNotNull } from "@enymo/ts-nullsafe";
import axios from "axios";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import route from "ziggy-js";

type InvoiceStatus = "open" | "draft" | "paid" | "uncollectible" | "void";

interface Invoice {
    id: string,
    invoice_number: string,
    status: InvoiceStatus,
    date: Date,
    amount: number,
    currency: string,
    url: string | null,
}

interface ApiInvoice extends Omit<Invoice, "date"> {
    date: string,
}

function InvoiceStatusTag({ status }: {
    status: InvoiceStatus,
}) {
    const { t } = useTranslation();

    const color = useMemo(() => {
        switch (status) {
            case "open": return "var(--error)";
            case "paid": return "var(--success)";
            default: return "var(--text-600)";
        }
    }, [status]);

    return (
        <span style={{ color }}>
            {t(`settings.invoices.status.${status}`)}
        </span>
    )
}

export default function Invoices() {
    const { t } = useTranslation();
    const { user } = useUser();
    assertNotNull(user);

    const [invoicesLoading, setInvoicesLoading] = useState(true);
    const [invoices, setInvoices] = useState<Invoice[] | null>(null);

    useEffect(() => {
        setInvoicesLoading(true);
        axios.get<ApiInvoice[]>(route("invoices.index")).then((response) => {
            setInvoices(response.data.map(({date, ...rest}) => ({
                ...rest,
                date: new Date(date),
            })));
            setInvoicesLoading(false);
        })
    }, [setInvoices, setInvoicesLoading]);

    return (
        <Column>
            <Table
                head={[
                    {
                        label: t("settings.invoices.invoiceNumber"),
                        disableWrap: true,
                    },
                    {
                        label: t("settings.invoices.date"),
                        disableWrap: true
                    },
                    {
                        label: t("settings.invoices.amount"),
                        disableWrap: true,
                    },
                    {
                        label: t("settings.invoices.status"),
                        fillWidth: true,
                    },
                    {
                        label: t("settings.invoices.action"),
                    },
                ]}
                rows={invoices?.map(({id, invoice_number, status, amount, date, currency, url}) => (
                    {
                        id: id,
                        data: [
                            <Style fontWeight="500" color="var(--text-700)">{invoice_number ?? t(`settings.invoices.status.${status}`)}</Style>,
                            <Style fontWeight="400" color="var(--text-600)">{t("dateFormat", {
                                day: date.getDate(),
                                month: date.getMonth() + 1, // getMonth is 0 indexed
                                year: date.getFullYear(),
                            })}</Style>,
                            <Style>{`${currency.toUpperCase()} ${amount.toFixed(2).replaceAll('.', ',')}`}</Style>,
                            <InvoiceStatusTag status={status} />,
                            url && <Link to={url}><DownloadIcon width="18px" color="var(--text-800)" /></Link>,
                        ]
                    }
                )) ?? []}
                itemsPerPage={10}
            />
        </Column>
    )
}