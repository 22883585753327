import useBreakpoint from "@/js/Hooks/BreakpointHook";
import { useToaster } from "@/js/Providers/ToasterProvider";
import { aiStyles } from "@/js/common";
import { Button, Input } from "@/js/glidespec";
import { useCampaigns } from "@/js/resources";
import { Form, SubmitHandler } from "@enymo/react-form-component";
import { Column, Row } from "@enymo/react-layout";
import { requireNotNull } from "@enymo/ts-nullsafe";
import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";

interface Submit {
    ai_description: string | null,
    ai_style: typeof aiStyles[number]
}

export default function AISettings() {
    const {t} = useTranslation();
    const toast = useToaster();
    const [campaign, {update}] = useCampaigns({id: Number(requireNotNull(useParams().campaignId))});
    const form = useForm<Submit>({
        values: campaign ? {
            ai_description: campaign.ai_description,
            ai_style: campaign.ai_style ?? "funny"
        } : undefined
    });
    const mobile = useBreakpoint(768);

    const handleSubmit: SubmitHandler<Submit> = async data => {
        await update(data);
        toast({
            type: "success",
            title: t("campaigns.settings.saved"),
            text: t("campaigns.settings.saved.text")
        });
    }

    return (
        <Column padding={mobile ? "35px 16px" : "35px 50px"}>
            <Form form={form} onSubmit={handleSubmit}>
                <Column gap="40px" maxWidth="450px" width="100%">
                    <p className="input-label">{t("campaigns.settings.ai.title")}</p>
                    <Column gap="20px">
                        <Input
                            name="ai_description"
                            type="textarea"
                            label={t("campaigns.settings.ai.description")}
                            placeholder={t("campaigns.settings.ai.description.placeholder")}
                            rows={4}
                        />
                        <Input name="ai_style" type="select" label={t("campaigns.settings.ai.style")}>
                            {aiStyles.map((style, index) => (
                                <option key={index} value={style}>{t(`ai.style.${style}`)}</option>
                            ))}
                        </Input>
                    </Column>
                    <Row align="end">
                        <Button variant="primary" submit>{t("save")}</Button>
                    </Row>
                </Column>
            </Form>
        </Column>
    )
}