import classNames from "classnames";
import React, { useState } from "react";

export default function PreviewText({
    className,
    text,
    hashtags,
    maxLength,
    showMoreLabel,
    prefix,
}: {
    className?: string,
    text: string,
    hashtags: string,
    maxLength: number,
    showMoreLabel?: string,
    prefix?: React.ReactNode
}) {
    const [show, setShow] = useState(false);

    return (
        <div className={classNames("preview-text", className)}>
            {prefix && <>{prefix} </>}{text.length + hashtags.length > maxLength && !show ? <>
                {text.substring(0, maxLength)}
                {hashtags && text.length < maxLength && <>
                    <br />
                    <span className="hashtags">{hashtags.substring(0, maxLength - text.length)}</span>
                </>}... {showMoreLabel && (
                    <button
                        type="button"
                        onClick={() => setShow(true)}
                    >{showMoreLabel}</button>
                )}
            </> : <>
                {text}
                {hashtags && <>
                    <br />
                    <span className="hashtags">{hashtags}</span>
                </>}
            </>}
        </div>
    )
}