import "@/css/components/fold-switcher.scss";
import ChevronDownIcon from "@/svg/chevron-down-regular.svg?react";
import ChevronUpIcon from "@/svg/chevron-up-regular.svg?react";
import React from "react";
import { useTranslation } from "react-i18next";

export function FoldSwitcher({
    folded,
    setFolded,
}: {
    folded: boolean,
    setFolded: (folded: boolean) => void,
}) {
    const { t } = useTranslation();

    return (
        <button className="fold-switcher" onClick={() => setFolded(!folded)}>
            {folded ? (
                <>
                    <span>{t("unfold")}</span>
                    <ChevronDownIcon />
                </>
            ) : (
                <>
                    <span>{t("fold")}</span>
                    <ChevronUpIcon />
                </>
            )}
        </button>
    )
}