import countriesDe from "@/lng/de/countries.json";
import mainDe from "@/lng/de/main.json";
import countriesEn from "@/lng/en/countries.json";
import mainEn from "@/lng/en/main.json";
import { requireNotNull } from "@enymo/ts-nullsafe";
import { CategoryScale, Chart, Filler, LineElement, LinearScale, PointElement } from "chart.js";
import i18next from "i18next";
import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector";
import React from "react";
import { createRoot } from "react-dom/client";
import { initReactI18next } from "react-i18next";
import Bootstrapper from "./Bootstrapper";
import "./sentry";

Chart.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Filler
);

i18next
    .use(I18nextBrowserLanguageDetector)
    .use(initReactI18next)
    .init({
        detection: {
            convertDetectedLanguage: lng => lng.split("-")[0]
        },
        debug: import.meta.env.VITE_I18N_DEBUG === "true",
        fallbackLng: "en",
        resources: {
            de: {
                translation: {
                    ...mainDe,
                    ...countriesDe
                }
            },
            en: {
                translation: {
                    ...mainEn,
                    ...countriesEn
                }
            }
        },
        react: {
            useSuspense: false
        },
        interpolation: {
            escapeValue: false
        }
    });

createRoot(requireNotNull(document.getElementById("app"))).render(<Bootstrapper />);