import useBreakpoint from "@/js/Hooks/BreakpointHook";
import { useToaster } from "@/js/Providers/ToasterProvider";
import { getTimeZoneI18nKey } from "@/js/common";
import { Button, Input } from "@/js/glidespec";
import { useCampaigns } from "@/js/resources";
import { Form, SubmitHandler, setFormValues } from "@enymo/react-form-component";
import { Column, Row } from "@enymo/react-layout";
import { isNotNull, requireNotNull } from "@enymo/ts-nullsafe";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";

interface Submit {
    timezone: string
}

export default function Timezone() {
    const {t} = useTranslation();
    const {campaignId} = useParams();
    const form = useForm<Submit>();
    const [campaign, {update}] = useCampaigns({id: Number(requireNotNull(campaignId))});
    const toast = useToaster();
    const mobile = useBreakpoint(768);

    const handleSubmit: SubmitHandler<Submit> = async (data) => {
        await update(data);
        toast({
            type: "success",
            title: t("campaigns.settings.saved"),
            text: t("campaigns.settings.saved.text")
        })
    }

    useEffect(() => {
        if (isNotNull(campaign)) {
            setFormValues(form, {
                timezone: campaign.timezone
            });
        }
    }, [form, campaign]);

    return (
        <Column padding={mobile ? "35px 16px" : "35px 50px"}>
            <Form form={form} onSubmit={handleSubmit}>
                <Column gap="40px" maxWidth="450px" width="100%">
                    <Column gap="20px">
                        <Input name="timezone" label={t("input.timezone")} type="select">
                            {Intl.supportedValuesOf("timeZone").map(timezone => (
                                <option key={timezone} value={timezone}>{t(getTimeZoneI18nKey(timezone))}</option>
                            ))}
                        </Input>
                    </Column>
                    <Row align="end">
                        <Button variant="primary" submit>{t("save")}</Button>
                    </Row>
                </Column>
            </Form>
        </Column>
    )
}