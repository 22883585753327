import "@/css/pages/main/analytics.scss";
import AvatarWithLogo from "@/js/Components/AvatarWithLogo";
import Graph from "@/js/Components/Graph";
import KeyPerformanceIndicator from "@/js/Components/KeyPerformanceIndicator";
import PeriodSelect from "@/js/Components/PeriodSelect";
import Select from "@/js/Components/Select";
import useBreakpoint from "@/js/Hooks/BreakpointHook";
import { useTitle } from "@/js/Layouts/Main";
import { useApp } from "@/js/Providers/AppProvider";
import { channelAvatar, initials, toISOStringWithTimezone } from "@/js/common";
import { useSocialChannels } from "@/js/resources";
import MagnifyingGlassIcon from "@/svg/magnifying-glass-regular.svg?react";
import { Column, Row } from "@enymo/react-layout";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import route from "ziggy-js";

const today = new Date();
today.setHours(0, 0, 0, 0);

const periods = [30, 14, 7, 3] as const;

interface Analytics {
    keyPerformanceIndicators: {
        name: string,
        value: number,
        previous?: number
    }[],
    metrics: {
        name: string,
        values: number[]
    }[]
}

export default function Analytics() {
    const {t} = useTranslation();
    const {axios} = useApp();
    const [channels] = useSocialChannels();
    const [selectedChannelId, setSelectedChannelId] = useState<number>()
    const [startDate, setStartDate] = useState(() => {
        const date = new Date(today);
        date.setTime(date.getTime() - 30 * 24 * 60 * 60 * 1000);
        return date;
    });
    const [endDate, setEndDate] = useState(today);
    const [analytics, setAnalytics] = useState<Analytics>(() => ({
        keyPerformanceIndicators: [],
        metrics: []
    }));
    const xAxisLabels = useMemo(() => {
        const current = new Date(startDate);
        const result = [];
        while (current.getTime() < endDate.getTime()) {
            result.push(`${current.getDate().toString().padStart(2, "0")}.${(current.getMonth() + 1).toString().padStart(2, "0")}.`);
            current.setDate(current.getDate() + 1);
        }
        return result;
    }, [startDate, endDate]);
    const mobile = useBreakpoint(1600);

    useEffect(() => {
        if (selectedChannelId !== undefined) {
            axios.get(route("social-channels.analytics.index", {
                social_channel: selectedChannelId,
                from: toISOStringWithTimezone(startDate, true),
                to: toISOStringWithTimezone(endDate, true)
            }))
                .then(response => setAnalytics(response.data));
        }
    }, [selectedChannelId, setAnalytics, startDate, endDate]);

    useEffect(() => {
        if (selectedChannelId === undefined && channels.length > 0) {
            setSelectedChannelId(channels[0].id);
        }
    }, [selectedChannelId, channels, setSelectedChannelId]);

    useTitle(t("analytics"));

    return (
        <Column padding={mobile ? "25px 16px " : "40px 50px"} gap="50px">
            <Row align="space" gap="10px">
                <Select
                    maxWidth="268px"
                    width="100%"
                    value={selectedChannelId}
                    onChange={setSelectedChannelId}
                    choices={channels.map(({id, name, icon_type, has_avatar}) => ({
                        key: id!,
                        value: id,
                        element: (
                            <Row gap="10px" vAlign="center" flex={1}>
                                <AvatarWithLogo size={28} src={channelAvatar({id, has_avatar})} initials={initials(name)} type={icon_type} />
                                <span>{name}</span>
                            </Row>
                        )
                    }))}
                />
                <PeriodSelect
                    since={startDate}
                    until={endDate}
                    onChangeSince={setStartDate}
                    onChangeUntil={setEndDate}
                    periods={periods}
                    mobile={mobile}
                />
            </Row>
            {analytics.keyPerformanceIndicators.length > 0 && analytics.metrics.length > 0 ? (
                <div className="analytics-grid">
                    {analytics?.keyPerformanceIndicators.map(({name, value, previous}) => (
                        <KeyPerformanceIndicator
                            key={name}
                            title={t(`analytics.kpi.${name}`)}
                            tooltip={t(`analytics.kpi.${name}.tooltip`)}
                            value={value}
                            previous={previous}
                        />
                    ))}
                    {analytics?.metrics.map(({name, values}) => (
                        <Graph key={name} title={t(`analytics.metric.${name}`)} values={values} labels={xAxisLabels} />
                    ))}
                </div>
            ) : (
                <div className="analytics-placeholder">
                    <MagnifyingGlassIcon />
                    <Column gap="15px" maxWidth="360px" width="100%">
                        <h1>{t("analytics.placeholder.title")}</h1>
                        <p>{t("analytics.placeholder.text")}</p>
                    </Column>
                </div>
            )}
        </Column>
    )
}