import Divider from "@/js/Components/Divider";
import SocialButtons from "@/js/Components/SocialButtons";
import Splash from "@/js/Components/Splash";
import useBreakpoint from "@/js/Hooks/BreakpointHook";
import { useUser } from "@/js/Providers/UserProvider";
import { Button, Checkbox, EmailInput, PasswordInput } from "@/js/glidespec";
import { Form, SubmitHandler } from "@enymo/react-form-component";
import { Column, Row } from "@enymo/react-layout";
import React from "react";
import { useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

interface Submit {
    email: string,
    password: string,
    remember: boolean
}

export default function Login() {
    const {t} = useTranslation();
    const form = useForm<Submit>();
    const { login } = useUser();
    const navigate = useNavigate();
    const mobile = useBreakpoint(700);

    const handleSubmit: SubmitHandler<Submit> = async ({email, password, remember}) => {
        await login(email, password, remember);
        navigate("/app");
    }

    return (
        <Column gap={mobile ? "30px" : "50px"} maxWidth="480px" width="100%">
            <Splash title={t("login.title")}>{!mobile && t("login.subtitle")}</Splash>
            <Form form={form} onSubmit={handleSubmit}>
                <Column gap={mobile ? "20px" : "30px"}>
                    <SocialButtons />
                    <Divider>{t("divider.or")}</Divider>
                    <EmailInput className="large" options={{
                        required: t("input.email.required"),
                    }} />
                    <Column gap={mobile ? "15px" : "20px"}>
                        <PasswordInput className="large" name="password" label={t("input.password")} options={{
                            required: t("login.password.required")
                        }} />
                        <Row align="space" vAlign="center" gap="16px">
                            <Checkbox name="remember">{t("login.remember")}</Checkbox>
                            <Link to="/login/forgot-password" className="link forgot-password">{t("login.forgotPassword")}</Link>
                        </Row>
                    </Column>
                    <Button variant="primaryLogin" submit>{t("login.submit")}</Button>
                    <span className="lockscreen-link">
                        <Trans i18nKey="login.toSignUp">
                            Don't have an account yet? <Link className="link" to="/login/sign-up">Sign up here</Link>
                        </Trans>
                    </span>
                </Column>
            </Form>
        </Column>
    )
}