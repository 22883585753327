import "@/css/components/table.scss";
import ArrowLeftIcon from "@/svg/chevron-left-regular.svg?react";
import ArrowRightIcon from "@/svg/chevron-right-regular.svg?react";
import PlaceholderIcon from "@/svg/folder-magnifying-glass-light.svg?react";
import DragHandleIcon from "@/svg/grip-dots-vertical-solid.svg?react";
import SortDownIcon from "@/svg/sort-down-solid.svg?react";
import SortIcon from "@/svg/sort-solid.svg?react";
import SortUpIcon from "@/svg/sort-up-solid.svg?react";
import { DndContext, DragEndEvent, UniqueIdentifier, useDndContext } from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { SortableContext, useSortable, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import compare from "@enymo/comparison";
import { useCheckboxList } from "@enymo/glide";
import { Column, Row } from "@enymo/react-layout";
import useScreenSize from "@enymo/react-screen-size-hook";
import classNames from "classnames";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { assert } from "ts-essentials";
import { variableSizeItemsCollisionDetection } from "../common";
import { CheckboxList } from "../glidespec";
import Loadable from "./Loadable";
import Switcher from "./Switcher";
import Tooltip, { TooltipParent } from "./Tooltip";

interface SortBy {
    index: number | null,
    descending: boolean
}

interface HeadProps extends React.ThHTMLAttributes<HTMLTableCellElement> {
    /** The label of the header. */
    label: React.ReactNode,
    /** whether sorting should be disabled fo this column. */
    disableSort?: boolean,
    /** whether this column should fill all remaining width. 
     *  Setting to true automatically sets the overflow for the cells in this column to ellipsis.
     **/
    fillWidth?: boolean,
    /** The alignment of the content in the cells in the table body. */
    cellAlignment?: "left" | "center" | "right",
    /** The alignment of the header. */
    headerAlignment?: "left" | "center" | "right",
    /** Disables text wrap for cells in the table body. */
    disableWrap?: boolean,
}

export interface TableRowProps<T extends string | number> extends Omit<React.HTMLAttributes<HTMLTableRowElement>, "id"> {
    children: React.ReactNode,
    /** Id used for both the switcher and the drag features. Must be set if these are used. */
    id?: T,
    /** whether the bottom border should be hidden. */
    hideBorder?: boolean,
    /** Whether the row is in mobile view. */
    isMobile?: boolean,
    paddingVertical?: string,
    switcherEnabled: boolean,
    switcherDisabled?: string | boolean
}

interface TableDataProps extends React.TdHTMLAttributes<HTMLTableCellElement> {
    /** whether the content should overflow with ... at the end. Only works for columns where the header fills the remaining width. */
    ellipsis?: boolean,
    /** The alignment of the content inside the cell. */
    alignment?: "left" | "center" | "right",
    /** whether text wrapping should be disbled for the cell. */
    disableWrap?: boolean,
}

interface DividerRowProps {
    children?: React.ReactNode,
    colSpan: number,
    /** If true the label will be rendered beginning on the second column. */
    tableHasGrabHandle?: boolean,
    /** Whether the table is in mobile view. */
    isMobile?: boolean,
}

interface TableProps<T extends string | number> {
    className?: string,
    /**
     * The head of the table
     * */
    head: HeadProps[],
    /**
     * The rows of the table.
     * 
     * id: The id of a row. Used as key by react, swticher and drag.
     * 
     * data: Sets the children for each cell of the row (rendered by index).
     */
    rows: {
        id: T,
        switcherDisabled?: string | boolean,
        data: React.ReactNode[],
    }[],
    /**
     * Static rows that are rendered between the rows.
     */
    dividerRows?: {
        /** The index of the row after the divider */
        index: number,
        /** The label of the divider */
        label: React.ReactNode,
    }[],
    /** The label for the header of the switcher. Settings this prop render the header and the switchers into the table. */
    switcherHead?: React.ReactNode,
    /** The ids of the rows where the switcher is on. */
    switcherValues?: number[],
    /** Called when the switcher state changes. */
    onSwitcherSwitched?: (switcherState: number[]) => void,
    /** Called when the sorting of the table changes. */
    onSortingChanged?: (fromId: UniqueIdentifier, toId: UniqueIdentifier, direction: "up" | "down") => void,
    /** Whether the columns should be sortable by clicking on the header. The sorting is handled by the table itself. */
    enableColumnSorting?: boolean,
    paddingVertical?: string
    /** How many items should be shown per page. Used for frontend pagination. */
    itemsPerPage?: number,
    /** Whether every second row of the table should be darker. */
    striped?: boolean,
    /** The screen width at which the table switches to the mobile view. */
    mobileBreakpoint?: number,
    /** The placeholder to show when the table is empty. */
    placeholder?: React.ReactNode,
    /** Whether the data in the table is loading. */
    dataLoading?: boolean,
}

export function TableData({ className, ellipsis, alignment, disableWrap, ...props }: TableDataProps) {
    return (
        <td className={classNames(className, { ellipsis, "disable-wrap": disableWrap })} style={{ textAlign: alignment }} {...props} />
    )
}

export function TableRow<T extends string | number>({
    children,
    id,
    hideBorder,
    isMobile,
    paddingVertical,
    switcherEnabled,
    switcherDisabled,
    ...props
}: TableRowProps<T>) {
    // If the draggableNodes size is 0, the table doesn't support dragging. A bit hacky way to check whether the table supports dragging. Avoids having to write a custom context provider.
    const isDraggable = (useDndContext()?.draggableNodes.size > 0) && (id !== undefined);
    const { value, toggle } = useCheckboxList() ?? {};
    const switcherState = value?.includes(id ?? -1);
    const handleSwitcherChange = () => id && toggle?.(id);

    assert(!((switcherEnabled || isDraggable) && id === undefined), "The id prop must be set when using the switcher or the drag feature");

    const {
        attributes,
        listeners,
        transform,
        transition,
        setNodeRef,
        isDragging
    } = useSortable({
        id: id ?? -1,
        disabled: !isDraggable,
    });

    const style = {
        transform: CSS.Translate.toString(transform),
        transition: transition
    };

    return (
        <tr ref={setNodeRef} {...props} style={style} className={classNames("table-row", { "show-border": !hideBorder, "dragging": isDragging })}>
            {!isMobile && isDraggable && (
                <td className="drag-handle">
                    <DragHandleIcon {...attributes} {...listeners} />
                </td>
            )}
            {!isMobile && switcherEnabled && (
                <td>
                    <TooltipParent>
                        <Switcher value={switcherState} onChange={handleSwitcherChange} disabled={!!switcherDisabled} />
                        {switcherDisabled && typeof switcherDisabled === "string" && (
                            <Tooltip>{switcherDisabled}</Tooltip>
                        )}
                    </TooltipParent>
                </td>
            )}
            {isMobile && (isDraggable || switcherEnabled) && (
                <td className={classNames("mobile-switcher-drag", { "switcher-enabled": switcherEnabled })} style={{
                    paddingTop: paddingVertical,
                    paddingBottom: paddingVertical,
                }}>
                    <Column height="100%">
                        {switcherEnabled && (
                            <TooltipParent>
                                <Switcher value={switcherState} onChange={handleSwitcherChange} disabled={!!switcherDisabled} />
                                {switcherDisabled && typeof switcherDisabled === "string" && (
                                    <Tooltip>{switcherDisabled}</Tooltip>
                                )}
                            </TooltipParent>
                        )}
                        {isDraggable && (
                            <Row flex={1} align="center" vAlign="center">
                                <DragHandleIcon {...attributes} {...listeners} />
                            </Row>
                        )}
                    </Column>
                </td>

            )}
            {children}
        </tr>
    )
}

export function DividerRow({
    children,
    colSpan,
    tableHasGrabHandle = false,
    isMobile,
}: DividerRowProps) {
    return (
        <tr className="divider-row">
            {!isMobile && tableHasGrabHandle && <td className="drag-handle" />}
            <td colSpan={colSpan - (tableHasGrabHandle ? 1 : 0)}>
                {children}
            </td>
        </tr>
    )
}

export default function Table<T extends string | number>({
    className,
    head: headProp,
    rows,
    dividerRows,
    switcherHead,
    switcherValues,
    onSwitcherSwitched: onChangeSwitcherValues,
    onSortingChanged,
    enableColumnSorting = false,
    paddingVertical,
    itemsPerPage,
    striped,
    mobileBreakpoint,
    placeholder,
    dataLoading,
}: TableProps<T>) {
    const paginationEnabled = itemsPerPage !== undefined;
    const switcherEnabled = switcherHead !== undefined; // Empty string would be false
    const dragEnabled = onSortingChanged !== undefined;
    assert(!(dragEnabled && enableColumnSorting), "Sorting and dragging cannot be enabled at the same time");

    const { width } = useScreenSize();
    const isMobile = mobileBreakpoint !== undefined && width < mobileBreakpoint;

    const { t } = useTranslation();

    const [page, setPage] = useState(1);
    const numPages = itemsPerPage ? Math.ceil(rows.length / itemsPerPage) : undefined;
    const [sortBy, setSortBy] = useState<SortBy | null>(enableColumnSorting ? {
        index: null,
        descending: true,
    } : null);


    const sortedRows = useMemo(() => ((sortBy && sortBy.index !== null) ? (
        rows.sort((a, b) => compare(a.data[sortBy.index!], b.data[sortBy.index!]) * (sortBy.descending ? -1 : 1))
    ) : rows), [rows, sortBy]);

    const paginatedRows = useMemo(() => (itemsPerPage ? (
        sortedRows.slice((page - 1) * itemsPerPage!, page * itemsPerPage!)
    ) : sortedRows), [sortedRows, page, itemsPerPage]);

    const fillWidthCount = useMemo(() => headProp.reduce((acc, { fillWidth }) => acc + (fillWidth ? 1 : 0), 0), [headProp]);

    const head = useMemo(() => (
        <thead>
            <tr>
                {!isMobile && dragEnabled && <th className="drag-handle-head" />}
                {!isMobile && switcherEnabled && <th>{switcherHead}</th>}
                {headProp.map(({ label, disableSort, fillWidth, style, cellAlignment, ...props }, index) => (
                    <th
                        key={index}
                        onClick={(sortBy && !disableSort) ? (() => setSortBy({
                            index,
                            descending: sortBy.index === index && !sortBy.descending
                        })) : undefined}
                        style={{
                            ...style,
                            width: fillWidth ? `${Math.round(100 / fillWidthCount)}%` : style?.width,
                            textAlign: cellAlignment ?? "left",
                        }}
                        colSpan={isMobile && (dragEnabled || switcherEnabled) && index === 0 ? 2 : undefined}
                        {...props}
                    >
                        <div>
                            <span>{label}</span>
                            {sortBy && !disableSort && (
                                <div className="sort-icon">
                                    {sortBy.index === index ? (
                                        sortBy.descending ? (
                                            <SortDownIcon />
                                        ) : (
                                            <SortUpIcon />
                                        )
                                    ) : (
                                        <SortIcon />
                                    )}
                                </div>
                            )}
                        </div>
                    </th>
                ))}
            </tr>
        </thead>
    ), [headProp, sortBy, setSortBy, isMobile]);

    const foot = useMemo(() => paginationEnabled ? (
        <Row className="table-foot">
            <Row className="pagination">
                <button disabled={page === 1} onClick={() => setPage(page - 1)}>
                    <ArrowLeftIcon />
                </button>
                <span>{numPages ? t("table.pagination.outOf", { page, numPages }) : page}</span>
                <button disabled={page === numPages} onClick={() => setPage(page + 1)}>
                    <ArrowRightIcon />
                </button>
            </Row>
        </Row>
    ) : null, [page, setPage, numPages, paginationEnabled]);


    const inner = useMemo(() => (
        <>
            <table className={classNames("table", className, { striped, "mobile": isMobile })}>
                {head}
                <tbody>
                    <SortableContext disabled={!dragEnabled} items={sortedRows} strategy={verticalListSortingStrategy}>
                        <CheckboxList value={switcherValues} onChange={onChangeSwitcherValues}>
                            {paginatedRows.length !== 0 ? paginatedRows.map(({ id, data, switcherDisabled }, index) => {
                                let dividerRow = dividerRows?.find((divRow) => divRow.index === index);
                                let hideBorder = index == sortedRows.length - 1 || (dividerRows?.find((divRow) => divRow.index === index + 1) !== undefined);
                                let tableRow = (
                                    <TableRow id={id} key={id} hideBorder={hideBorder} isMobile={isMobile} paddingVertical={paddingVertical} switcherEnabled={switcherEnabled} switcherDisabled={switcherDisabled}>
                                        {data.map((cell, index) => (
                                            <TableData
                                                key={index}
                                                ellipsis={headProp[index].fillWidth}
                                                alignment={headProp[index].cellAlignment}
                                                disableWrap={headProp[index].disableWrap}
                                                style={{
                                                    paddingTop: paddingVertical,
                                                    paddingBottom: paddingVertical,
                                                    width: isMobile && (dragEnabled || switcherEnabled) && index === 0 ? "100%" : undefined
                                                }}
                                            >
                                                {cell}
                                            </TableData>
                                        ))}
                                    </TableRow>
                                )
                                if (dividerRow) {
                                    let dividerLength = data.length + (dragEnabled ? 1 : 0) + (switcherEnabled ? 1 : 0);
                                    return (
                                        <React.Fragment key={id}>
                                            <DividerRow colSpan={dividerLength} tableHasGrabHandle={dragEnabled} isMobile={isMobile}>
                                                {dividerRow.label}
                                            </DividerRow>
                                            {tableRow}
                                        </React.Fragment>
                                    )
                                }
                                return tableRow;
                            }) : (
                                placeholder && <tr className="placeholder-row">
                                    <TableData
                                        colSpan={headProp.length + (dragEnabled ? 1 : 0) + (switcherEnabled ? 1 : 0)}
                                        style={{ textAlign: "center" }}
                                    >
                                        <Loadable loading={dataLoading ?? false}>
                                            <Column gap="15px" height="100%" align="center" hAlign="center">
                                                <PlaceholderIcon width="50px" />
                                                <span>{placeholder}</span>
                                            </Column>
                                        </Loadable>
                                    </TableData>
                                </tr>
                            )}
                        </CheckboxList>
                    </SortableContext>
                </tbody>
            </table>
            {foot}
        </>
    ), [dragEnabled, sortedRows, dividerRows, head, switcherEnabled, switcherValues, onChangeSwitcherValues, foot, isMobile]);

    const handleSortingChanged = useCallback((event: DragEndEvent) => {
        event.over && event.over.id !== event.active.id && onSortingChanged?.(event.active.id, event.over?.id, event.delta.y > 0 ? "down" : "up");
    }, [onSortingChanged])

    if (!dragEnabled) {
        return inner;
    }

    return (
        <DndContext
            onDragEnd={handleSortingChanged}
            modifiers={[restrictToVerticalAxis]}
            collisionDetection={variableSizeItemsCollisionDetection}
        >
            {inner}
        </DndContext>
    )
}