import Divider from "@/js/Components/Divider";
import SocialButtons from "@/js/Components/SocialButtons";
import Splash from "@/js/Components/Splash";
import TosCheckbox from "@/js/Components/TosCheckbox";
import { useUser } from "@/js/Providers/UserProvider";
import { Button, EmailInput, Input, PasswordInput } from "@/js/glidespec";
import { Form, SubmitHandler } from "@enymo/react-form-component";
import { Column, Row } from "@enymo/react-layout";
import React from "react";
import { useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

interface Submit {
    first_name: string,
    last_name: string,
    email: string,
    password: string,
    tos: boolean
}

export default function SignUp() {
    const {t} = useTranslation();
    const form = useForm<Submit>();
    const navigate = useNavigate();
    const {signUp} = useUser();

    const handleSubmit: SubmitHandler<Submit> = async ({first_name, last_name, email, password}) => {
        await signUp(first_name, last_name, email, password);
        navigate("/post-login");
    }

    return (
        <Column gap="50px" maxWidth="480px" width="100%">
            <Splash title={t("signUp.title")}>{t("signUp.subtitle")}</Splash>
            <Form form={form} onSubmit={handleSubmit}>
                <Column gap="30px">
                    <SocialButtons />
                    <Divider>{t("divider.or")}</Divider>
                    <Row gap="10px">
                        <Input className="large" name="first_name" label={t("input.firstName")} options={{
                            required: t("input.firstName.required")
                        }} flex={1} />
                        <Input className="large" name="last_name" label={t("input.lastName")} options={{
                            required: t("input.lastName.required")
                        }} flex={1} />
                    </Row>
                    <EmailInput className="large" options={{
                        required: t("input.email.required"),
                    }} />
                    <Column gap="20px">
                        <PasswordInput className="large" name="password" label={t("signUp.password")} options={{
                            required: t("signUp.password.required"),
                            minLength: {
                                value: 8,
                                message: t("signUp.password.minLength")
                            }
                        }} placeholder={t("signUp.password.placeholder")} />
                        <TosCheckbox />
                    </Column>
                    <Button variant="primaryLogin" submit>{t("signUp.submit")}</Button>
                    <span className="lockscreen-link">
                        <Trans i18nKey="signUp.toLogin">
                            Already have an account? <Link className="link" to="/login">Log in here</Link>
                        </Trans>
                    </span>
                </Column> 
            </Form>           
        </Column>
    )
}