import TrashIcon from "@/svg/trash-regular.svg?react";
import useHybridFieldArray from "@enymo/react-hybrid-field-array-hook";
import { Column, Row } from "@enymo/react-layout";
import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Button, Input } from "../glidespec";

interface MultiDateInputProps {
    label?: string,
    value?: {
        date: string
    }[],
    onChange?(value: {
        date: string
    }[]): void,
    name?: string
}

export default function MultiDateInput({
    label,
    value,
    onChange,
    name
}: MultiDateInputProps) {
    const {t} = useTranslation();
    const {fields, append, remove} = useHybridFieldArray({name, onChange, value})
    const init = useRef(false);

    useEffect(() => {
        if (!init.current && fields.length === 0) {
            init.current = true;
            append({date: ""});
        }
    }, [init, fields.length, append]);

    return (
        <Column gap="8px" hAlign="left">
            {label && <label className="input-label">{label}</label>}
            <Column gap="10px" width="100%">
                {fields.map(({id}, index) => (
                    <Row key={id ?? index} gap="14px" vAlign="center">
                        <Input style={{width: "248px"}} name={`${name}.${index}.date`} options={{
                            required: index === 0 ? t("input.multiDate.required.first") : t("input.multiDate.required"),
                            pattern: {
                                value: /\d{2}\.\d{2}\./,
                                message: t("input.multiDate.pattern")
                            }
                        }} placeholder={t("input.multiDate.placeholder")} />
                        {index > 0 && (
                            <button type="button" onClick={() => remove(index)}>
                                <TrashIcon className="error" height="14px" />
                            </button>
                        )}
                    </Row>
                ))}
            </Column>
            <Button variant="link" onClick={() => append({date: ""})}>{t("input.multiDate.add")}</Button>
        </Column>
    )
}