import FileInput, { FileInputRef } from "@/js/Components/FileInput";
import { LoadingMediaComponent, MediaComponent, UploadingMediaComponent } from "@/js/Components/Post/MediaComponent";
import { usePostParams } from "@/js/Pages/Main/Campaigns/Posts/Post";
import { useToaster } from "@/js/Providers/ToasterProvider";
import { deleteLinkedListItem, randomString, resourceDragAndDropSorting, resourcesToLinkedList, sortLinkedList } from "@/js/common";
import { Post, PostMedium } from "@/js/resources";
import UploadIcon from "@/svg/arrow-up-from-bracket-regular.svg?react";
import InfoIcon from "@/svg/circle-info-solid.svg?react";
import vistaCreate from "@/svg/vista-create.png";
import { DndContext, DragEndEvent } from "@dnd-kit/core";
import { SortableContext, } from "@dnd-kit/sortable";
import { Column, Row } from "@enymo/react-layout";
import { ReturnList } from "@enymo/react-resource-hook";
import { assertNotNull, isNotNull } from "@enymo/ts-nullsafe";
import classNames from "classnames";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import "react-quill/dist/quill.snow.css";
import { FoldSwitcher } from "../FoldSwitcher";
import Tooltip, { TooltipParent } from "../Tooltip";

function stopPropagation(e: Event) {
    e.stopPropagation();
    e.preventDefault();
}

export default function MediaCarousel({
    media: [media, { store, update, destroy, loading }],
    mediaCount,
    postSettings
}: {
    media: [PostMedium[], ReturnList<PostMedium, PostMedium, null>],
    mediaCount?: number,
    postSettings?: Post["settings"]
}) {
    const { t } = useTranslation();
    const inputRef = useRef<FileInputRef>(null);
    const dropzoneRef = useRef<HTMLDivElement>(null);
    const dragEventCounter = useRef(0);
    const [fileDragActive, setFileDragActive] = useState(false);
    const [uploadProgressMap, setUploadProgressMap] = useState<{
        [key: string]: number,
    }>({});
    const [folded, setFolded] = useState(false);
    const toast = useToaster();

    const {
        screenSize,
        mediaLimitReached,
    } = usePostParams();

    const sortedMedia = useMemo(() => sortLinkedList(resourcesToLinkedList(media)), [media]);
    const uploadingMedia = useMemo(() => media.filter(({ id }) => id === undefined), [media]);

    const handleSetUploadProgress = useCallback((client_id: string, progress?: number) => {
        if (progress === undefined) {
            setUploadProgressMap(({ [client_id]: _, ...prev }) => ({
                ...prev,
            }));
        }
        else {
            setUploadProgressMap((prev) => ({
                ...prev,
                [client_id]: progress,
            }));
        }
    }, [setUploadProgressMap]);

    const handleSelectFile = useCallback(async (acceptedFiles: File[]) => {
        const maxVideoSize = 70;
        const maxImageSize = 3;
        const filteredFiles = acceptedFiles.filter((file) => {
            if (file.type.startsWith("image")) {
                return file.size <= maxImageSize * 10 ** 6;
            }
            else if (file.type.startsWith("video")) {
                return file.size <= maxVideoSize * 10 ** 6;
            }
            return false;
        });
        if (filteredFiles.length !== acceptedFiles.length) {
            toast({
                type: "error",
                title: t("posts.create.media.fileTooLarge.title"),
                text: t("posts.create.media.fileTooLarge.text")
            })
        }

        if (acceptedFiles.length === 0) {
            return;
        }
        await Promise.all(filteredFiles.map(file => {
            const client_id = randomString(16);
            return store({
                client_id: client_id,
                media_type: file.type.startsWith("image") ? "image" : "video",
                file,
            }, 'immediate', {
                onUploadProgress: (progressEvent) => {
                    const progress = (progressEvent.progress ?? 0) * 100;
                    if (progress < 100) {
                        handleSetUploadProgress(client_id, progress);
                    }
                    else {
                        handleSetUploadProgress(client_id, undefined);
                    }
                }
            })
        }))
    }, [store, toast, t]);

    useEffect(() => {
        if (isNotNull(dropzoneRef.current) && !mediaLimitReached) {
            const handleDrop = (e: DragEvent) => {
                stopPropagation(e);
                if (e.dataTransfer) {
                    handleSelectFile([...e.dataTransfer.files]);
                }
                setFileDragActive(false);
                dragEventCounter.current = 0;
            }
            const handleDragEnter = (e: DragEvent) => {
                dragEventCounter.current++;
                stopPropagation(e);
                setFileDragActive(true);
            }
            const handleDragLeave = (e: DragEvent) => {
                dragEventCounter.current--;
                stopPropagation(e);
                if (dragEventCounter.current === 0) {
                    setFileDragActive(false);
                }
            }
            dropzoneRef.current.addEventListener("drop", handleDrop);
            dropzoneRef.current.addEventListener("dragenter", handleDragEnter);
            dropzoneRef.current.addEventListener("dragleave", handleDragLeave);
            dropzoneRef.current.addEventListener("dragover", stopPropagation);

            return () => {
                dropzoneRef.current?.removeEventListener("drop", handleDrop);
                dropzoneRef.current?.removeEventListener("dragenter", handleDragEnter);
                dropzoneRef.current?.removeEventListener("dragleave", handleDragLeave);
                dropzoneRef.current?.removeEventListener("dragover", stopPropagation);
            }
        }
    }, [dropzoneRef, handleSelectFile, dragEventCounter, setFileDragActive]);

    const handleDelete = (id: number) => async () => {
        await deleteLinkedListItem(media, id, update, destroy);
    }

    const sorter = useCallback(resourceDragAndDropSorting(media, update), [media, update]);

    const handleSortingChanged = (event: DragEndEvent) => {
        event.over && event.over.id !== event.active.id && sorter(event.active.id, event.over?.id, sortedMedia.findIndex(medium => medium.id === event.active.id) < sortedMedia.findIndex(medium => medium.id === event.over!.id) ? "down" : "up");
    }

    const uploadBox = useMemo(() => (
        <TooltipParent disabled={!mediaLimitReached} style={{ flex: 1 }}>
            <div
                ref={dropzoneRef}
                className={classNames("add-media", "new-media-box", {
                    active: fileDragActive,
                    mobile: screenSize === "mobile",
                    disabled: mediaLimitReached,
                })}
            >
                <UploadIcon />
                <Column gap={screenSize === "desktop" ? "6px" : "2px"} flex="unset">
                    <span className="text">
                        <Trans i18nKey="posts.create.media.addMedia">
                            <span>Drop media here or</span> <button
                                disabled={mediaLimitReached}
                                onClick={() => inputRef.current?.open()}
                            >select files</button>
                        </Trans>
                    </span>
                    <span className="note">
                        <Trans i18nKey="posts.create.media.addMediaNote" values={{
                            image: "3",
                            video: "70",
                        }}>
                            Max. 3MB per image<br />
                            Max. 70MB per video
                        </Trans>
                    </span>
                    <FileInput onSelected={handleSelectFile} ref={inputRef} accept="image/*,video/*" multiple />
                </Column>
            </div>
            <Tooltip>{t("posts.create.media.limitReached")}</Tooltip>
        </TooltipParent>
    ), [fileDragActive, dropzoneRef, handleSelectFile, inputRef, screenSize, mediaLimitReached]);

    const vistaButton = useMemo(() => (
        <TooltipParent style={{ height: "unset" }} disabled={!mediaLimitReached}>
            <button
                className={classNames("new-media-box", "vista", {
                    mobile: screenSize === "mobile",
                    disabled: mediaLimitReached,
                })}
                disabled={mediaLimitReached}
            >
                <img src={vistaCreate} alt="vista" />
            </button>
            <Tooltip>{t("posts.create.media.limitReached")}</Tooltip>
        </TooltipParent>
    ), [screenSize, mediaLimitReached]);

    useEffect(() => {
        if (screenSize !== "mobile") {
            setFolded(false);
        }
    }, [screenSize, setFolded])

    return (
        <DndContext onDragEnd={handleSortingChanged}>
            <Column className="media-wrapper" gap="18px" minWidth={screenSize === "wide-desktop" ? "558px" : undefined} flex={screenSize === "wide-desktop" ? 1 : undefined}>
                <Row className="underline-title" gap="10px" vAlign="center">
                    <h3>{t("posts.create.media")}</h3>
                    {screenSize !== "mobile" ? (
                        <TooltipParent className="note">
                            <InfoIcon />
                            <Tooltip>{t("posts.create.media.note")}</Tooltip>
                        </TooltipParent>
                    ) : (
                        <FoldSwitcher folded={folded} setFolded={setFolded} />
                    )}
                </Row>
                {!folded && (
                    <Row className="media" flex={1}>
                        <Column gap="17px" flex={1}>
                            {screenSize === "mobile" && (
                                <Row gap="10px" style={{ overflowX: "auto" }}>
                                    {uploadBox}
                                </Row>
                            )}
                            {loading ? (
                                <Column className="loading-placeholder" gap="20px">
                                    <Row gap="12px" wrap="wrap">
                                        {Array.from({ length: mediaCount ?? 0 }).map((_, index) => (
                                            <LoadingMediaComponent key={index} />
                                        ))}
                                    </Row>
                                </Column>
                            ) : (
                                <SortableContext
                                    items={sortedMedia}
                                >
                                    <Row gap="12px" vAlign="bottom" className="media-carousel" wrap="wrap">
                                        {sortedMedia.map((medium, index) => {
                                            assertNotNull(medium.id, "The media component needs an already uploaded medium.");
                                            const component = (
                                                <MediaComponent
                                                    key={medium.id}
                                                    onDelete={handleDelete(medium.id)}
                                                    medium={medium}
                                                    postSettings={postSettings}
                                                />
                                            )

                                            return index === 0 || index === 1 ? (
                                                <Column gap="8px" key={medium.id}>
                                                    <span className="next-post-note">
                                                        {t(`posts.create.nextPost.${index}`)}
                                                    </span>
                                                    {component}
                                                </Column>
                                            ) : (
                                                component
                                            )
                                        })}
                                        {uploadingMedia.map((medium, index) => {
                                            const newIndex = sortedMedia.length + index;
                                            const component = (
                                                <UploadingMediaComponent
                                                    key={medium.client_id}
                                                    medium={medium}
                                                    progress={uploadProgressMap[medium.client_id ?? ""] ?? 0}
                                                />
                                            )

                                            return newIndex === 0 || newIndex === 1 ? (
                                                <Column gap="8px" key={medium.client_id}>
                                                    <span className="next-post-note">
                                                        {t(`posts.create.nextPost.${newIndex}`)}
                                                    </span>
                                                    {component}
                                                </Column>
                                            ) : (
                                                component
                                            )
                                        })}
                                        {screenSize !== "mobile" && (
                                            <Column gap="5px" height="170px">
                                                {uploadBox}
                                            </Column>
                                        )}
                                    </Row>
                                </SortableContext>
                            )}
                        </Column>
                    </Row>
                )}
            </Column>
        </DndContext>
    )
}