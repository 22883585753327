import "@/css/pages/main/hashtags/lists.scss";
import HashtagInputContainer from "@/js/Components/HashtagInputContainer";
import Loadable from "@/js/Components/Loadable";
import Popup, { PopupFooter } from "@/js/Components/Popup";
import { useUser } from "@/js/Providers/UserProvider";
import { Button, Input } from "@/js/glidespec";
import { useHashtagListHashtags, useHashtagLists } from "@/js/resources";
import EditIcon from "@/svg/pen-solid.svg?react";
import TrashIcon from "@/svg/trash-solid.svg?react";
import ExclamationIcon from "@/svg/triangle-exclamation-duotone.svg?react";
import { Form, SubmitHandler } from "@enymo/react-form-component";
import { Column, Row } from "@enymo/react-layout";
import { assertNotNull } from "@enymo/ts-nullsafe";
import classNames from "classnames";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";


interface PopupForm {
    name: string,
}

export default function Lists() {
    const [params, setParams] = useSearchParams();
    const { t } = useTranslation();
    const { user } = useUser();
    assertNotNull(user);
    const navigate = useNavigate();

    const [lists, { loading: listsLoading, store, update, destroy }] = useHashtagLists();
    
    const selectedListId = params.has("list") ? Number(params.get("list")) : null;

    const hashtagParams = useMemo(() => ({
        hashtag_list: selectedListId ?? -1,
    }), [selectedListId]);

    const [hashtags, { 
        loading: hashtagsLoading,
        store: storeHashtag, 
        destroy: destroyHashtag,
        refresh: refreshHashtags,
        ...hashtagListActions
    }] = useHashtagListHashtags({
        params: hashtagParams,
        autoRefresh: false,
    });

    useEffect(() => { // Have to do it this to stop fetching hashtags when list is not selected
        if (hashtagParams.hashtag_list === -1) return;
        refreshHashtags();
    }, [refreshHashtags, hashtagParams]);

    const clearHashtags = useCallback(() => {
        for (const hashtag of hashtags) {
            destroyHashtag(hashtag.id, 'local-only');
        }
    }, [hashtags, destroyHashtag]);

    const setSelectedListId = useCallback((id: number | null) => {
        if (id === null) {
            setParams({ });
        } else {
            setParams({ list: id.toString() });
        }
        if (selectedListId !== id) {
            clearHashtags();
        }
    }, [setParams, clearHashtags],)


    const selectedList = useMemo(() => lists.find(list => list.id === selectedListId), [lists, selectedListId]);

    const [popupType, setPopupType] = useState<"new" | "edit" | "delete" | null>(null);
    const popupForm = useForm<PopupForm>();

    const handlePopupSubmit: SubmitHandler<PopupForm> = async (data) => {
        switch (popupType) {
            case "new":
                const id = (await store(data))?.id;
                id && setSelectedListId(id);
                break;
            case "edit":
                if (selectedListId !== null) {
                    await update(selectedListId, data);
                }
                break;
            case "delete":
                if (selectedListId !== null) {
                    await destroy(selectedListId);
                    clearHashtags();
                }
                break;
        }
        setPopupType(null);
    }

    const handleSetPopupType = (type: "new" | "edit" | "delete") => {
        setPopupType(type);
        if (type === "edit") {
            popupForm.setValue("name", selectedList?.name ?? "");
        }
        if (type === "new") {
            popupForm.reset();
        }
    }
    

    useEffect(() => {
        if (selectedListId === null && lists.length > 0) {
            setSelectedListId(lists[0]?.id ?? null);
        }

        if (selectedListId !== null && lists.find(list => list.id === selectedListId) === undefined) {
            setSelectedListId(null);
        }
    }, [lists, selectedListId, setSelectedListId]);

    return (
        <>
            <div className="hashtag-lists">
                <Column gap="30px" className="lists-container">
                    <Row align="space">
                        <h3>{t("hashtags.lists.myLists")}</h3>
                        <button className="new-list" onClick={() => handleSetPopupType("new")}>
                            {t("hashtags.lists.newList")}
                        </button>
                    </Row>
                    <Column gap="10px">
                        {!listsLoading ? (
                            lists?.map(({ id, name }) => (
                                <button
                                    key={id}
                                    className={classNames("list-entry", { selected: selectedListId === id })}
                                    onClick={() => setSelectedListId(id)}
                                >
                                    {name}
                                </button>
                            ))
                        ) : (
                            <>
                                <button className={classNames("list-entry", "loading")} />
                                <button className={classNames("list-entry", "loading")} />
                            </>
                        )}
                    </Column>
                </Column>
                <Column className="hashtag-list-container" flex={1} gap="25px">
                    <Row gap="12px">
                        <h3>{selectedList?.name}</h3>
                        <Row gap="5px">
                            <button
                                onClick={() => handleSetPopupType("edit")}
                                className="hashtag-list-icon-button"
                            >
                                <EditIcon />
                            </button>
                            <button
                                onClick={() => handleSetPopupType("delete")}
                                className="hashtag-list-icon-button"
                            >
                                <TrashIcon />
                            </button>
                        </Row>
                    </Row>
                    <Loadable loading={hashtagsLoading} fade>
                        <HashtagInputContainer 
                            hashtags={[hashtags, { 
                                store: storeHashtag, 
                                destroy: destroyHashtag, 
                                refresh: refreshHashtags, 
                                loading: hashtagsLoading, 
                                ...hashtagListActions,
                            }]}
                            disabled={selectedListId === null}
                        />
                    </Loadable>
                </Column>
            </div>
            {popupType && (
                <Popup
                    maxWidth="500px"
                    width="100%"
                    onBackgroundClick={() => setPopupType(null)}
                >
                    <Form form={popupForm} onSubmit={handlePopupSubmit}>
                        <Column padding="30px 25px 40px" gap="30px">
                            <Row gap="15px" vAlign="center">
                                {popupType === "delete" && <ExclamationIcon width="40px" height="35px" fill="var(--warning)" />}
                                <h1>{t(`hashtags.lists.popup.${popupType}`)}</h1>
                            </Row>
                            {popupType === "delete" && <p>{t("hashtags.lists.popup.delete.explanation")}</p>}
                            {popupType !== "delete" && <Input name="name" label={t("input.name")} options={{
                                required: t("input.name.required"),
                            }} />}
                        </Column>
                        <PopupFooter padding="0 25px" height="80px" vAlign="center" align="end" gap="35px">
                            <Button
                                variant="secondary" onClick={() => setPopupType(null)}>
                                {t("cancel")}
                            </Button>
                            <Button variant={popupType === "delete" ? "danger" : "primary"} submit>
                                {t(`hashtags.lists.popup.${popupType}.submit`)}
                            </Button>
                        </PopupFooter>
                    </Form>
                </Popup>
            )}
        </>

    )
}