import "@/css/components/month-select.scss";
import { createGlideChoice } from "@enymo/glide";
import { Column } from "@enymo/react-layout";
import React from "react";
import { useTranslation } from "react-i18next";
import { months } from "../common";
import { CheckboxList } from "../glidespec";
import { FormInputProps } from "../types";

const MonthSelectItem = createGlideChoice({
    wrapperStyle: {
        borderRadius: "12px",
        background: "var(--bg-400)",
        fontSize: "15px",
        fontWeight: 500,
        color: "var(--text-500)",
        cursor: "pointer"
    },
    labelStyle: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    selectedWrapperStyle: {
        border: "2px solid var(--error)",
        color: "var(--error)"
    }
});

interface MonthSelectProps extends FormInputProps<typeof months[number][]> {
    label?: string
}

export default function MonthSelect({
    label,
    ...props
}: MonthSelectProps) {
    const {t} = useTranslation();

    return (
        <Column gap="8px">
            {label && <label className="input-label">{label}</label>}
            <CheckboxList className="month-select-grid" handlesError {...props}>
                {months.map(month => (
                    <MonthSelectItem key={month} value={month}>{t(`months.${month}.short`)}</MonthSelectItem>
                ))}
            </CheckboxList>
        </Column>
    )
}