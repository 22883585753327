import { Column } from "@enymo/react-layout";
import React from "react";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router";
import Menu, { MenuItem } from "../Components/Menu";
import useBreakpoint from "../Hooks/BreakpointHook";
import { useTitle } from "./Main";

export default function Settings({ children }: {
    children?: React.ReactNode
}) {
    const { t } = useTranslation();

    useTitle(t("settings"));

    const mobile = useBreakpoint(768);

    return (
        <Column flex={1}>
            <Column padding={mobile ? "25px" : "25px 50px"}>
                <Menu>
                    <MenuItem to="/app/settings/general">{t("settings.general")}</MenuItem>
                    <MenuItem to="/app/settings/password">{t("settings.password")}</MenuItem>
                    <MenuItem to="/app/settings/subscription">{t("settings.subscription")}</MenuItem>
                    <MenuItem to="/app/settings/payment-info">{t("settings.paymentInfo")}</MenuItem>
                    <MenuItem to="/app/settings/invoices">{t("settings.invoices")}</MenuItem>
                </Menu>
            </Column>
            <Column style={{ overflowY: "auto" }} flex={1} padding={mobile ? "25px" : "25px 50px"}>
                {children ?? <Outlet />}
            </Column>
        </Column>
    )
}