import TosCheckbox from "@/js/Components/TosCheckbox";
import { useUser } from "@/js/Providers/UserProvider";
import { Button, Input } from "@/js/glidespec";
import { Form, SubmitHandler } from "@enymo/react-form-component";
import { Column, Row } from "@enymo/react-layout";
import { assertNotNull } from "@enymo/ts-nullsafe";
import React from "react";
import { useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import route from "ziggy-js";
import LockscreenLogout from "../Components/LockscreenLogout";
import Splash from "../Components/Splash";
import Lockscreen from "../Layouts/Lockscreen";
import { useApp } from "../Providers/AppProvider";
import { useToaster } from "../Providers/ToasterProvider";

interface Submit {
    first_name: string,
    last_name: string,
    tos: boolean
}

export default function PostLogin() {
    const {t} = useTranslation();
    const {axios} = useApp();
    const {user, update, logout} = useUser();
    const toast = useToaster();
    const navigate = useNavigate();

    assertNotNull(user, "User must be logged in");

    const form = useForm<Submit>({
        values: user !== null ? {
            first_name: user.first_name,
            last_name: user.last_name,
            tos: false
        } : undefined
    });

    const handleSubmit: SubmitHandler<Submit> = async ({first_name, last_name}) => {
        await update({
            first_name,
            last_name,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        });
        navigate("/app");
    }

    const handleReRequest = async () => {
        await axios.post(route("users.send-verify-email"));
        toast({
            type: "success",
            title: t("verifyEmail.resent.title"),
            text: t("verifyEmail.resent.text")
        });
    }

    const handleLogout = async () => {
        await logout();
        navigate("/login");
    }

    return (
        <Lockscreen>
            {(user.has_google || user.has_facebook || user.has_linkedin) ? (
                <Column gap="40px" maxWidth="480px" width="100%">
                    <Splash title={t("completeSignUp.title")} />
                    <Form form={form} onSubmit={handleSubmit}>
                        <Column gap="30px">
                            <Row gap="10px">
                                <Input name="first_name" options={{
                                    required: t("input.firstName.required")
                                }} label={t("input.firstName")} flex={1} />
                                <Input name="last_name" options={{
                                    required: t("input.lastName.required")
                                }} label={t("input.lastName")} flex={1} />
                            </Row>
                            <TosCheckbox />
                            <Button variant="primaryLogin" submit>{t("signUp.submit")}</Button>
                            <button type="button" className="lockscreen-logout" onClick={handleLogout}>
                                <span>{t("cancel")}</span>
                            </button>
                        </Column>
                    </Form>
                </Column>
            ) : (
                <Column gap="30px" width="480px">
                    <Splash title={t("verifyEmail.title")}>{t("verifyEmail.subtitle")}</Splash>
                    <span className="lockscreen-link large">
                        <Trans i18nKey="verifyEmail.send">
                            Didn't get the email? <button className="link" onClick={handleReRequest}>Request again</button>
                        </Trans>
                    </span>
                    <LockscreenLogout />
                </Column>
            )}
        </Lockscreen>
    )
}