import "@/css/components/search-field.scss";
import SearchIcon from "@/svg/magnifying-glass-regular.svg?react";
import useHybridInput from "@enymo/react-hybrid-input-hook";
import classNames from "classnames";
import React from "react";

export default function SearchField({
    value: externalValue,
    onChange: externalOnChange,
    name,
    placeholder,
    maxWidth = "320px",
    ...props
}: {
    placeholder?: string;
    maxWidth?: string;
    value?: string;
    onChange?: (value: string) => void;
    name?: string;
}) {

    const { value, onChange } = useHybridInput({ name, externalOnChange, externalValue, defaultValue: ""});

    return (
        <label className={classNames("search-field")} style={{ maxWidth }} {...props} >
            <SearchIcon />
            <input placeholder={placeholder} value={value} onChange={(e) => onChange(e.target.value)} />
        </label>
    )
}