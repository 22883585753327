import React from "react";
import { Navigate, Outlet } from "react-router";
import { useUser } from "./Providers/UserProvider";

export default function Gate({realm}: {
    realm: "login" | "app" | "post-login" | "account-restricted"
}) {
    const {user, loading} = useUser();

    if (loading) {
        return null;
    }

    if (user === null && realm !== "login") {
        return <Navigate to="/login" replace />
    }
    else if (user !== null && user.restricted && realm !== "account-restricted") {
        return <Navigate to="/account-restricted" replace />
    }
    else if (user !== null && user.email_verified_at === null && !user.restricted && realm !== "post-login") {
        return <Navigate to="/post-login" replace />
    }
    else if (user !== null && user.email_verified_at !== null && !user.restricted && realm !== "app") {
        return <Navigate to ="/app" replace />
    }
    else {
        return <Outlet />
    }
}