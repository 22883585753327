import "@/css/pages/main/campaigns/settings/hashtags.scss";
import HashtagInputContainer from "@/js/Components/HashtagInputContainer";
import HashtagListInsertion from "@/js/Components/HashtagListInsertion";
import { Hashtag, useCampaignHashtags } from "@/js/resources";
import { Column, Row } from "@enymo/react-layout";
import { assertNotNull } from "@enymo/ts-nullsafe";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";

export default function Hashtags() {
    const { t } = useTranslation();
    const { campaignId } = useParams();
    assertNotNull(campaignId);

    const [showHashtagLists, setShowHashtagLists] = useState(false);

    const [hashtags, {store, ...hashtagActions}] = useCampaignHashtags({
        params: useMemo(() => ({
            campaign: campaignId,
        }), [campaignId]),
    });

    const handleAddHashtags = (hashtags: Hashtag | Hashtag[]) => {
        hashtags = Array.isArray(hashtags) ? hashtags : [hashtags];
        for (const hashtag of hashtags) {
            store(hashtag, "immediate");
        }
    }

    const mobile = false;
    return (
        <Column padding={mobile ? "35px 16px" : "35px 50px"} gap="30px" className="campaign-hashtags">
            <Row align="space">
                <span className="title">{t("campaigns.settings.hashtags.title")}</span>
                {!showHashtagLists && (
                    <button className="from-list-button" onClick={() => setShowHashtagLists(true)}>
                        {t("campaigns.settings.hashtags.fromHashtagList")}
                    </button>
                )}
            </Row>
            {showHashtagLists && (
                <HashtagListInsertion onAddHashtag={handleAddHashtags} />
            )}
            <HashtagInputContainer hashtags={[hashtags, { store, ...hashtagActions }]} />
        </Column>
    )
}