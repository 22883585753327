import "@/css/components/language-select.scss";
import ChevronDown from "@/svg/chevron-down-regular.svg?react";
import GlobeIcon from "@/svg/globe-solid.svg?react";
import useOnClickOutside from "@enymo/react-click-outside-hook";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

export default function LanguageSelect<T>({
    languages,
    value,
    onChange
}: {
    languages: readonly T[],
    value: T,
    onChange(value: T): void
}) {
    const {t} = useTranslation();
    const [showDropdown, setShowDropdown] = useState(false);
    const ref = useOnClickOutside<HTMLDivElement>(() => setShowDropdown(false), [setShowDropdown]);

    const handleChange = (value: T) => {
        setShowDropdown(false);
        onChange(value);
    }

    return (
        <div ref={ref} className="language-select">
            <button onClick={() => setShowDropdown(!showDropdown)}>
                <GlobeIcon className="globe" />
                <span>{t(`language.${value}`)}</span>
                <ChevronDown className="chevron" />
            </button>
            {showDropdown && (
                <div className="dropdown">
                    {languages.map((language, index) => (
                        <button key={index} onClick={() => handleChange(language)}>{t(`language.${language}`)}</button>
                    ))}
                </div>
            )}
        </div>
    )
}