import { Row } from "@enymo/react-layout";
import React from "react";
import { useTranslation } from "react-i18next";
import { SocialAccountType } from "../types";
import Select, { SelectProps } from "./Select";
import SocialMediaIcon from "./SocialMediaIcon";

interface SocialAccountSelectProps extends Omit<SelectProps<SocialAccountType>, "choices"> {
    types: SocialAccountType[]
}

export default function SocialAccountSelect({types, ...props}: SocialAccountSelectProps) {
    const {t} = useTranslation();

    return (
        <Select
            choices={types.map(type => ({
                key: type,
                value: type,
                element: (
                    <Row gap="15px" vAlign="center">
                        <SocialMediaIcon 
                            width={type === "youtube" ? undefined : "22px"} 
                            height={type === "youtube" ? "20px" : undefined} 
                            type={type} 
                            alwaysShowYT
                        />
                        <span>{t(`social.${type}`)}</span>
                    </Row>
                )
            }))}
            {...props}
        />
    )
}