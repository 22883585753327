import AvatarWithLogo from "@/js/Components/AvatarWithLogo";
import SearchField from "@/js/Components/SearchField";
import useBreakpoint from "@/js/Hooks/BreakpointHook";
import { channelAvatar } from "@/js/common";
import { Button, CheckboxList } from "@/js/glidespec";
import { useCampaigns, useSocialChannels } from "@/js/resources";
import { SocialAccountType } from "@/js/types";
import CheckIcon from "@/svg/check-regular.svg?react";
import { createGlideChoice } from "@enymo/glide";
import { Form, SubmitHandler } from "@enymo/react-form-component";
import { Column, Row } from "@enymo/react-layout";
import { requireNotNull } from "@enymo/ts-nullsafe";
import React, { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";

const SocialChannelChoice = createGlideChoice({
    className: "campaign-social-channel-choice",
    indicator: {
        style: {
            width: "26px",
            height: "26px",
            border: "2px solid var(--neutral-200)",
            borderRadius: "8px",
            background: "var(--bg-100)"
        },
        selectedStyle: {
            borderColor: "var(--primary-500)",
            background: "var(--primary-500)"
        },
        element: <CheckIcon width="18px" fill="white" />
    },
    childrenGap: "30px",
    childrenVerticalAlignment: "center",
    wrapperStyle: {
        borderRadius: "8px",
        border: "2px solid var(--neutral-200)",
        padding: "6px 20px",
        cursor: "pointer"
    },
    element: ({src, type, children}: {
        src?: string,
        type: SocialAccountType,
        children: string
    }) => (
        <Row gap="26px" vAlign="center" flex={1}>
            <AvatarWithLogo
                size={34}
                src={src}
                initials={children.split(" ").map(([i]) => i).join("")}
                type={type}
            />
            <span>{children}</span>
        </Row>
    )
})

interface Submit {
    active_social_channels: number[]
}

export default function SocialAccounts() {
    const {t} = useTranslation();

    const {campaignId} = useParams();
    const [campaign, {update}] = useCampaigns({id: Number(requireNotNull(campaignId))});
    const [search, setSearch] = useState("");
    const mobile = useBreakpoint(768);

    const form = useForm<Submit>({
        values: campaign ? {
            active_social_channels: campaign.active_social_channels
        } : undefined
    });

    const [socialChannels, {refresh}] = useSocialChannels();
    const filteredSocialChannels = useMemo(() => socialChannels.filter(({name}) => name.toLowerCase().includes(search.toLowerCase())), [socialChannels, search]);

    const handleSubmit: SubmitHandler<Submit> = async data => {
        await update(data);
        refresh();
    }

    return (
        <Column gap="40px" padding={mobile ? "35px 16px" : "35px 50px"}>
            <p className="input-label">{t("campaigns.settings.socialAccounts.title")}</p>
            <Form form={form} onSubmit={handleSubmit}>
                <Column gap="40px" maxWidth="450px" width="100%">
                    <Column gap="30px">
                        <Row>
                            <SearchField value={search} onChange={setSearch} maxWidth="320px" placeholder={t("campaigns.settings.socialAccounts.search")} />
                        </Row>
                        <Column gap="12px">
                            <CheckboxList name="active_social_channels">
                                {filteredSocialChannels.map(({id, icon_type, has_avatar, name}) => (
                                    <SocialChannelChoice key={id} src={channelAvatar({id, has_avatar})} value={id} type={icon_type}>{name}</SocialChannelChoice>
                                ))}
                            </CheckboxList>
                        </Column>
                    </Column>
                    <Row align="end">
                        <Button variant="primary" submit>{t("save")}</Button>
                    </Row>
                </Column>
            </Form>
        </Column>
    )
}