import LockscreenLogout from "@/js/Components/LockscreenLogout";
import Splash from "@/js/Components/Splash";
import { Style } from "@/js/Components/Style";
import Lockscreen from "@/js/Layouts/Lockscreen";
import { Column } from "@enymo/react-layout";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function AccountRestricted() {
    const { t } = useTranslation();
    return (
        <Lockscreen>
            <Column gap="30px" width="480px">
                <Splash title={t("accountRestricted.title")}>
                    <Trans i18nKey="accountRestricted.subtitle">
                        We have blocked access to your account. 
                        Please contact our support at <Link to="https://superfred.com/support">
                            <Style color="var(--links)" fontWeight="500">superfred.com/support</Style>
                        </Link> to find out more
                    </Trans>
                </Splash>
                <LockscreenLogout />
            </Column>
        </Lockscreen>
    )
}