import React from "react";
import "@/css/components/tab-switcher.scss";
import { Row } from "@enymo/react-layout";
import classNames from "classnames";
import { createRequiredContext } from "@enymo/react-better-context";
import useHybridInput from "@enymo/react-hybrid-input-hook";

const [TabSwitcherProvider, useTabSwitcher] = createRequiredContext<{
    value: string,
    setValue: (value: string) => void,
}>("TabSwitcherContext must be provided to use Tab components");

export default function TabSwitcher<T extends string>({
    value: externalValue,
    onChange: externalOnChange,
    name,
    children
}: {
    value?: T,
    onChange?: (value: T) => void,
    name?: string,
    children?: React.ReactNode,
}) {

    const { value, onChange } = useHybridInput({ externalValue, externalOnChange, name });

    return (
        <TabSwitcherProvider value={{ value, setValue: onChange as (value: string) => void }}>
            <Row className="tab-switcher" vAlign="center">
                {children}
            </Row>
        </TabSwitcherProvider>
    )
}

export function Tab({
    children,
    value,
}: {
    children?: React.ReactNode,
    value: string,
}) {

    const { value: activeValue, setValue } = useTabSwitcher();

    return (
        <button className={classNames("tab", { active: value === activeValue })} onClick={() => setValue(value)}>
            {children}
        </button>
    )
}

export function Tag({ children }: {
    children?: React.ReactNode
}) {

    return (
        <div className="tag">
            {children}
        </div>
    )
}