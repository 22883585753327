import "@/css/components/active-switch.scss";
import { Row } from "@enymo/react-layout";
import { isNotNull } from "@enymo/ts-nullsafe";
import classNames from "classnames";
import React from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Switcher, { SwitcherProps } from "./Switcher";


export default function ActiveSwitch(props: SwitcherProps) {
    const {t} = useTranslation();
    const active = (isNotNull(props.name) ? useFormContext().watch(props.name) : props.value) ?? false;

    return (
        <div className={classNames("active-switch", {active})}>
            <Switcher {...props} />
            <Row gap="5px" vAlign="center">
                <div className="indicator" />
                <span>{active ? t("activeSwitch.active") : t("activeSwitch.inactive")}</span>
            </Row>
        </div>
    )
}