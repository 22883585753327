import "@/css/components/key-performance-indicator.scss";
import ArrowUpLongIcon from "@/svg/arrow-up-long-regular.svg?react";
import { Row } from "@enymo/react-layout";
import { isNotNull } from "@enymo/ts-nullsafe";
import classNames from "classnames";
import React, { useMemo } from "react";
import TooltipIcon from "./TooltipIcon";

export default function KeyPerformanceIndicator({
    title,
    tooltip,
    value,
    previous,
    flex
}: {
    title: React.ReactNode,
    tooltip: React.ReactNode,
    value: number,
    previous?: number | null,
    flex?: string | number
}) {
    const change = useMemo(() => isNotNull(previous) ? Math.round(Math.abs(value / previous - 1) * 100) : undefined, [previous, value]);

    return (
        <div className="key-performance-indicator" style={{flex}}>
            <Row align="space" vAlign="center">
                <h1>{title}</h1>
                <TooltipIcon>{tooltip}</TooltipIcon>
            </Row>
            <Row vAlign="bottom" gap="10px">
                <span className="value">{value}</span>
                {isNotNull(previous) && change !== undefined && !isNaN(change) && isFinite(change) && (
                    <div className={classNames("change-indicator", {
                        up: value > previous,
                        down: value < previous
                    })}>
                        <ArrowUpLongIcon />
                        <span>{change} %</span>
                    </div>
                )}
            </Row> 
        </div>
    )
}