import "@/css/components/post-preview.scss";
import ArrowsRotateIcon from "@/svg/arrows-rotate-regular.svg?react";
import FacebookCommentIcon from "@/svg/facebook-comment-icon.svg?react";
import FacebookLikeIcon from "@/svg/facebook-post-like-button.svg?react";
import FacebookShareIcon from "@/svg/facebook-post-share-icon.svg?react";
import InstagramShareIcon from "@/svg/instagram/svg-gobbler_0.svg?react";
import InstagramLikeIcon from "@/svg/instagram/svg-gobbler_1.svg?react";
import InstagramCommentIcon from "@/svg/instagram/svg-gobbler_2.svg?react";
import InstagramMarkIcon from "@/svg/instagram/svg-gobbler_3.svg?react";
import InstagramDotsIcon from "@/svg/instagram/svg-gobbler_4.svg?react";
import LinkedInCommentIcon from "@/svg/linkedin/comment.svg?react";
import LinkedInLikeIcon from "@/svg/linkedin/like.svg?react";
import LinkedInShareIcon from "@/svg/linkedin/repost.svg?react";
import TikTokLikeIcon from "@/svg/tiktok/svg-gobbler_0.svg?react";
import TikTokCommentIcon from "@/svg/tiktok/svg-gobbler_1.svg?react";
import TikTokMarkIcon from "@/svg/tiktok/svg-gobbler_2.svg?react";
import TikTokShareIcon from "@/svg/tiktok/svg-gobbler_3.svg?react";
import TikTokSearchIcon from "@/svg/tiktok/svg-gobbler_4.svg?react";
import TikTokPlusIcon from "@/svg/tiktok/svg-gobbler_5.svg?react";
import YouTubeCommentIcon from "@/svg/youtube/svg-gobbler_0.svg?react";
import YouTubeDislikeIcon from "@/svg/youtube/svg-gobbler_1.svg?react";
import YouTubeLikeIcon from "@/svg/youtube/svg-gobbler_2.svg?react";
import YouTubeShareIcon from "@/svg/youtube/svg-gobbler_3.svg?react";
import YouTubeDotsIcon from "@/svg/youtube/svg-gobbler_4.svg?react";
import YouTubeSearchIcon from "@/svg/youtube/svg-gobbler_5.svg?react";
import { Column, Row } from "@enymo/react-layout";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import route from "ziggy-js";
import { channelAvatar, initials, randomItem } from "../common";
import { PostMedium, PostText, useSocialChannels } from "../resources";
import { SocialAccountType } from "../types";
import { Avatar } from "./Avatar";
import Medium from "./Medium";
import PreviewText from "./PreviewText";
import SocialAccountSelect from "./SocialAccountSelect";

export default function PostPreview({
    postId,
    media,
    texts
}: {
    postId: number,
    media: PostMedium[],
    texts: PostText[]
}) {
    const {t} = useTranslation();
    const [type, setType] = useState<"facebook" | "instagram" | "tiktok" | "youtube" | "linkedin">();
    const [channels] = useSocialChannels();
    const availableTypes = useMemo(() => {
        const types: SocialAccountType[] = [];
        for (const channel of channels) {
            if (!types.includes(channel.icon_type) && (media.length > 0 || !["youtube", "instagram", "tiktok"].includes(channel.icon_type))) {
                types.push(channel.icon_type);
            }
        }
        return types;
    }, [channels, media]);
    const filteredChannels = useMemo(() => channels.filter(({icon_type}) => icon_type === type), [type, channels]);
    const [channelId, setChannelId] = useState<number>();
    const [mediumIndex, setMediumIndex] = useState<number>(0);
    const [textIndex, setTextIndex] = useState<number>(0);
    const channel = useMemo(() => filteredChannels.find(({id}) => id === channelId), [filteredChannels, channelId]);
    const medium = useMemo(() => media[mediumIndex], [media, mediumIndex]);
    const text = useMemo(() => texts[textIndex], [texts, textIndex]);
    const plainText = useMemo(() => text?.text.replace(/<p>(.*?)<\/p>/g, (_, match) => /<br ?\/?>/.test(match) ? "\n" : `${match}\n`), [text]);

    useEffect(() => {
        if (type === undefined && channels.length > 0) {
            setType(channels[0].icon_type);
        }
        if (channel === undefined && filteredChannels.length > 0) {
            setChannelId(randomItem(filteredChannels).id);
        }
        if (medium === undefined && media.length > 0) {
            setMediumIndex(0);
        }
        if (text === undefined && texts.length > 0) {
            setTextIndex(0);
        }
    }, [medium, media, channel, filteredChannels, text, texts, setMediumIndex, setTextIndex]);

    const handleShuffle = () => {
        if (media.length > 0) {
            setMediumIndex((mediumIndex + 1) % media.length);
        }
        setTextIndex((textIndex + 1) % texts.length);
    }

    if (channel === undefined || plainText === undefined) {
        return null;
    }

    return (
        <div className="post-preview">
            <Row gap="10px">
                <SocialAccountSelect
                    types={availableTypes}
                    value={type}
                    onChange={setType}
                    flex={1}
                />
                <button type="button" className="shuffle" onClick={handleShuffle}>
                    <ArrowsRotateIcon />
                </button>
            </Row>
            {(() => {
                switch (type) {
                    case "facebook":
                    case "linkedin":
                        return (
                            <div className={type}>
                                <Column gap="8px" padding="12px 14px">
                                    <Row gap="8px" vAlign="center">
                                        <Avatar src={channelAvatar(channel)} size={45} initials={initials(channel.name)} />
                                        <Column flex={1}>
                                            <span className="name">{channel.name}</span>
                                            <span className="time">{t("postPreview.justNow")}</span>
                                        </Column>
                                    </Row>
                                    <PreviewText
                                        text={plainText}
                                        hashtags={text.hashtags_string}
                                        maxLength={70}
                                        showMoreLabel={t(`postPreview.showMore.${type}`)}
                                    />
                                </Column>
                                {medium && (
                                    <Medium src={route("posts.media.file", {post: postId, medium: medium.id})} assetType={medium.media_type} />
                                )}
                                <div className="actions">
                                    <div>
                                        {type === "facebook" ? <FacebookLikeIcon /> : <LinkedInLikeIcon />}
                                        <span>{t("postPreview.like")}</span>
                                    </div>
                                    <div>
                                        {type === "facebook" ? <FacebookCommentIcon /> : <LinkedInCommentIcon />}
                                        <span>{t("postPreview.comment")}</span>
                                    </div>
                                    <div>
                                        {type === "facebook" ? <FacebookShareIcon /> : <LinkedInShareIcon />}
                                        <span>{t("postPreview.share")}</span>
                                    </div>
                                </div>
                            </div>
                        )
                    case "instagram":
                        return (
                            <div className="instagram">
                                <Row align="space" vAlign="center" padding="12px 14px">
                                    <Row flex={1} gap="10px" vAlign="center">
                                        <Avatar src={channelAvatar(channel)} initials={initials(channel.name)} size={30} />
                                        <span className="name">{channel.name}</span>
                                    </Row>
                                    <InstagramDotsIcon />
                                </Row>
                                {medium && (
                                    <Medium src={route("posts.media.file", {post: postId, medium: medium.id})} assetType={medium.media_type} />
                                )}
                                <Column gap="13px" padding="14px">
                                    <Row align="space" vAlign="center">
                                        <Row gap="15px" vAlign="center">
                                            <InstagramLikeIcon />
                                            <InstagramCommentIcon />
                                            <InstagramShareIcon />
                                        </Row>
                                        <InstagramMarkIcon />
                                    </Row>
                                    <PreviewText
                                        prefix={<span className="name">{channel.name}</span>}
                                        maxLength={70}
                                        showMoreLabel={t("postPreview.showMore.instagram")}
                                        text={plainText}
                                        hashtags={text.hashtags_string}
                                    />
                                    <span className="comment">{t("postPreview.comment")}...</span>
                                </Column>
                            </div>
                        )
                    case "tiktok":
                        return (
                            <div className="tiktok">
                                <Column flex={1} align="end" gap="12px">
                                    <span className="name">{channel.name}</span>
                                    <PreviewText
                                        maxLength={70}
                                        text={plainText}
                                        hashtags={text.hashtags_string}
                                        showMoreLabel={t("postPreview.showMore.tiktok")}
                                    />
                                </Column>
                                <Column align="space">
                                    <TikTokSearchIcon />
                                    <Column gap="18px" padding="0 0 90px 0" hAlign="center">
                                        <div className="tiktok-avatar">
                                            <Avatar src={channelAvatar(channel)} initials={initials(channel.name)} size={36} />
                                            <div className="plus">
                                                <TikTokPlusIcon width="11px" />
                                            </div>
                                        </div>
                                        <TikTokLikeIcon width="24px" />
                                        <TikTokCommentIcon width="24px" />
                                        <TikTokMarkIcon width="24px" />
                                        <TikTokShareIcon width="24px" />
                                    </Column>
                                </Column>
                                {medium && <Medium className="background" src={route("posts.media.file", {post: postId, medium: medium.id})} assetType={medium.media_type} />}
                            </div>
                        )
                    case "youtube":
                        return (
                            <div className="youtube">
                                <Column align="end" gap="8px" flex={1}>
                                    <Row gap="8px" vAlign="center">
                                        <Avatar size={30} src={channelAvatar(channel)} initials={initials(channel.name)} />
                                        <span className="name">{channel.name}</span>
                                        <div className="subscribe">{t("postPreview.subscribe")}</div>
                                    </Row>
                                    <PreviewText
                                        maxLength={70}
                                        text={plainText}
                                        hashtags={text.hashtags_string}
                                    />
                                </Column>
                                <Column align="space" hAlign="right">
                                    <Row vAlign="center">
                                        <YouTubeSearchIcon width="16px" />
                                        <YouTubeDotsIcon width="20px" />
                                    </Row>
                                    <Column gap="30px" padding="0 0 92px 0">
                                        <YouTubeLikeIcon />
                                        <YouTubeDislikeIcon />
                                        <YouTubeCommentIcon />
                                        <YouTubeShareIcon />
                                    </Column>
                                </Column>
                                {medium && <Medium className="background" src={route("posts.media.file", {post: postId, medium: medium.id})} assetType={medium.media_type} />}
                            </div>
                        )
                }
            })()}
        </div>
    )
}