import Popup, { PopupFooter } from "@/js/Components/Popup";
import Table from "@/js/Components/Table";
import useBreakpoint from "@/js/Hooks/BreakpointHook";
import { useApp } from "@/js/Providers/AppProvider";
import { useConfirmPopup } from "@/js/Providers/ConfirmPopupProvider";
import { useToaster } from "@/js/Providers/ToasterProvider";
import { Button, EmailInput } from "@/js/glidespec";
import { useCampaignUsers } from "@/js/resources";
import UserSlashIcon from "@/svg/user-slash-regular.svg?react";
import { Form, SubmitHandler } from "@enymo/react-form-component";
import { Column, Row } from "@enymo/react-layout";
import React, { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import route from "ziggy-js";

interface Submit {
    email: string
}

export default function Share() {
    const {t} = useTranslation();
    const {axios} = useApp();
    const {campaignId} = useParams();
    const [campaignUsers, {destroy}] = useCampaignUsers({
        params: useMemo(() => ({
            campaign: campaignId
        }), [campaignId])
    });
    const [showPopup, setShowPopup] = useState(false);
    const form = useForm<Submit>();
    const mobile = useBreakpoint(768);

    const withPopup = useConfirmPopup();
    const toast = useToaster();

    const handleSubmit: SubmitHandler<Submit> = async ({email}) => {
        await axios.post(route("campaigns.users.invite", {campaign: campaignId}), { email });
        toast({
            title: t("campaigns.settings.share.success.title"),
            text: t("campaigns.settings.share.success.text"),
            type: "success",
            duration: 5000
        });
        handleClosePopup();
    }

    const handleClosePopup = () => {
        form.reset();
        setShowPopup(false);
    }

    const handleRemoveUser = (id: number) => () => {
        withPopup(
            "warning",
            t("remove"),
            t("campaigns.settings.share.remove.title"),
            t("campaigns.settings.share.remove.text"),
            async () => {
                await destroy(id);
            }
        )
    }

    return (
        <Column gap="30px" maxWidth="800px" width="100%">
            <Column gap="40px" padding={mobile ? "35px 16px 0 16px" : "35px 50px 0 50px"}>
                <p className="input-label">{t("campaigns.settings.share.title")}</p>
                <Row>
                    <Button variant="primary" onClick={() => setShowPopup(true)}>{t("campaigns.settings.share.add")}</Button>
                </Row>
            </Column>
            <Column padding={mobile ? "0 0 35px 0" : "0px 50px 35px 50px"}>
                <Table
                    head={[{
                        label: t("campaigns.settings.share.table.name"),
                    }, {
                        label: t("campaigns.settings.share.table.email"),
                        fillWidth: true
                    }, {
                        label: t("campaigns.settings.share.table.action")
                    }]}
                    rows={campaignUsers.map(({id, first_name, last_name, email}) => ({
                        id,
                        data: [
                            <strong>{first_name} {last_name}</strong>,
                            email,
                            <button type="button" onClick={handleRemoveUser(id as number)}>
                                <UserSlashIcon height="18px" /> 
                            </button>
                        ]
                    }))}
                    placeholder={t("campaigns.settings.share.table.placeholder")}
                />
            </Column>
            {showPopup && (
                <Popup onBackgroundClick={handleClosePopup} width="500px">
                    <Form form={form} onSubmit={handleSubmit}>
                        <Column padding="30px 25px" gap="30px">
                            <h1>{t("campaigns.settings.share.popup.title")}</h1>
                            <p>{t("campaigns.settings.share.popup.text")}</p>
                            <EmailInput name="email" options={{
                                required: t("input.email.required")
                            }} />
                        </Column>
                        <PopupFooter>
                            <Button variant="secondary" onClick={handleClosePopup}>{t("cancel")}</Button>
                            <Button variant="primary" submit>{t("add")}</Button>
                        </PopupFooter>
                    </Form>
                </Popup>
            )}
        </Column>
    )
}