import compare from "@enymo/comparison";
import useScreenSize from "@enymo/react-screen-size-hook";
import { useMemo } from "react";

export default function useBreakpoint(breakpoint: number): boolean;
export default function useBreakpoint<T extends string>(breakpoint: Record<T, number>, dependencies?: React.DependencyList): T | null;
export default function useBreakpoint<T extends string>(breakpoint: number | Record<T, number>, dependencies: React.DependencyList = []) {
    const {width} = useScreenSize();
    return useMemo(() => {
        if (typeof breakpoint === "object") {
            return Object.entries<number>(breakpoint)
                .sort(([, a], [, b]) => compare(a,b))
                .find(([, value]) => width < value)?.[0] ?? null;
        }
        return width < breakpoint;
    }, [width, ...dependencies])
    
}