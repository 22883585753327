import "@/css/components/hashtag-input-container.scss";
import { Row } from "@enymo/react-layout";
import { ReturnList } from "@enymo/react-resource-hook";
import classNames from "classnames";
import React, { useRef, useState } from "react";
import { useNavigate } from "react-router";
import { Hashtag as HashtagInterface } from "../resources";
import Hashtag from "./Hashtag";
import Loader from "./Loader";

export default function HashtagInputContainer({
    disabled,
    hashtags: [hashtags, { store, destroy }],
}: {
    hashtags: [HashtagInterface[], ReturnList<HashtagInterface, HashtagInterface, null>]
    disabled?: boolean,
}) {
    const navigate = useNavigate();

    const [inputValue, setInputValue] = useState("");

    const [hashtagStoreLoading, setHashtagStoreLoading] = useState(false);
    
    const inputRef = useRef<HTMLInputElement>(null);

    const handleCreateHashtag = async () => {
        if (disabled) return;
        setHashtagStoreLoading(true);
        setInputValue("");
        if (hashtags.some(hashtag => hashtag.name === inputValue)) {
            setHashtagStoreLoading(false);
            return;
        }
        await store({ name: inputValue }, 'immediate');
        setHashtagStoreLoading(false);
    }

    const [deletingHashtagId, setDeletingHashtagId] = useState<number | null>(null);
    const [deleteHashtagLoading, setDeleteHashtagLoading] = useState(false);    

    const handleInputKeyDown = async (e: React.KeyboardEvent<HTMLInputElement>) => {
        switch (e.key) {
            case "Enter":
                if (inputValue.trim() === "") return;
                handleCreateHashtag();
                break;
            case "Backspace":
                if (inputValue === "") {
                    if (deletingHashtagId !== null) {
                        setDeleteHashtagLoading(true);
                        await destroy(deletingHashtagId);
                        setDeletingHashtagId(null);
                        setDeleteHashtagLoading(false);
                    }
                    else if (hashtags.length > 0) {
                        setDeletingHashtagId(hashtags[hashtags.length - 1].id);
                    }
                }
                break;
            default:
                setDeletingHashtagId(null);
        }
    }

    const handleSetInputValue = (value: string) => {
        if (value.match(/^[a-zA-Z0-9_]*$/)) {
            setInputValue(value);
        }
    }

    return (
        <div 
            className={classNames("hashtag-input-container", { disabled })} 
            onClick={() => inputRef.current?.focus()}
        >
            <div className="hashtag-list">
                {hashtags?.map(hashtag => (
                    <Hashtag
                        key={hashtag.id ?? "new"}
                        hashtag={hashtag}
                        onDelete={(() => destroy(hashtag.id))}
                        deleteLoading={(deleteHashtagLoading && deletingHashtagId === hashtag.id) || undefined}
                        showDelete={deletingHashtagId === hashtag.id}
                        onClick={() => navigate(`/app/hashtags/research?searchTerm=${hashtag.name}`)}
                    />
                ))}
                <Row gap="5px" className="input-wrapper" vAlign="center">
                    {hashtagStoreLoading && <Loader />}
                    {!disabled && <input
                        ref={inputRef}
                        value={inputValue}
                        onChange={(e) => handleSetInputValue(e.target.value)}
                        onKeyDown={handleInputKeyDown}
                    />}
                </Row>
            </div>
        </div>
    )
}