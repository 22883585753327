import "@/css/components/popup.scss";
import { Column, ColumnProps, Row, RowProps } from "@enymo/react-layout";
import classNames from "classnames";
import React, { useCallback } from "react";

interface PopupProps extends ColumnProps {
    onBackgroundClick?(): void
}

export default function Popup({
    onBackgroundClick,
    ...props
}: PopupProps) {
    const handleBackgroundClick: React.MouseEventHandler = useCallback(e => {
        if (e.target === e.currentTarget) {
            onBackgroundClick?.();
        }
    }, [onBackgroundClick]);

    return (
        <div className="popup" onClick={handleBackgroundClick}>
            <div>
                <Column {...props} />
            </div>
        </div>
    )
}

export function PopupFooter({className, align = "end", vAlign = "center", gap="35px", padding="20px 25px", ...props}: RowProps) {
    return <Row className={classNames("popup-footer", className)} {...{align, gap, padding, vAlign}} {...props} />
}