import FileInput, { FileInputRef } from "@/js/Components/FileInput";
import Popup, { PopupFooter } from "@/js/Components/Popup";
import { UserAvatar } from "@/js/Components/UserAvatar";
import { useUser } from "@/js/Providers/UserProvider";
import { getTimeZoneI18nKey, languages } from "@/js/common";
import { Button, Checkbox, EmailInput, Input, PasswordInput } from "@/js/glidespec";
import CircleInfoIcon from "@/svg/circle-info-duotone.svg?react";
import TrashIcon from "@/svg/trash-regular.svg?react";
import ExclamationTriangleIcon from "@/svg/triangle-exclamation-duotone.svg?react";
import { Form, SubmitHandler } from "@enymo/react-form-component";
import { Column, Row } from "@enymo/react-layout";
import { assertNotNull } from "@enymo/ts-nullsafe";
import React, { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";

interface SettingsSubmit {
    first_name: string,
    last_name: string,
    email: string,
    language: typeof languages[number],
    timezone: string
}

interface CloseAccountSubmit {
    password: string
}

export default function General() {
    const {t} = useTranslation();
    
    const { user, setAvatar, update, deleteAccount } = useUser();
    assertNotNull(user, "User must be logged in");

    const ref = useRef<FileInputRef>(null);
    const [uploading, setUploading] = useState(false);
    const [showDeletePopup, setShowDeletePopup] = useState(false);
    const [showUpdateEmailPopup, setShowUpdateEmailPopup] = useState<string | null>(null);
    const settingsForm = useForm<SettingsSubmit>({
        values: {
            first_name: user.first_name,
            last_name: user.last_name,
            email: user.email,
            language: user.language,
            timezone: user.timezone
        }
    });
    const closeAccountForm = useForm<CloseAccountSubmit>();

    const handleUploadAvatar = async ([file]: File[]) => {
        setUploading(true);
        await setAvatar(file);
        setUploading(false);
    }

    const handleDeleteAvatar = async () => {
        await setAvatar(null);
    }

    const handleSubmit: SubmitHandler<SettingsSubmit> = async data => {
        await update(data);
        if (data.email !== user.email) {
            setShowUpdateEmailPopup(data.email);
            settingsForm.setValue("email", user.email);
        }
    }

    const handleResendEmail = async () => {
        assertNotNull(showUpdateEmailPopup, "Resend email can only be called when popup is open");
        await update({
            email: showUpdateEmailPopup
        });
    }

    const handleCloseAccount: SubmitHandler<CloseAccountSubmit> = async ({password}) => {
        await deleteAccount(password);
    }

    return (
        <Column gap="50px">
            <Column gap="30px">
                <h2>{t("settings.general.avatar")}</h2>
                <Row gap="20px" vAlign="center">
                    <UserAvatar size={100} />
                    <Column gap="14px" hAlign="left">
                        <Button variant="primarySmall" loading={uploading} onClick={() => ref.current?.open()}>{t("settings.general.avatar.upload")}</Button>
                        {user.has_avatar && (
                            <Button variant="dangerText" onClick={handleDeleteAvatar}>
                                <TrashIcon width="12px" />
                                <span>{t("settings.general.avatar.delete")}</span>
                            </Button>
                        )}
                    </Column>
                </Row>
            </Column>
            <Form form={settingsForm} onSubmit={handleSubmit}>
                <Column gap="50px" maxWidth="450px" width="100%">
                    <Column gap="30px">
                        <h2>{t("settings.general.personalData")}</h2>
                        <Column gap="20px">
                            <Row gap="10px">
                                <Input name="first_name" label={t("input.firstName")} options={{
                                    required: t("input.firstName.required")
                                }} />
                                <Input name="last_name" label={t("input.lastName")} options={{
                                    required: t("input.lastName.required")
                                }} />
                            </Row>
                            <EmailInput options={{
                                required: t("input.email.required")
                            }} />
                        </Column>
                    </Column>
                    <Column gap="30px">
                        <h2>{t("settings.general.additional")}</h2>
                        <Column gap="20px">
                            <Input name="language" label={t("input.language")} type="select">
                                <option value="de">{t("language.de")}</option>
                                <option value="en">{t("language.en")}</option>
                            </Input>
                            <Input name="timezone" label={t("input.timezone")} type="select">
                                {Intl.supportedValuesOf("timeZone").map(timezone => (
                                    <option key={timezone} value={timezone}>{t(getTimeZoneI18nKey(timezone))}</option>
                                ))}
                            </Input>
                        </Column>
                    </Column>
                    <Row align="space" vAlign="bottom">
                        <Button variant="dangerText" onClick={() => setShowDeletePopup(true)}>{t("settings.general.closeAccount")}</Button>
                        <Button variant="primary" submit>{t("save")}</Button>
                    </Row>
                </Column>
            </Form>
            <FileInput ref={ref} onSelected={handleUploadAvatar} accept="image/png,image/jpeg" />
            {showDeletePopup && (
                <Popup onBackgroundClick={() => setShowDeletePopup(false)} width="500px">
                    <Form form={closeAccountForm} onSubmit={handleCloseAccount}>
                        <Column gap="30px" padding="30px 25px 40px 25px">
                            <Row gap="15px" vAlign="center">
                                <ExclamationTriangleIcon width="40px" height="35px" className="warning" />
                                <h1>{t("settings.general.closeAccount.title")}</h1>
                            </Row>
                            <p>{t("settings.general.closeAccount.text")}</p>
                            {user.subscription_tier !== "free" && (
                                <Column gap="20px">
                                    <p>{t("settings.general.closeAccount.cancelSubscription")}</p>
                                    <Checkbox name="cancel_subscription" options={{
                                        required: t("settings.general.closeAccount.cancelSubscription.checkbox.required")
                                    }}>{t("settings.general.closeAccount.cancelSubscription.checkbox")}</Checkbox>
                                </Column>
                            )}
                            {user.has_password && (
                                <PasswordInput name="password" label={t("settings.general.closeAccount.password")} options={{
                                    required: t("settings.general.closeAccount.password.required")
                                }} />
                            )}
                        </Column>
                        <PopupFooter height="80px" vAlign="center" align="end" gap="35px" padding="0 25px">
                            <Button variant="secondary" onClick={() => setShowDeletePopup(false)}>{t("cancel")}</Button>
                            <Button variant="danger" submit>{t("settings.general.closeAccount.button")}</Button>
                        </PopupFooter>
                    </Form>
                </Popup>
            )}
            {showUpdateEmailPopup !== null && (
                <Popup onBackgroundClick={() => setShowUpdateEmailPopup(null)} width="500px">
                    <Column gap="30px" padding="30px 25px 40px 25px">
                        <Row gap="15px" vAlign="center">
                            <CircleInfoIcon className="primary-500" width="35px" height="35px" />
                            <h1>{t("settings.general.updateEmail.title")}</h1>
                        </Row>
                        <p>{t("settings.general.updateEmail.textLineOne")}</p>
                        <p className="update-email-again">
                            <Trans i18nKey="settings.general.updateEmail.textLineTwo">
                                Didn't get the email? <Button variant="link" onClick={handleResendEmail}>Send again</Button>
                            </Trans>
                        </p>
                    </Column>
                    <PopupFooter height="80px" vAlign="center" align="end" padding="0 25px">
                        <Button variant="primary" onClick={() => setShowUpdateEmailPopup(null)}>{t("settings.general.updateEmail.button")}</Button>
                    </PopupFooter>
                </Popup>
            )}
        </Column>
    )
}