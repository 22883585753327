import "@/css/components/period-select.scss";
import FilterIcon from "@/svg/filter-regular.svg?react";
import useOnClickOutside from "@enymo/react-click-outside-hook";
import { Row } from "@enymo/react-layout";
import classNames from "classnames";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { sameDate, toISOStringWithTimezone } from "../common";
import { Input, PeriodRadio, RadioGroup } from "../glidespec";

const today = new Date();
today.setHours(0, 0, 0, 0);

const setDateState = (setState: (value: Date) => void) => (value: string) => {
    const date = new Date(value);
    date.setHours(0, 0, 0, 0);
    setState(date);
}

export default function PeriodSelect({
    periods,
    since,
    until,
    onChangeSince,
    onChangeUntil,
    mobile = false
}: {
    periods: readonly number[],
    since: Date,
    until: Date,
    onChangeSince(since: Date): void,
    onChangeUntil(until: Date): void,
    mobile?: boolean
}) {
    const {t} = useTranslation();
    const [open, setOpen] = useState(false);
    const ref = useOnClickOutside<HTMLDivElement>(() => setOpen(false), [setOpen]);

    const currentPeriod = useMemo(() => sameDate(until, today) ? Math.floor((until.getTime() - since.getTime()) / (24 * 60 * 60 * 1000)) : undefined, [since, until]);
    const handleSetPeriod = (period: number) => {
        onChangeUntil(today);
        const date = new Date(today);
        date.setTime(date.getTime() - period * 24 * 60 * 60 * 1000);
        onChangeSince(date);
    }

    if (mobile) {
        return (
            <div ref={ref} className={classNames("period-drawer", {open})}>
                <button type="button" onClick={() => setOpen(!open)}>
                    <FilterIcon />
                </button>
                <div>
                    <RadioGroup value={currentPeriod} onChange={handleSetPeriod}>
                        {periods.map(period => (
                            <PeriodRadio key={period} value={period}>{t("period", {count: period})}</PeriodRadio>
                        ))}
                    </RadioGroup>
                    <Row gap="10px" vAlign="center">
                        <Input type="date" value={toISOStringWithTimezone(since, true)!} onChange={setDateState(onChangeSince)} />
                        -
                        <Input type="date" value={toISOStringWithTimezone(until, true)!} onChange={setDateState(onChangeUntil)} />
                    </Row>
                </div>
            </div>
        )
    }
    else {
        return (
            <Row gap="22px">
                <Row gap="12px">
                    <RadioGroup value={currentPeriod} onChange={handleSetPeriod}>
                        {periods.map(period => (
                            <PeriodRadio key={period} value={period}>{t("period", {count: period})}</PeriodRadio>
                        ))}
                    </RadioGroup>
                </Row>
                <Row gap="10px" vAlign="center">
                    <Input type="date" value={toISOStringWithTimezone(since, true)!} onChange={setDateState(onChangeSince)} />
                    -
                    <Input type="date" value={toISOStringWithTimezone(until, true)!} onChange={setDateState(onChangeUntil)} />
                </Row>
            </Row>
        )
    }
}