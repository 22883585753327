import Loader from "@/js/Components/Loader";
import TextComponent, { LoadingTextComponent } from "@/js/Components/Post/TextComponent";
import { usePostParams } from "@/js/Pages/Main/Campaigns/Posts/Post";
import { useApp } from "@/js/Providers/AppProvider";
import { useToaster } from "@/js/Providers/ToasterProvider";
import { deleteLinkedListItem, resourceDragAndDropSorting, sortLinkedList, variableSizeItemsCollisionDetection } from "@/js/common";
import { Hashtag as HashtagInterface, PostText, useCampaigns, useSocialChannels } from "@/js/resources";
import { SocialAccountType } from "@/js/types";
import InfoIcon from "@/svg/circle-info-solid.svg?react";
import { DndContext, DragEndEvent } from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { createRequiredContext } from "@enymo/react-better-context";
import { Column, Row } from "@enymo/react-layout";
import { ReturnList } from "@enymo/react-resource-hook";
import { assertNotNull } from "@enymo/ts-nullsafe";
import { AxiosError } from "axios";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import "react-quill/dist/quill.snow.css";
import { useParams } from "react-router";
import route from "ziggy-js";
import { FoldSwitcher } from "../FoldSwitcher";
import Tooltip, { TooltipParent } from "../Tooltip";


const [TextParamsProvider, useTextParams] = createRequiredContext<{
    maximizedId: [number | null, React.Dispatch<React.SetStateAction<number | null>>];
}>("TextParamsProvider must be in the component tree.");

export { useTextParams };

export default function TextsColumn({
    texts: [texts, { store, update, destroy, loading }],
    textCount,
}: {
    texts: [PostText[], ReturnList<PostText, PostText, null>],
    textCount?: number,
}) {
    const { t } = useTranslation();
    const { axios } = useApp();
    const toast = useToaster();
    const [folded, setFolded] = useState(false);
    const sortedTexts = useMemo(() => sortLinkedList(texts), [texts]);
    const {campaignId} = useParams();
    assertNotNull(campaignId, "Campaign id is required");
    const [campaign] = useCampaigns({id: Number(campaignId)});
    const [socialChannels] = useSocialChannels();
    const socialChannelIds = useMemo(() => new Set(campaign?.active_social_channels), [campaign]);
    const filteredSocialChannels = useMemo(() => {
        const channels = new Set<SocialAccountType>();
        return socialChannels.filter(({id, icon_type}) => {
            if (socialChannelIds.has(id) && !channels.has(icon_type)) {
                channels.add(icon_type);
                return true;
            }
            return false;
        });
    }, [socialChannelIds, socialChannels]);


    const [maximizedTextId, setMaximizedTextId] = useState<number | null>(null);

    const {
        textStoreLoading: [textStoreLoading, setTextStoreLoading],
        screenSize,
        textLimitReached,
    } = usePostParams();

    const textsDragAndDropSorter = useCallback(resourceDragAndDropSorting(texts, update), [texts, update]);

    const handleSortingChanged = (event: DragEndEvent) => {
        event.over && event.over.id !== event.active.id && textsDragAndDropSorter(event.active.id, event.over?.id, event.delta.y > 0 ? "down" : "up");
    }

    const handleNewText = async () => {
        setTextStoreLoading(true);
        const id = (await store({ text: "" }))?.id;
        setTextStoreLoading(false);
        setMaximizedTextId(id ?? null);
    }

    const handleTextChange = (id: number) => async (text: string) => {
        await update(id, { text }, 'immediate');
    }

    const handleYoutubeTitleChange = (id: number) => async (title: string) => {
        await update(id, { title: title }, 'immediate');
    }

    const handleHashtagsChange = (id: number) => async (hashtags: HashtagInterface[]) => {
        const postText = texts.find(({ id: textId }) => textId === id);
        if (!postText) return;
        await update(id, { hashtags: hashtags }, 'immediate');
    }

    const handleTextDelete = (id: number) => async () => {
        await deleteLinkedListItem(texts, id, update, destroy);
    }

    const handleCloneRephrase = async (text: PostText) => {
        // Limit reached checked when button is clicked
        try {
            const response = await axios.post<PostText>(route("posts.texts.clone-rephrase", {
                post: text.post_id,
                postText: text.id,
            }));
            store(response.data, 'local-only');
            setMaximizedTextId(response.data.id);
        }
        catch (error) {
            if (error instanceof AxiosError && (error.response?.status === 400 || (error.response?.status === 500 && error.response.data.code !== undefined))) {
                toast({
                    type: "error",
                    text: t(error.response.data.code),
                })
            }
            else {
                throw error;
            }
        }

    }

    useEffect(() => {
        if (screenSize !== "mobile") {
            setFolded(false);
        }
    }, [screenSize, setFolded]);

    return (
        <DndContext
            onDragEnd={handleSortingChanged}
            modifiers={[restrictToVerticalAxis]}
            collisionDetection={variableSizeItemsCollisionDetection}
        >
            <Column className="texts" gap="18px" flex={["desktop", "wide-desktop"].includes(screenSize) ? 1 : undefined}>
                <Row className="underline-title" vAlign="center" gap="10px">
                    <h3>{t("posts.create.texts")}</h3>
                    {screenSize !== "mobile" ? (
                        <TooltipParent className="note">
                            <InfoIcon />
                            <Tooltip>{t("posts.create.texts.note")}</Tooltip>
                        </TooltipParent>
                    ) : (
                        <FoldSwitcher folded={folded} setFolded={setFolded} />
                    )}
                </Row>
                {!folded && (
                    <Column gap="12px">
                        {loading ? (
                            <Column gap="12px">
                                {Array.from({ length: textCount ?? 0 }).map((_, index) => (
                                    <LoadingTextComponent key={index} />
                                ))}
                            </Column>
                        ) : (
                            <SortableContext items={sortedTexts} strategy={verticalListSortingStrategy}>
                                <TextParamsProvider value={{
                                    maximizedId: [maximizedTextId, setMaximizedTextId],
                                }}>
                                    <Column gap="12px">
                                        {sortedTexts.map((text, index) => {
                                            const textComponent = (
                                                <TextComponent
                                                    key={text.id}
                                                    text={text}
                                                    onTextChange={handleTextChange(text.id)}
                                                    onTitleChange={handleYoutubeTitleChange(text.id)}
                                                    onHashtagsChange={handleHashtagsChange(text.id)}
                                                    onDelete={texts.length > 1 ? handleTextDelete(text.id) : undefined}
                                                    onCloneRephrase={() => handleCloneRephrase(text)}
                                                    channels={filteredSocialChannels}
                                                />
                                            );
                                            return (
                                                index === 0 || index === 1 ? (
                                                    <Column gap="8px" key={text.id} >
                                                        <span className="next-post-note">
                                                            {t(`posts.create.nextPost.${index}`)}
                                                        </span>
                                                        {textComponent}
                                                    </Column>
                                                ) : (
                                                    textComponent
                                                )
                                            )
                                        })}
                                    </Column>
                                </TextParamsProvider>
                            </SortableContext>
                        )}
                        <Row gap="5px" className="new-text-wrapper" vAlign="center">
                            {textStoreLoading && <Loader />}
                            <TooltipParent disabled={!textLimitReached}>
                                <button onClick={handleNewText} disabled={textStoreLoading || textLimitReached}>
                                    {t("posts.create.texts.addText")}
                                </button>
                                <Tooltip>
                                    {t("posts.create.texts.limitReached")}
                                </Tooltip>
                            </TooltipParent>
                        </Row>
                    </Column>
                )}
            </Column>
        </DndContext>
    )
}