import "@/css/components/error.scss";
import CircleInfoIcon from "@/svg/circle-info-solid.svg?react";
import React from "react";

export default function Error({error}: {
    error: React.ReactNode
}) {
    return (
        <div className="input-error">
            <CircleInfoIcon />
            <span>{error}</span>
        </div>
    )
}