import "@/css/components/header.scss";
import { Column, Row } from "@enymo/react-layout";
import { assertNotNull, isNotNull } from "@enymo/ts-nullsafe";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import useBreakpoint from "../Hooks/BreakpointHook";
import { useConfirmPopup } from "../Providers/ConfirmPopupProvider";
import { useUser } from "../Providers/UserProvider";
import { DateFormat, formatDate } from "../common";
import { BackButton } from "../glidespec";
import { useCampaigns, useSocialChannels, useUsers } from "../resources";
import { ReactElements } from "../types";
import ActiveSwitch from "./ActiveSwitch";
import DropdownButton, { DropdownButtonItem, DropdownButtonItemProps } from "./DropdownButton";
import SocialChannelInfo from "./SocialChannelInfo";
import Tooltip, { TooltipParent } from "./Tooltip";

export function HeaderItem({
    title,
    children
}: {
    title: React.ReactNode,
    children: React.ReactNode
}) {
    return (
        <Column height="50px" align="space">
            <span className="item-title">{title}</span>
            <span className="item-body">{children}</span>
        </Column>
    )
}

export function Header({
    active,
    onChangeActive,
    backTo,
    title,
    dropdownButton,
    switcherDisabled = false,
    children
}: {
    active: boolean,
    onChangeActive?(active: boolean): void,
    backTo: string,
    title: string,
    dropdownButton?: ReactElements<DropdownButtonItemProps>,
    switcherDisabled?: string | boolean,
    children?: React.ReactNode
}) {
    const {t} = useTranslation();

    return (
        <div className="header">
            <Row align="space">
                <BackButton to={backTo}>{t("back")}</BackButton>
                <Row vAlign="top" gap="10px">
                    <TooltipParent>
                        <ActiveSwitch value={active} onChange={onChangeActive} disabled={!!switcherDisabled} />
                        {switcherDisabled && typeof switcherDisabled === "string" && (
                            <Tooltip>{switcherDisabled}</Tooltip>
                        )}
                    </TooltipParent>
                    {dropdownButton && (
                        <DropdownButton>{dropdownButton}</DropdownButton>
                    )}
                </Row>
            </Row>
            <div className="title-wrap">
                <span className="title">{title}</span>
                <div className="info">
                    {children}
                </div>
            </div>
        </div>
    )
}

export default function CampaignHeader({campaignId, postSettings = false}: {
    campaignId: number,
    postSettings?: boolean
}) {
    const {t} = useTranslation();
    const [campaigns, {update, destroy}] = useCampaigns();
    const campaign = useMemo(() => campaigns.find(({id}) => id === campaignId)!, [campaigns, campaignId])
    const {user} = useUser();
    assertNotNull(user, "User must be logged in");
    const [campaignOwner] = useUsers({
        id: campaign?.owner.id ?? null,
        autoRefresh: campaign !== null
    });
    const canActivate = useMemo(() => campaignOwner && (campaignOwner.can_activate_campaigns - campaigns.reduce((sum, campaign) => campaign.owner.id === campaignOwner.id && campaign.active ? sum + 1 : sum, 0)) > 0, [campaignOwner, campaigns]);
    const [channels] = useSocialChannels();
    const activeChannels = useMemo(() => channels.filter(({id}) => campaign?.active_social_channels.includes(id)), [channels, campaign]);
    const hasDates = isNotNull(campaign?.start_date) || isNotNull(campaign?.end_date);

    const mobile = useBreakpoint(768);

    const withPopup = useConfirmPopup();
    const navigate = useNavigate();

    const handleDeleteCampaign = () => {
        withPopup("warning", t("campaigns.delete.button"), t("campaigns.delete.title"), t("campaigns.delete.text"), async () => {
            await destroy(campaignId);
            navigate("/app/campaigns");
        });
    }

    return (
        <Header
            active={campaign?.active ?? false} 
            onChangeActive={active => update(campaignId, {active}, "immediate")}
            backTo="/app/campaigns"
            title={campaign?.name ?? ""}
            dropdownButton={<>
                {postSettings ? (
                    <DropdownButtonItem to={`/app/campaigns/${campaignId}/settings`}>{t("campaigns.settings")}</DropdownButtonItem>
                ) : (
                    <DropdownButtonItem to={`/app/campaigns/${campaignId}/posts`}>{t("campaigns.managePosts")}</DropdownButtonItem>
                )}
                <DropdownButtonItem variant="danger" onClick={handleDeleteCampaign}>{t("campaigns.delete")}</DropdownButtonItem>
            </>}
            switcherDisabled={campaignOwner && campaign ? (!campaign.active && !canActivate ? (campaignOwner.id === user.id ? t("planExceeded.owner") : t("planExceeded.user")) : false) : true}
        >
            {mobile ? (
                <Row align={hasDates ? "space" : "end"} flex={1}>
                    {hasDates && <span>{formatDate(campaign?.start_date, DateFormat.DATE) ?? t("campaigns.startDate.undefined")} - {formatDate(campaign?.end_date, DateFormat.DATE) ?? t("campaigns.endDate.undefined")}</span>}
                    <SocialChannelInfo channels={activeChannels} maxDisplay={5} />
                </Row>
            ) : <>
                <HeaderItem title={t("campaigns.settings.socialAccounts")}>
                    <SocialChannelInfo channels={activeChannels} maxDisplay={5} />
                </HeaderItem>
                <HeaderItem title={t("campaigns.startDate")}>
                    {formatDate(campaign?.start_date, DateFormat.DATE) ?? "-"}
                </HeaderItem>
                <HeaderItem title={t("campaigns.endDate")}>
                    {formatDate(campaign?.end_date, DateFormat.DATE) ?? "-"}
                </HeaderItem>
            </>}
        </Header>
    )
}