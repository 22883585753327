import "@/css/pages/main/calendar.scss";
import AvatarWithLogo from "@/js/Components/AvatarWithLogo";
import { FoldSwitcher } from "@/js/Components/FoldSwitcher";
import useBreakpoint from "@/js/Hooks/BreakpointHook";
import { useTitle } from "@/js/Layouts/Main";
import { calendarInfo, channelAvatar, getDay, initials, months, toISOStringWithTimezone, weekdays } from "@/js/common";
import { Input } from "@/js/glidespec";
import CalendarIcon from "@/svg/calendar-days-regular.svg?react";
import ChevronLeftIcon from "@/svg/chevron-left-regular.svg?react";
import ChevronRightIcon from "@/svg/chevron-right-regular.svg?react";
import { Column, Row } from "@enymo/react-layout";
import { assertNotNull, requireNotNull } from "@enymo/ts-nullsafe";
import classNames from "classnames";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Schedule, useCampaigns, useSchedule, useSocialChannels } from "../../resources";

export default function Calendar() {
    const { t } = useTranslation();
    useTitle(t("calendar"));
    const isMobile = useBreakpoint(768);

    const [channels] = useSocialChannels();
    const [selectedChannels, setSelectedChannels] = useState<(number | null)[]>(() => Array(5).fill(null));
    const campaignsToShow = useMemo(
        () => {
            const result: { [campaignId: number]: number[] } = {}
            for (const channel of channels) {
                const index = selectedChannels.indexOf(channel.id);
                if (index !== -1) {
                    for (const campaign of requireNotNull(channel.campaigns)) {
                        result[campaign.id] ??= [];
                        result[campaign.id].push(index);
                    }
                }
            }
            return result;
        },
        [channels, selectedChannels]
    )
    const [channelsFolded, setChannelsFolded] = useState(false);

    const [campaigns] = useCampaigns();
    const campaignsById = useMemo(() => Object.fromEntries(campaigns.map(campaign => [campaign.id, campaign])), [campaigns]);
    const [mode, setMode] = useState<"month" | "week">("week");
    const [date, setDate] = useState(() => new Date());
    const [schedule, { loading }] = useSchedule({
        params: useMemo(() => ({
            mode,
            date: toISOStringWithTimezone(date)!
        }), [mode, date])
    });
    const mappedSchedule = useMemo(() => {
        const result: { [key: number]: Schedule[] } = {};
        for (const entry of schedule) {
            if (entry.campaign_id in campaignsToShow && !result[entry.date.getDate()]?.some(({ campaign_id }) => campaign_id === entry.campaign_id)) {
                result[entry.date.getDate()] ??= [];
                result[entry.date.getDate()].push(entry);
            }
        }
        return result;
    }, [schedule, campaignsToShow]);
    const weekStart = useMemo(() => {
        const copy = new Date(date);
        copy.setDate(copy.getDate() - getDay(copy));
        return copy;
    }, [date]);
    const weekEnd = useMemo(() => {
        const copy = new Date(date);
        copy.setDate(copy.getDate() + 6 - getDay(copy));
        return copy;
    }, [date]);
    const {
        prevMonthDays,
        prevMonthPartialWeek,
        currentMonthDays,
        currentMonthPartialWeek,
        dayToWeekday
    } = useMemo(() => calendarInfo(date), [date]);

    const handleToggleChannel = (id: number) => () => {
        if (!selectedChannels.includes(id)) {
            const nullIndex = selectedChannels.indexOf(null);
            if (nullIndex !== -1) {
                setSelectedChannels(selectedChannels.toSpliced(nullIndex, 1, id))
            }
        }
        else {
            setSelectedChannels(selectedChannels.map(value => value === id ? null : value));
        }
    }

    const handlePrevious = () => {
        const copy = new Date(date);
        if (mode === "month") {
            copy.setMonth(copy.getMonth() - 1);
        }
        else {
            copy.setDate(copy.getDate() - 7);
        }
        setDate(copy);
    }

    const handleNext = () => {
        const copy = new Date(date);
        if (mode === "month") {
            copy.setMonth(copy.getMonth() + 1);
        }
        else {
            copy.setDate(copy.getDate() + 7);
        }
        setDate(copy);
    }

    return (
        <div className="calendar-page">
            <Column gap={isMobile ? "15px" : "30px"} padding={isMobile ? "0 16px" : undefined}>
                <Row align="space">
                    <h2>{t("socialAccounts")}</h2>
                    {isMobile && <FoldSwitcher folded={channelsFolded} setFolded={setChannelsFolded} />}
                </Row>
                {!channelsFolded ? (
                    <div className="channels">
                        {channels.map(({ id, name, has_avatar, icon_type }) => {
                            assertNotNull(id);
                            const selectionIndex = selectedChannels.indexOf(id);
                            return (
                                <button type="button" key={id} className={classNames("channel", selectionIndex !== -1 ? `selected-${selectionIndex + 1}` : undefined)} onClick={handleToggleChannel(id)}>
                                    <AvatarWithLogo type={icon_type} size={32} src={channelAvatar({ id, has_avatar })} initials={initials(name)} />
                                    <span>{name}</span>
                                </button>
                            )
                        })}
                    </div>
                ) : (
                    <div style={{ height: "40px" }} />
                )}
            </Column>
            <Column flex={isMobile ? 1 : undefined}>
                <Row align="space" className="filter">
                    <Input type="select" value={mode} onChange={setMode as any}>
                        <option value="month">{t("calendar.mode.month")}</option>
                        <option value="week">{t("calendar.mode.week")}</option>
                    </Input>
                    <Row gap="24px" vAlign="center">
                        <div className="calendar-date">
                            <CalendarIcon />
                            <span>
                                {mode === "month" ? t(`calendar.month`, {
                                    month: t(`months.${months[date.getMonth()]}`),
                                    year: date.getFullYear()
                                }) : t(`calendar.week`, {
                                    startDate: weekStart.getDate(),
                                    startMonth: t(`months.${months[weekStart.getMonth()]}.short`),
                                    endDate: weekEnd.getDate(),
                                    endMonth: t(`months.${months[weekEnd.getMonth()]}.short`)
                                })}
                            </span>
                        </div>
                        <div className="navigation">
                            <button type="button" onClick={handlePrevious}>
                                <ChevronLeftIcon />
                            </button>
                            <button type="button" onClick={handleNext}>
                                <ChevronRightIcon />
                            </button>
                        </div>
                    </Row>
                </Row>
                <div className="calendar-wrapper">
                    <div className="calendar">
                        {!isMobile && weekdays.map(weekday => (
                            <div key={weekday} className="head">{t(`weekdays.${weekday}`)}</div>
                        ))}
                        {isMobile ? (<>
                            {Array<void>(mode === "month" ? currentMonthDays : 7).fill().map((_, index) => {
                                const copy = new Date(weekStart);
                                copy.setDate(copy.getDate() + index);
                                const dayIndex = mode === "week" ? copy.getDate() : index + 1;
                                return (
                                    <div key={index} className="item">
                                        <Column gap="1px">
                                            <span className="dayname">{t(`weekdays.${weekdays[mode === "month" ? dayToWeekday.get(index + 1)! : getDay(copy)]}.short`)}</span>
                                            <span className="daynumber">{dayIndex}</span>
                                        </Column>
                                        {!loading && (
                                            <Column gap="3px" className={classNames("day-container", { "no-items": !mappedSchedule[dayIndex] })} flex={1}>
                                                {mappedSchedule[dayIndex]?.map(({ campaign_id }) => (
                                                    campaignsToShow[campaign_id].map(selectionIndex => (
                                                        <Link key={selectionIndex} to={`/app/campaigns/${campaign_id}/posts`} className={`campaign-item selected-${selectionIndex + 1}`}>{campaignsById[campaign_id].name}</Link>
                                                    ))
                                                )) ?? t("calendar.noCampaigns")}
                                            </Column>
                                        )}
                                    </div>
                                )
                            })}
                        </>) : (
                            mode === "month" ? <>
                                {Array<void>(prevMonthPartialWeek).fill().map((_, index) => (
                                    <div key={index} className="item gray">{prevMonthDays - prevMonthPartialWeek + index + 1}</div>
                                ))}
                                {Array<void>(currentMonthDays).fill().map((_, index) => (
                                    <div key={index} className="item">
                                        {<span>{index + 1}</span>}
                                        {!loading && (
                                            <Column gap="2px">
                                                {mappedSchedule[index + 1]?.map(({ campaign_id }) => (
                                                    campaignsToShow[campaign_id].map(selectionIndex => (
                                                        <Link key={selectionIndex} to={`/app/campaigns/${campaign_id}/posts`} className={`campaign-item selected-${selectionIndex + 1}`}>{campaignsById[campaign_id].name}</Link>
                                                    ))
                                                ))}
                                            </Column>
                                        )}
                                    </div>
                                ))}
                                {Array<void>(currentMonthPartialWeek).fill().map((_, index) => (
                                    <div key={index} className="item gray">{index + 1}</div>
                                ))}
                            </> : Array<void>(7).fill().map((_, index) => {
                                const copy = new Date(weekStart);
                                copy.setDate(copy.getDate() + index);
                                return (
                                    <div key={index} className="item week">
                                        <span>{copy.getDate()}</span>
                                        {!loading && (
                                            <Column gap="2px">
                                                {mappedSchedule[copy.getDate()]?.map(({ campaign_id }) => (
                                                    campaignsToShow[campaign_id].map(selectionIndex => (
                                                        <Link to={`/app/campaigns/${campaign_id}/posts`} className={`campaign-item selected-${selectionIndex + 1}`}>{campaignsById[campaign_id].name}</Link>
                                                    ))
                                                ))}
                                            </Column>
                                        )}
                                    </div>
                                )
                            })
                        )}
                    </div>
                </div>
            </Column>
        </div>
    )
}