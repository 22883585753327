import Splash from "@/js/Components/Splash";
import { useUser } from "@/js/Providers/UserProvider";
import { Button, PasswordInput } from "@/js/glidespec";
import { Form, SubmitHandler } from "@enymo/react-form-component";
import { Column } from "@enymo/react-layout";
import React, { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Navigate, useSearchParams } from "react-router-dom";

interface Submit {
    password: string
}

export default function ResetPassword() {
    const {t} = useTranslation();
    const [searchParams] = useSearchParams();
    const {email, token} = useMemo(() => ({
        email: searchParams.get("email"),
        token: searchParams.get("token")
    }), [searchParams]);
    const {login} = useUser();
    const form = useForm<Submit>();

    const handleSubmit: SubmitHandler<Submit> = async ({password}) => {
        if (email !== null && token !== null) {
            await login(email, password, false, token);
        }
    }

    if (email === null || token === null) {
        return <Navigate to="/login" replace />
    }

    return (
        <Column gap="50px" maxWidth="480px" width="100%">
            <Splash title={t("resetPassword.title")}>{t("resetPassword.subtitle")}</Splash>
            <Form form={form} onSubmit={handleSubmit}>
                <Column gap="30px">
                    <PasswordInput className="large" name="password" label={t("signUp.password")} placeholder={t("signUp.password.placeholder")} options={{
                        required: t("signUp.password.required"),
                        minLength: {
                            value: 8,
                            message: t("signUp.password.minLength")
                        }
                    }} />
                    <Button variant="primaryLogin" submit>{t("resetPassword.submit")}</Button>
                </Column>
            </Form>
        </Column>
    )
}