import "@/css/components/text-limit.scss";
import classNames from "classnames";
import React from "react";
import { SocialAccountType } from "../types";
import SocialMediaIcon from "./SocialMediaIcon";

export default function TextLimit({type, limit, exceeded}: {
    type: SocialAccountType,
    limit: number,
    exceeded: boolean
}) {
    return (
        <div className={classNames("text-limit", {exceeded})}>
            <SocialMediaIcon type={type} />
            <span>{limit}</span>
        </div>
    )
}