import "@/css/components/alert.scss";
import TriangleExclamationIcon from "@/svg/triangle-exclamation-duotone.svg?react";
import { Column } from "@enymo/react-layout";
import React from "react";

export default function Alert({
    title,
    children
}: {
    title: React.ReactNode,
    children: React.ReactNode
}) {
    return (
        <div className="alert">
            <TriangleExclamationIcon />
            <Column flex={1} gap="6px">
                <span className="title">{title}</span>
                <p>{children}</p>
            </Column>
        </div>
    )
}