import { createRequiredContext } from "@enymo/react-better-context";
import { assertNotNull } from "@enymo/ts-nullsafe";
import React, { useState } from "react";
import { Outlet } from "react-router";
import { useUser } from "../Providers/UserProvider";
import { PaymentMethodProvider } from "../resources";

type PaymentSuccess = "successful-payment" | "successful-modification" | "failed" | null;

const [Provider, usePaymentSuccess] = createRequiredContext<[PaymentSuccess, (success: PaymentSuccess) => void]>("PaymentSuccess must be present in the component tree");

export { usePaymentSuccess };

export default function Subscription() {
    const { user } = useUser();
    assertNotNull(user);
    const paymentSuccess = useState<PaymentSuccess>(null);
    return (
        <PaymentMethodProvider>
            <Provider value={paymentSuccess}>
                <Outlet />
            </Provider>
        </PaymentMethodProvider>
    )
}