import "@/css/components/select.scss";
import ChevronDownIcon from "@/svg/chevron-down-regular.svg?react";
import useOnClickOutside from "@enymo/react-click-outside-hook";
import useHybridInput from "@enymo/react-hybrid-input-hook";
import classNames from "classnames";
import React, { useMemo, useState } from "react";
import { RegisterOptions } from "react-hook-form";

export interface SelectProps<T> {
    className?: string,
    name?: string,
    value?: T,
    onChange?(value: T): void,
    options?: RegisterOptions,
    choices: {
        key: React.Key,
        value: T,
        element: React.ReactNode
    }[],
    flex?: string | number,
    width?: string,
    maxWidth?: string
    minWidth?: string
}

export default function Select<T>({
    className,
    name,
    value: externalValue,
    onChange: externalOnChange,
    options,
    choices,
    ...style
}: SelectProps<T>) {
    const {value, onChange} = useHybridInput({name, externalValue, externalOnChange, options});
    const [open, setOpen] = useState(false);
    const selectedElement = useMemo(() => choices.find(choice => choice.value === value), [choices, value]);

    const ref = useOnClickOutside<HTMLDivElement>(() => setOpen(false), [setOpen]);

    const handleChange = (value: T) => () => {
        onChange(value);
        setOpen(false);
    }

    return (
        <div ref={ref} className={classNames(className, "select", {open})} style={style}>
            <button type="button" onClick={() => setOpen(!open)}>
                {selectedElement?.element}
                <ChevronDownIcon />
            </button>
            <div>
                {choices.map(({key, value, element}) => (
                    <button key={key} type="button" onClick={handleChange(value)}>{element}</button>
                ))}
            </div>
        </div>
    )
}