import "@/css/components/table-actions.scss";
import BanIcon from "@/svg/ban-regular.svg?react";
import GearIcon from "@/svg/gear-regular.svg?react";
import PenIcon from "@/svg/pen-regular.svg?react";
import TrashIcon from "@/svg/trash-regular.svg?react";
import { Clickable } from "@enymo/react-clickable-router";
import { Row } from "@enymo/react-layout";
import React from "react";

interface ClickablePropsLite {
    to?: string,
    onClick?(): void
}

interface EditProps extends ClickablePropsLite {
    icon?: "gear" | "pen"
}

interface DeleteProps extends ClickablePropsLite {
    icon?: "ban" | "trash"
}

export default function TableActions({
    edit,
    delete: deleteProp
}: {
    edit?: EditProps,
    delete?: DeleteProps
}) {
    return (
        <Row gap="25px" vAlign="center" className="table-actions">
            {edit && (
                <Clickable {...edit}>
                    {edit.icon === "pen" ? <PenIcon className="settings" /> : <GearIcon className="settings" />}
                </Clickable>
            )}
            {deleteProp && (
                <Clickable {...deleteProp}>
                    {deleteProp.icon === "ban" ? <BanIcon className="delete" /> : <TrashIcon className="delete" />}
                </Clickable>
            )}
        </Row>
    )
}