import "@/css/components/hashtag-list-insertion.scss";
import { Hashtag as HashtagInterface } from "@/js/resources";
import SortDown from "@/svg/sort-down-solid.svg?react";
import SortBothIcon from "@/svg/sort-solid.svg?react";
import SortUp from "@/svg/sort-up-solid.svg?react";
import compare from "@enymo/comparison";
import { Column, Row } from "@enymo/react-layout";
import classNames from "classnames";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import useBreakpoint from "../Hooks/BreakpointHook";
import { useHashtagLists } from "../resources";
import Hashtag from "./Hashtag";
import Loadable from "./Loadable";

export default function HashtagListInsertion({
    onAddHashtag,
}: {
    onAddHashtag: (hashtagId: HashtagInterface | HashtagInterface[]) => void;
}) {
    const { t } = useTranslation();
    const [hashtagLists, { loading }] = useHashtagLists();
    const [selectedHashtagListId, setSelectedHashtagListId] = useState<number | null>(null);
    const [hashtagSortDirection, setHashtagSortDirection] = useState<"up" | "down" | null>(null);
    const selectedHashtagListHashtags = useMemo(
        () => (hashtagLists.find(({ id }) => id === selectedHashtagListId)?.hashtags ?? [])
            .sort((a, b) => compare(a.name, b.name) * (hashtagSortDirection === "up" ? 1 : -1)),
        [selectedHashtagListId, hashtagLists, hashtagSortDirection]
    );

    const screenSize = useBreakpoint({
        mobile: 500,
        tablet: 1000,
    }) ?? "desktop";

    return (
        <div className={classNames("hashtag-list-insertion", screenSize)}>
            <Loadable loading={loading}>
                <Column className="hashtag-lists-container">
                    {hashtagLists.map(({ id, name }) => (
                        <button
                            className={classNames({ selected: selectedHashtagListId === id })}
                            onClick={() => setSelectedHashtagListId(id)}
                            key={id}
                        >
                            {name}
                        </button>
                    ))}
                </Column>
            </Loadable>
            <Column gap="21px" flex={1}>
                <Row align="space">
                    <button
                        className="hashtags-text-button"
                        onClick={() => setHashtagSortDirection((prev) => prev === "up" ? "down" : "up")}
                        disabled={selectedHashtagListId === null}
                    >
                        <span>{t("posts.create.texts.hashtags.sort")}</span>
                        {(() => {
                            switch (hashtagSortDirection) {
                                case "up":
                                    return <SortUp />
                                case "down":
                                    return <SortDown />
                                default:
                                    return <SortBothIcon />
                            }
                        })()}
                    </button>
                    <button
                        className="hashtags-text-button"
                        onClick={() => onAddHashtag(selectedHashtagListHashtags)}
                        disabled={selectedHashtagListId === null}
                    >
                        {t("posts.create.texts.hashtags.addAll")}
                    </button>
                </Row>
                <div className="hashtags-wrap">
                    {selectedHashtagListId !== null ? (
                        selectedHashtagListHashtags.length > 0 ? (
                            selectedHashtagListHashtags.map((hashtag) => (
                                <Hashtag
                                    key={hashtag.id}
                                    type="in-post-selection"
                                    hashtag={hashtag}
                                    onClick={() => onAddHashtag(hashtag)}
                                />
                            ))
                        ) : (
                            <span className="hashtags-placeholder">{t("posts.create.texts.hashtags.noItemsInList")}</span>
                        )
                    ) : (
                        <span className="hashtags-placeholder">{t("posts.create.texts.hashtags.selectList")}</span>
                    )}
                </div>

            </Column>
        </div>
    )
}