import Splash from "@/js/Components/Splash";
import { useApp } from "@/js/Providers/AppProvider";
import { useToaster } from "@/js/Providers/ToasterProvider";
import { EmailRegex } from "@/js/common";
import { Button, Input } from "@/js/glidespec";
import { Form, SubmitHandler } from "@enymo/react-form-component";
import { Column } from "@enymo/react-layout";
import { AxiosError } from "axios";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import route from "ziggy-js";

interface Submit {
    email: string
}

export default function ForgotPassword() {
    const {t} = useTranslation();
    const form = useForm<Submit>();
    const {axios} = useApp();
    const toast = useToaster();

    const [requested, setRequested] = useState<string | null>(null);

    const handleSubmit: SubmitHandler<Submit> = async ({email}) => {
        await axios.post(route("users.forgot-password"), {email});
        setRequested(email);
    }

    const handleReRequest = async () => {
        try {
            await axios.post(route("users.forgot-password"), {email: requested});
            toast({
                type: "success",
                title: t("forgotPassword.resent.title"),
                text: t("forgotPassword.resent.text")
            });
        }
        catch (e) {
            if (e instanceof AxiosError && e.response && e.response.status === 400 && e.response.data.errors.email === "passwords.throttled") {
                toast({
                    type: "error",
                    title: t("passwords.throttled.title"),
                    text: t("passwords.throttled.text")
                })
            }
            else {
                throw e;
            }
        }
    }

    if (requested !== null) {
        return (
            <Column maxWidth="480px" width="100%" gap="30px">
                <Splash title={t("forgotPassword.requested.title")}>{t("forgotPassword.requested.subtitle")}</Splash>
                <span className="lockscreen-link large">
                    <Trans i18nKey="forgotPassword.resend">
                        Didn't get the email? <button className="link" onClick={handleReRequest}>Request again</button>
                    </Trans>
                </span>
            </Column>
        )
    }
    else {
        return (
            <Column gap="50px" maxWidth="480px" width="100%">
                <Splash title={t("forgotPassword.title")}>{t("forgotPassword.subtitle")}</Splash>
                <Form form={form} onSubmit={handleSubmit}>
                    <Column gap="30px">
                        <Input className="large" name="email" options={{
                            required: t("input.email.required"),
                            pattern: {
                                value: EmailRegex,
                                message: t("input.email.pattern")
                            }
                        }} label={t("input.email")} />
                        <Button variant="primaryLogin" submit>{t("forgotPassword.submit")}</Button>
                        <span className="lockscreen-link">
                            <Link to="/login" className="link">{t("forgotPassword.toLogin")}</Link>
                        </span>
                    </Column>
                </Form>
            </Column>
        )
    }
}