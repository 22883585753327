import compare from "@enymo/comparison";
import * as Sentry from "@sentry/react";
import { useEffect } from "react";
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from "react-router";

export type SentryListener = (event: Sentry.Event, hint: Sentry.EventHint) => Sentry.Event | null;
const listeners: {
    listener: SentryListener,
    priority: number | null
}[] = [];

Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
    environment: import.meta.env.VITE_APP_ENV,
    integrations: [
        Sentry.reactRouterV6BrowserTracingIntegration({
            useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes
        }),
        Sentry.replayIntegration()
    ],
    tracesSampleRate: import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE,
    tracePropagationTargets: ["localhost", /^https:\/\/sentry\.internal\.enymo\.com\/api/],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1,
    beforeSend: (event, hint) => {
        let current: Sentry.Event | null = event;
        for (const listener of listeners.map(({listener}) => listener)) {
            if (current === null) {
                break;
            }
            current = listener(current, hint);
        }
        return current;
    }
});

export function addSentryListener(listener: SentryListener, priority: number | null = null)  {
    listeners.push({listener, priority});
    listeners.sort((a, b) => compare(a.priority, b.priority));
}

export function removeSentryListener(listener: SentryListener) {
    const index = listeners.findIndex(value => value.listener === listener);
    if (index !== -1) {
        listeners.splice(index, 1);
    }
}