import "@/css/glidespec.scss";
import CheckIcon from "@/svg/check-regular.svg?react";
import SelectIcon from "@/svg/chevron-down-regular.svg?react";
import ChevronLeftIcon from "@/svg/chevron-left-regular.svg?react";
import EyeIcon from "@/svg/eye-regular.svg?react";
import EyeSlashIcon from "@/svg/eye-slash-regular.svg?react";
import { createGlideButton, createGlideCheckboxList, createGlideChoice, createGlideInput, createGlideRadioGroup } from "@enymo/glide";
import { Column, Row } from "@enymo/react-layout";
import classNames from "classnames";
import React, { useState } from "react";
import { RegisterOptions } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Error from "./Components/Error";
import Loader from "./Components/Loader";
import Tooltip, { TooltipParent } from "./Components/Tooltip";
import { EmailRegex } from "./common";

export const Button = createGlideButton({
    style: {
        fontWeight: 500,
        fontFamily: "'Noto Sans', sans-serif",
    },
    variants: {
        ".filled": {
            style: {
                padding: "0 16px",
                borderRadius: "12px",
                color: "var(--white)",
                fill: "var(--white)",
            },
            disabledStyle: {
                background: "#B5B4BB"
            }
        },
        primaryLogin: {
            extends: ".filled",
            style: {
                height: "48px",
                background: "var(--primary-500)",
                fontSize: "16px",
                padding: "0 "
            },
            hoverStyle: {
                background: "#236EE0"
            }
        },
        primary: {
            extends: ".filled",
            style: {
                height: "42px",
                background: "var(--primary-500)",
                fontSize: "15px"
            },
            hoverStyle: {
                background: "#236EE0"
            }
        },
        primarySmall: {
            style: {
                padding: "0 8px",
                borderRadius: "8px",
                color: "var(--white)",
                fill: "var(--white)",
                height: "32px",
                background: "var(--primary-500)",
                fontSize: "14px"
            },
            disabledStyle: {
                background: "#B5B4BB"
            },
            hoverStyle: {
                background: "#236EE0"
            }
        },
        social: {
            style: {
                borderRadius: "12px",
                height: "42px",
                background: "var(--bg-400)",
                color: "var(--text-700)",
                fontSize: "15px",
                padding: "0 15px"
            },
            hoverStyle: {
                background: "var(--bg-500)"
            }
        },
        danger: {
            extends: ".filled",
            style: {
                height: "42px",
                background: "var(--error)",
                fontSize: "15px"
            },
            hoverStyle: {
                background: "#F92359"
            }
        },
        dangerText: {
            style: {
                fontSize: "14px",
                color: "var(--error)",
                fill: "var(--error)"
            },
            disabledStyle: {
                color: "var(--neutral-500)"
            }
        },
        secondary: {
            style: {
                height: "48px",
                fontSize: "15px",
                color: "var(--text-700)"
            },
            hoverStyle: {
                color: "var(--text-900)"
            }
        },
        link: {
            style: {
                display: "inline"
            }
        },
        gray: {
            style: {
                height: "32px",
                borderRadius: "6px",
                padding: "0 8px",
                background: "var(--bg-500)",
                fontSize: "14px",
                color: "var(--text-700)",
                fill: "var(--text-700)"
            }
        },
        outlineSmall: {
            style: {
                height: "32px",
                border: "1px solid var(--primary-500)",
                borderRadius: "8px",
                fontSize: "14px",
                color: "var(--primary-500)",
                fill: "var(--primary-500)",
            },
            hoverStyle: {
                borderColor: "#236EE0",
                color: "#236EE0",
                fill: "#236EE0"
            },
            disabledStyle: {
                borderColor: "var(--neutral-300)",
                color: "var(--neutral-300)",
                fill: "var(--neutral-300)"
            }
        }
    },
    defaultVariant: "secondary",
    loader: <Loader style={{maxWidth: "100%", maxHeight: "100%"}} />,
    loaderPadding: "5px"
});

export type ButtonProps = typeof Button extends (props: infer P) => unknown ? P : never;

export function BackButton({className, children, ...props}: Omit<ButtonProps, "variant">) {
    return (
        <Button className={classNames("back-button", className)} variant="gray" {...props}>
            <ChevronLeftIcon />
            <span>{children}</span>
        </Button>
    )
}

export const Input = createGlideInput({
    labelPosition: "outside-top",
    labelStyle: {
        fontWeight: 500,
        fontSize: "15px",
        color: "var(--text-700)"
    },
    labelGap: "12px",
    style: {
        border: "2px solid var(--neutral-200)",
        borderRadius: "12px",
        background: "var(--bg-100)",
        color: "var(--text-900)",
        fontSize: "16px"
    },
    hoverStyle: {
        borderColor: "#CFD4D9"
    },
    focusStyle: {
        borderColor: "var(--primary-400)"
    },
    errorStyle: {
        borderColor: "var(--error)"
    },
    disabledStyle: {
        background: "var(--bg-300)"
    },
    placeholderStyle: {
        color: "var(--text-100)"
    },
    inputPadding: "0 16px",
    suffix: ({icon}: {
        icon?: React.ReactNode
    }) => icon && (
        <div className="suffix">
            {icon}
        </div>
    ),
    errorComponent: Error,
    errorGap: "10px",
    selectIndicator: ({className}: {
        className?: string,
    }) => (
        <div className={classNames("select-indicator", className)}>
            <SelectIcon height="15px" width="30px" />
        </div>
    )
});
type InputProps = typeof Input extends (props: infer P) => unknown ? P : never;

export function EmailInput({options, label, name = "email", ...props}: Omit<InputProps, "type">) {
    const {t} = useTranslation();

    return (
        <Input name={name} type="email" label={label ?? t("input.email")} options={{
            ...options,
            pattern: {
                value: EmailRegex,
                message: t("input.email.pattern")
            }
        } as RegisterOptions} {...props} />
    )
}

export function PasswordInput({className, ...props}: Omit<InputProps, "type" | "icon">) {
    const [visible, setVisible] = useState(false);

    return (
        <Input
            className={classNames("password-input", className)}
            type={visible ? "text" : "password"}
            icon={
                <button type="button" onClick={() => setVisible(!visible)}>
                    {visible ? <EyeSlashIcon className="eye-slash" /> : <EyeIcon className="eye" />}
                </button>
            }
            {...props}
        />
    )
}

export const Checkbox = createGlideChoice({
    indicator: {
        style: {
            width: "26px",
            height: "26px",
            border: "1px solid var(--neutral-200)",
            borderRadius: "8px",
            background: "var(--bg-100)"
        },
        selectedStyle: {
            borderColor: "var(--primary-500)",
            background: "var(--primary-500)"
        },
        element: <CheckIcon width="18px" fill="white" />
    },
    childrenGap: "10px",
    labelStyle: {
        margin: "5px 0",
        fontSize: "14px",
        color: "var(--text-700)"
    },
    wrapperStyle: {
        cursor: "pointer"
    },
    errorComponent: Error
})

const SocialAccountRadioInput = createGlideChoice({
    className: "social-select",
    wrapperStyle: {
        border: "2px solid var(--neutral-200)",
        borderRadius: "8px",
        background: "var(--bg-100)",
        padding: "14px 20px"
    },
    disabledWrapperStyle: {
        background: "var(--bg-300)"
    },
    selectedWrapperStyle: {
        borderColor: "var(--primary-500)",
        background: "var(--primary-100)"
    },
    element: ({icon: Icon, title, subtitle}: {
        icon: React.FC<React.SVGProps<SVGSVGElement>>,
        title: React.ReactNode,
        subtitle?: React.ReactNode
    }) => (
        <Row gap="20px" vAlign="center">
            <Icon width="40px" height="40px" />
            <Column gap="2px">
                <span className="title">{title}</span>
                <span className="subtitle">{subtitle}</span>
            </Column>
        </Row>
    )
})

type SocialAccountRadioProps = typeof SocialAccountRadioInput extends React.FC<infer P> ? P : never;

export function SocialAccountRadio(props: SocialAccountRadioProps) {
    const {t} = useTranslation();

    return (
        <TooltipParent style={{width: "auto"}}>
            <SocialAccountRadioInput {...props} />
            {props.disabled && <Tooltip>{t("planExceeded.owner")}</Tooltip>}
        </TooltipParent>
    )
}

export const PeriodRadio = createGlideChoice({
    wrapperStyle: {
        display: "flex",
        borderRadius: "10px",
        height: "38px",
        padding: "0 12px",
        fontSize: "15px",
        fontWeight: 500,
        color: "var(--text-500)",
        cursor: "pointer",
        flex: "auto"
    },
    selectedWrapperStyle: {
        background: "var(--primary-200)",
        color: "var(--primary-500)"
    },
    childrenVerticalAlignment: "center"
});

export const RadioGroup = createGlideRadioGroup({});

export const CheckboxList = createGlideCheckboxList({});

const SlideSwitchInner = createGlideRadioGroup({
    style: {
        flexDirection: "row",
        gap: "6px",
        padding: "4px 5px",
        borderRadius: "12px",
        background: "var(--bg-400)",
        height: "42px"
    }
});

type SlideSwitchInner = typeof SlideSwitchInner extends (props: infer P) => unknown ? P : never;

interface SlideSwitchProps extends Omit<SlideSwitchInner, "handlesError"> {
    label?: string
}

export function SlideSwitch({label, ...props}: SlideSwitchProps) {
    return (
        <div className="slide-switch">
            {label && <label>{label}</label>}
            <SlideSwitchInner handlesError {...props} />
        </div>
    )
}

export const SlideSwitchItem = createGlideChoice({
    className: "slide-switch-item",
    wrapperStyle: {
        padding: "0 18px",
        borderRadius: "10px",
        fontSize: "15px",
        fontWeight: 500,
        color: "var(--text-500)",
        cursor: "pointer"
    },
    labelStyle: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    selectedWrapperStyle: {
        background: "var(--white)",
        color: "var(--text-700)"
    }
});
export const CheckboxSmall = createGlideChoice({
    indicator: {
        style: {
            width: "20px",
            height: "20px",
            border: "1px solid var(--neutral-200)",
            borderRadius: "6px",
            background: "var(--bg-100)"
        },
        selectedStyle: {
            borderColor: "var(--primary-500)",
            background: "var(--primary-500)"
        },
        disabledStyle: {
            background: "var(--neutral-300)",
            borderColor: "var(--neutral-300)",
        },
        element: <CheckIcon width="10px" fill="white" />
    },
    childrenGap: "10px",
    childrenVerticalAlignment: "center",
    labelStyle: {
        fontSize: "15px",
        fontWeight: "400",
        color: "var(--text-900)"
    },
    disabledWrapperStyle: {
        cursor: "not-allowed"
    },
    wrapperStyle: {
        cursor: "pointer",
        width: "fit-content"
    },
    errorComponent: Error
})

export const RadioButton = createGlideChoice({
    indicator: {
        style: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "20px",
            height: "20px",
            background: "var(--bg-100)",
            border: "2px solid var(--neutral-200)",
            borderRadius: "50%"
        },
        selectedStyle: {
            borderColor: "var(--primary-500)",
            background: "var(--primary-500)"
        },
        element: <div style={{
            width: "10px",
            height: "10px",
            borderRadius: "50%",
            background: "var(--bg-100)"
        }} />
    },
    childrenGap: "8px",
    childrenVerticalAlignment: "center",
})