import "@/css/pages/main/social-accounts.scss";
import AvatarWithLogo from "@/js/Components/AvatarWithLogo";
import ConfirmPopup from "@/js/Components/ConfirmPopup";
import InfoPopup from "@/js/Components/InfoPopup";
import Popup, { PopupFooter } from "@/js/Components/Popup";
import SearchField from "@/js/Components/SearchField";
import SocialAccountSettingsPopup from "@/js/Components/SocialAccountSettingsPopup";
import SocialMediaIcon from "@/js/Components/SocialMediaIcon";
import Status from "@/js/Components/Status";
import Table from "@/js/Components/Table";
import TableActions from "@/js/Components/TableActions";
import Tooltip, { TooltipParent } from "@/js/Components/Tooltip";
import useBreakpoint from "@/js/Hooks/BreakpointHook";
import { useTitle } from "@/js/Layouts/Main";
import { useConfirmPopup } from "@/js/Providers/ConfirmPopupProvider";
import { useUser } from "@/js/Providers/UserProvider";
import { channelAvatar, initials } from "@/js/common";
import { Button, RadioGroup, SocialAccountRadio } from "@/js/glidespec";
import { SocialChannelStatus, useSocialChannels } from "@/js/resources";
import { SocialAccountType } from "@/js/types";
import plans from "@/json/plans.json";
import FacebookIcon from "@/svg/facebook-logo.svg?react";
import LinkedInIcon from "@/svg/linkedin-logo.svg?react";
import RepeatIcon from "@/svg/repeat-duotone.svg?react";
import SquareShareNodesIcon from "@/svg/square-share-nodes-duotone.svg?react";
import TikTokIcon from "@/svg/tiktok-logo.svg?react";
import YouTubeIcon from "@/svg/youtube-logo.svg?react";
import { Column, Row } from "@enymo/react-layout";
import { assertNotNull, requireNotNull } from "@enymo/ts-nullsafe";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import route from "ziggy-js";

const errorTypes = ["inUse"] as const;
type ErrorType = typeof errorTypes[number];

function isError(input: string): input is ErrorType {
    return errorTypes.includes(input as ErrorType);
}

function SocialAccountLabel({type}: {
    type: SocialAccountType
}) {
    const {t} = useTranslation();

    return (
        <div className="account-label">
            <SocialMediaIcon type={type} />
            <span>{t(`social.${type}`)}</span>
        </div>
    )
}

function SocialAccountStatus({status, type, hAlign}: {
    status: SocialChannelStatus,
    type: SocialAccountType,
    hAlign: "left" | "right"
}) {
    const {t} = useTranslation();

    return (
        <Column gap="4px" hAlign={hAlign}>
            {status === "ok" ? (
                <Status color="green">{t("socialAccounts.table.status.ok")}</Status>
            ) : <>
                {status === "will_expire" ? (
                    <Status color="yellow">{t("socialAccounts.table.status.willExpire")}</Status>
                ) : (
                    <Status color="red">{t("socialAccounts.table.status.expired")}</Status>
                )}
                
                <a href={getRoute(type)} className="link renew">
                    <RepeatIcon />
                    <span>{t("socialAccounts.table.renew")}</span>
                </a>
            </>}
        </Column>
    )
}

function getRoute(type: SocialAccountType) {
    switch (type) {
        case "youtube":
            return route("oauth.google", {action: "connect"});
        case "tiktok":
            return route("oauth.tiktok");
        default:
            return route(`oauth.${type}`, {action: "connect"});
    }
}

export default function SocialAccounts() {
    const {t} = useTranslation();
    const {user} = useUser();
    assertNotNull(user, "User must be logged in");
    const withPopup = useConfirmPopup();
    const [search, setSearch] = useState("");
    const [showAddPopup, setShowAddPopup] = useState(false);
    const [showDeletePopup, setShowDeletePopup] = useState<number | null>(null);
    const [showSettingsPopup, setShowSettingsPopup] = useState<number | null>(null);
    const [selectedAddSocial, setSelectedAddSocial] = useState<SocialAccountType>();
    const [searchParams, setSearchParams] = useSearchParams();
    const [socialChannels, {destroy, refresh}] = useSocialChannels();
    const [error, setError] = useState<ErrorType | null>(null);
    const canAddAccounts = useMemo(() => plans[user.subscription_tier].social_accounts + user.billed_subscription.additional_social_accounts - socialChannels.length, [user, socialChannels]);
    const filteredSocialChannels = useMemo(() => socialChannels.filter(({name}) => name.toLowerCase().includes(search.toLowerCase())), [socialChannels, search])
    const mobile = useBreakpoint(1100);
    
    useEffect(() => {
        const settings = searchParams.get("settings");
        const error = searchParams.get("error");
        if (settings !== null) {
            setShowSettingsPopup(Number(settings));
        }
        else if (error !== null) {
            if (isError(error)) {
                setError(error);
            }
        }
        else {
            return;
        }
        setSearchParams({}, {replace: true});
    }, [searchParams, setSearchParams, setShowSettingsPopup, setError]);

    const connectRoute = useMemo(() => {
        if (selectedAddSocial === undefined) {
            return "#";
        }
        else {
            return getRoute(selectedAddSocial);
        }
    }, [selectedAddSocial]);
    
    useTitle(t("socialAccounts"));

    const handleClosePopup = () => {
        setShowAddPopup(false);
        setSelectedAddSocial(undefined);
    }

    const handleCloseSettings = (shouldRefresh: boolean) => {
        setShowSettingsPopup(null);
        if (shouldRefresh) {
            refresh();
        }
    }

    const handleDeleteSocialAccount = () => {
        destroy(requireNotNull(showDeletePopup));
        setShowDeletePopup(null);
    }

    return (
        <Column gap="30px" className="social-accounts">
            <Row align="space" padding={mobile ? "25px 17px 0 17px" : "60px 50px 0 50px"}>
                <TooltipParent>
                    <Button variant="primary" disabled={canAddAccounts <= 0 && !user.admin} onClick={() => setShowAddPopup(true)}>{mobile ? t("socialAccounts.add.mobile") : t("socialAccounts.add")}</Button>
                    {canAddAccounts <= 0 && !user.admin && <Tooltip>{t("planExceeded.owner")}</Tooltip>}
                </TooltipParent>
                <SearchField maxWidth={mobile ? "200px" : "320px"} placeholder={mobile ? t("search") : t("socialAccounts.search")} value={search} onChange={setSearch} />
            </Row>
            <Column padding={mobile ? "0 0 60px 0" : "0 50px 60px 50px"}>
                <Table
                    striped
                    mobileBreakpoint={1100}
                    head={[
                        {
                            label: t("socialAccounts.table.account"),
                            fillWidth: true
                        },
                        ...(mobile ? [] : [{
                            label: t("socialAccounts.table.platform")
                        }, {
                            label: t("socialAccounts.table.status")
                        }]),
                        {
                            label: t("socialAccounts.table.actions")
                        }
                    ]}
                    rows={filteredSocialChannels.map(({
                        id,
                        name,
                        has_avatar,
                        icon_type,
                        account: {
                            type: account_type,
                            status
                        }
                    }) => ({
                        id,
                        data: [
                            <Row gap="20px" vAlign="center">
                                {mobile ? <>
                                    <AvatarWithLogo type={icon_type} size={48} src={channelAvatar({id, has_avatar})} initials={initials(name)} />
                                    <Column gap="5px">
                                        <span className="name">{name}</span>
                                        <SocialAccountStatus status={status} hAlign="left" type={account_type} />
                                    </Column>
                                </> : <>
                                    <AvatarWithLogo 
                                        type={icon_type}
                                        size={48}
                                        src={channelAvatar({id, has_avatar})} 
                                        initials={initials(name)}
                                    />
                                    <span className="name">{name}</span>
                                </>}
                            </Row>,
                            ...(mobile ? [] : [
                                <SocialAccountLabel type={icon_type} />,
                                <SocialAccountStatus status={status} hAlign="right" type={account_type} />
                            ]), 
                            <TableActions delete={{onClick: () => setShowDeletePopup(id), icon: "ban"}} />
                        ]
                    }))}
                    paddingVertical="8px"
                    placeholder={search ? t("socialAccounts.table.placeholder.search") : t("socialAccounts.table.placeholder")}
                />
            </Column>
            {showAddPopup && (
                <Popup onBackgroundClick={handleClosePopup} maxWidth="500px" width="100%">
                    <Column padding="30px 25px" gap="30px">
                        <Row gap="15px" vAlign="center">
                            <SquareShareNodesIcon className="primary-500" width="35px" style={{ flexShrink: 0 }} />
                            <h1>{t("socialAccounts.add.title")}</h1>
                        </Row>
                        <Column gap="20px">
                            <p>{t("socialAccounts.add.text")}</p>
                            <RadioGroup value={selectedAddSocial} onChange={setSelectedAddSocial} handlesError gap="12px">
                                <SocialAccountRadio disabled={!user.admin && !plans[user.subscription_tier].facebook} icon={FacebookIcon} title={t("socialAccounts.facebook")} subtitle={!mobile && t("socialAccounts.facebook.description")} value="facebook" />
                                <SocialAccountRadio disabled={!user.admin && !plans[user.subscription_tier].youtube} icon={YouTubeIcon} title={t("socialAccounts.youtube")} subtitle={!mobile && t("socialAccounts.youtube.description")} value="youtube" />
                                <SocialAccountRadio disabled={!user.admin && !plans[user.subscription_tier].tiktok} icon={TikTokIcon} title={t("socialAccounts.tiktok")} subtitle={!mobile && t("socialAccounts.tiktok.description")} value="tiktok" />
                                <SocialAccountRadio disabled={!user.admin && !plans[user.subscription_tier].linkedin} icon={LinkedInIcon} title={t("socialAccounts.linkedin")} subtitle={!mobile && t("socialAccounts.linkedin.description")} value="linkedin" />
                            </RadioGroup>
                        </Column>
                    </Column>
                    <PopupFooter>
                        <Button variant="secondary" onClick={handleClosePopup}>{t("cancel")}</Button>
                        <Button 
                            variant="primary" 
                            disabled={selectedAddSocial === undefined} 
                            to={connectRoute}
                            linkType="no-router"
                        >{t("continue")}</Button>
                    </PopupFooter>
                </Popup>
            )}
            {showDeletePopup !== null && (
                <ConfirmPopup
                    variant="warning"
                    confirmText={t("socialAccounts.delete.button")}
                    title={t("socialAccounts.delete.title")}
                    onConfirm={handleDeleteSocialAccount}
                    onCancel={() => setShowDeletePopup(null)}
                >{t("socialAccounts.delete.text")}</ConfirmPopup>
            )}
            {showSettingsPopup !== null && (
                <SocialAccountSettingsPopup activationLimit={canAddAccounts} socialAccountId={showSettingsPopup} onClose={handleCloseSettings} />
            )}
            {error !== null && (
                <InfoPopup
                    title={t(`socialAccounts.error.${error}.title`)}
                    onClose={() => setError(null)}
                    variant="warning"
                    confirmText={t("close")}
                >{t(`socialAccounts.error.${error}.text`)}</InfoPopup>
            )}
        </Column>
    )    
}