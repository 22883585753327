import FacebookIcon from "@/svg/facebook-logo.svg?react";
import GoogleIcon from "@/svg/google.svg?react";
import LinkedInIcon from "@/svg/linkedin-logo.svg?react";
import { Row } from "@enymo/react-layout";
import React from "react";
import { useTranslation } from "react-i18next";
import route from "ziggy-js";
import { Button } from "../glidespec";

interface SocialButtonProps {
    flex?: number,
    disabled?: boolean
}

export function GoogleButton({flex, disabled}: SocialButtonProps) {
    const {t} = useTranslation();

    return (
        <Button flex={flex} variant="social" linkType="no-router" to={route("oauth.google", {action: "login"})} disabled={disabled}>
            <GoogleIcon />
            <span>{t("social.google")}</span>
        </Button>
    )
}

export function FacebookButton({flex, disabled}: SocialButtonProps) {
    const {t} = useTranslation();

    return (
        <Button flex={flex} variant="social" linkType="no-router" to={route("oauth.facebook", {action: "login"})} disabled={disabled}>
            <FacebookIcon />
            <span>{t("social.facebook")}</span>
        </Button>
    )
}

export function LinkedInButton({flex, disabled}: SocialButtonProps) {
    const {t} = useTranslation();

    return (
        <Button flex={flex} variant="social" linkType="no-router" to={route("oauth.linkedin", {action: "login"})} disabled={disabled}>
            <LinkedInIcon />
            <span>{t("social.linkedin")}</span>
        </Button>
    )
}

export default function SocialButtons() {
    return (
        <Row gap="15px">
            <GoogleButton flex={1} />
            <FacebookButton flex={1} />
            <LinkedInButton flex={1} />
        </Row>
    )
}