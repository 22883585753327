import "@/css/components/month-day-select.scss";
import { createGlideChoice } from "@enymo/glide";
import { Column } from "@enymo/react-layout";
import React from "react";
import { useTranslation } from "react-i18next";
import { CheckboxList } from "../glidespec";
import { FormInputProps } from "../types";

interface MonthDaySelectProps extends FormInputProps<(number | "last")[]> {
    label?: string
}

const MonthDaySelectItem = createGlideChoice({
    wrapperStyle: {
        borderRadius: "8px",
        background: "var(--bg-400)",
        fontSize: "14px",
        fontWeight: 500,
        color: "var(--text-500)",
        cursor: "pointer"
    },
    labelStyle: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    selectedWrapperStyle: {
        border: "2px solid var(--primary-400)",
        color: "var(--primary-500)"
    }
});

export default function MonthDaySelect({
    label,
    ...props
}: MonthDaySelectProps) {
    const {t} = useTranslation();

    return (
        <Column gap="8px">
            {label && <label className="input-label">{label}</label>}
            <CheckboxList className="month-day-select-grid" handlesError {...props}>
                {Array<void>(31).fill().map((_, index) => (
                    <MonthDaySelectItem key={index} value={index + 1}>{index + 1}</MonthDaySelectItem>
                ))}
                <MonthDaySelectItem className="last" value="last">{t("input.monthDaySelect.last")}</MonthDaySelectItem>
            </CheckboxList>
        </Column>
    )
}