import { createRequiredContext } from "@enymo/react-better-context";
import { assertNotNull } from "@enymo/ts-nullsafe";
import React, { useCallback, useState } from "react";
import ConfirmPopup from "../Components/ConfirmPopup";

type WithPopup = (
    variant: "warning" | "default",
    confirmText: string,
    title: React.ReactNode,
    children: React.ReactNode,
    onConfirm?: () => Promise<void>,
    onCancel?: () => void
) => Promise<boolean>

const [Provider, useConfirmPopup] = createRequiredContext<WithPopup>("ConfirmPopupProvider must be present in component tree");

export { useConfirmPopup };
export default function ConfirmPopupProvider({children}: {
    children: React.ReactNode
}) {
    const [popupConfig, setPopupConfig] = useState<{
        variant: "warning" | "default",
        confirmText: string,
        title: React.ReactNode,
        children: React.ReactNode,
        onConfirm?: () => Promise<void>,
        onCancel?: () => void,
        resolve: (result: boolean) => void
    } | null>(null);

    const withPopup = useCallback<WithPopup>((variant, confirmText, title, children, onConfirm, onCancel) => new Promise(resolve => {
        setPopupConfig({variant, confirmText, title, children, onConfirm, onCancel, resolve});
    }), [setPopupConfig]);

    const handleConfirm = useCallback(async () => {
        assertNotNull(popupConfig);
        await popupConfig.onConfirm?.();
        popupConfig.resolve(true);
        setPopupConfig(null);
    }, [popupConfig, setPopupConfig]);

    const handleCancel = useCallback(() => {
        assertNotNull(popupConfig);
        popupConfig.onCancel?.();
        popupConfig.resolve(false);
        setPopupConfig(null);
    }, [popupConfig, setPopupConfig]);

    return <>
        <Provider value={withPopup}>
            {children}
        </Provider>
        {popupConfig !== null && (
            <ConfirmPopup 
                {...popupConfig} 
                onConfirm={handleConfirm} 
                onCancel={handleCancel}
            />
        )}
    </>
}