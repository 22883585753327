import "@/css/components/switcher.scss";
import useHybridInput from "@enymo/react-hybrid-input-hook";
import classNames from "classnames";
import React from "react";
import { RegisterOptions } from "react-hook-form";

export interface SwitcherProps {
    id?: string,
    name?: string,
    value?: boolean,
    onChange?: (value: boolean) => void,
    options?: RegisterOptions,
    disabled?: boolean
}

export default function Switcher({
    id,
    name,
    value: externalValue,
    onChange: externalOnChange,
    options,
    disabled = false
}: SwitcherProps) {

    const { value, onChange } = useHybridInput({ name, externalOnChange, externalValue, defaultValue: false, options });
    return (
        <label className={classNames("switcher", {disabled})}>
            <input
                id={id}
                type="checkbox"
                checked={value}
                onChange={e => onChange(e.target.checked)}
                disabled={disabled}
            />
            <div>
                <div />
            </div>
        </label>
    )
}