import "@/css/layouts/main.scss";
import LogoutIcon from "@/svg/arrow-right-from-bracket-light.svg?react";
import MenuIcon from "@/svg/bars-regular.svg?react";
import CalendarIcon from "@/svg/calendars-regular.svg?react";
import AnalyticsIcon from "@/svg/chart-mixed-regular.svg?react";
import AccountCircleIcon from "@/svg/circle-user-light.svg?react";
import DashboardIcon from "@/svg/grid-regular.svg?react";
import HashtagIcon from "@/svg/hashtag-regular.svg?react";
import CampaignsIcon from "@/svg/list-regular.svg?react";
import Logo from "@/svg/logo.svg?react";
import SupportIcon from "@/svg/messages-question-light.svg?react";
import AIIcon from "@/svg/microchip-ai-regular.svg?react";
import SocialIcon from "@/svg/share-nodes-regular.svg?react";
import SortDownIcon from "@/svg/sort-down-solid.svg?react";
import UsersIcon from "@/svg/user-group-regular.svg?react";
import { createRequiredContext } from '@enymo/react-better-context';
import useOnClickOutside from '@enymo/react-click-outside-hook';
import { Clickable, ClickableProps } from "@enymo/react-clickable-router";
import { Column, Row } from '@enymo/react-layout';
import useScreenSize from "@enymo/react-screen-size-hook";
import { assertNotNull } from "@enymo/ts-nullsafe";
import classNames from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { assert } from 'ts-essentials';
import { UserAvatar } from "../Components/UserAvatar";
import { useUser } from "../Providers/UserProvider";
import { SUPPORT_URL, version } from '../common';
import { Button } from '../glidespec';


const [TitleProvider, useSetTitle] = createRequiredContext<((title: string) => void)>("Main layout must be present in component tree");
const [SideBarProvider, useSidebar] = createRequiredContext<((open: boolean) => void)>("Main layout must be present in component tree");


export const useTitle = (title: string) => {
    const setTitle = useSetTitle();

    useEffect(() => setTitle(title), [setTitle, title]);
}

function DropdownItem({
    to,
    onClick,
    icon,
    children,
    linkType,
    gap = '14px',
}: {
    to?: string,
    onClick?: () => void,
    icon: React.ReactNode,
    children: React.ReactNode,
    linkType?: ClickableProps["linkType"],
    gap?: string,
}) {
    assert(to || onClick, "Either to or onClick must be defined");

    return (
        <Clickable onClick={onClick} to={to} linkType={linkType} className='dropdown-button'>
            <Row gap={gap} vAlign='center'>
                {icon}
                <span>{children}</span>
            </Row>
        </Clickable>
    )

}

function SidebarItem({
    to,
    icon,
    children,
}: {
    to: string,
    icon: React.ReactNode,
    children: React.ReactNode,
}) {

    const location = useLocation();
    const setSidebarOpen = useSidebar();

    const active = useMemo(() => location.pathname.startsWith(to), [location.pathname, to])

    return (
        <Link 
            to={to} 
            onClick={() => !active && setSidebarOpen(false)} 
            className={classNames('sidebar-item', { active })}
        >
            {icon}
            <span>{children}</span>
        </Link>
    )
}

export default function Main({ children }: {
    children?: React.ReactNode,
}) {
    const { t } = useTranslation();
    const { user, logout } = useUser();

    assertNotNull(user, "User must be logged in");

    const [title, setTitle] = useState<string>("");
    const [showDropdown, setShowDropdown] = useState<boolean>(false);
    const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);

    const profileDropdownRef = useOnClickOutside<HTMLDivElement>(() => setShowDropdown(false), [setShowDropdown]);

    const { width } = useScreenSize();

    const isMobile = width <= 768; // TODO


    return (
        <SideBarProvider value={setSidebarOpen}>
            <div className='main-layout'>
                <div className={classNames("fade", { open: sidebarOpen })} onClick={() => setSidebarOpen(false)}>
                    <div className='sidebar' onClick={(e) => e.stopPropagation()}>
                        <Column gap={isMobile ? '38px' : '55px'}>
                            <Logo className='logo' />
                            <Column gap='80px'>
                                <Column gap='6px' flex={1}>
                                    <SidebarItem to='/app/dashboard' icon={<DashboardIcon width="18px" />}>
                                        {t("sidebar.dashboard")}
                                    </SidebarItem>
                                    <SidebarItem to='/app/campaigns' icon={<CampaignsIcon width="20px" />}>
                                        {t("sidebar.campaigns")}
                                    </SidebarItem>
                                    <SidebarItem to='/app/social-accounts' icon={<SocialIcon width="18px" />}>
                                        {t("sidebar.socialAccounts")}
                                    </SidebarItem>
                                    <SidebarItem to='/app/calendar' icon={<CalendarIcon width="20px" />}>
                                        {t("sidebar.calendar")}
                                    </SidebarItem>
                                    <SidebarItem to='/app/hashtags' icon={<HashtagIcon width="18px" />}>
                                        {t("sidebar.hashtags")}
                                    </SidebarItem>
                                    <SidebarItem to='/app/analytics' icon={<AnalyticsIcon width="20px" />}>
                                        {t("sidebar.analytics")}
                                    </SidebarItem>
                                </Column>
                                {user.admin && <Column gap='13px'>
                                    <span className='admin-title'>{t("sidebar.admin")}</span>
                                    <Column gap='6px' flex={1}>
                                        <SidebarItem to='/app/admin/users' icon={<UsersIcon width="20px" />}>
                                            {t("sidebar.admin.users")}
                                        </SidebarItem>
                                        <SidebarItem to='/app/admin/ai' icon={<AIIcon width="20px" />}>
                                            {t("sidebar.admin.ai")}
                                        </SidebarItem>
                                    </Column>
                                </Column>}
                            </Column>
                        </Column>
                        {["free", "plus"].includes(user.subscription_tier) && (
                            <div className='pro-upgrade'>
                                <Column gap='6px'>
                                    <span className='title'>{t("sidebar.proUpgrade.title")}</span>
                                    <span className='subtitle'>{t("sidebar.proUpgrade.subtitle")}</span>
                                </Column>
                                <Button variant='primary' to="/app/settings/subscription">{t("sidebar.proUpgrade.button")}</Button>
                            </div>
                        )}
                    </div>
                </div>
                <Column flex={1} className="main-content">
                    <div className='navbar'>
                        <Row gap="19px" vAlign="center">
                            <button className="menu-button" onClick={() => setSidebarOpen((sidebarOpen) => !sidebarOpen)}>
                                <MenuIcon />
                            </button>
                            <h1>{title}</h1>
                        </Row>
                        <div ref={profileDropdownRef}>
                            <button className='profile' onClick={() => setShowDropdown(!showDropdown)}>
                                <UserAvatar size={isMobile ? 34 : 36} />
                                <SortDownIcon width='10px' />
                            </button>
                            {showDropdown && (
                                <Column className='profile-dropdown'>
                                    <div className='user-fields'>
                                        <UserAvatar size={46} />
                                        <Column gap='1px' align='center'>
                                            <span className='name'>{t("user.fullName", {
                                                firstName: user.first_name,
                                                lastName: user.last_name,
                                            })}</span>
                                            <span className='subscription'>
                                                {t(`subscriptionTier.${user.subscription_tier}`) } {["free", "plus"].includes(user.subscription_tier) && (
                                                    <Link to='/app/settings/subscription'>Upgrade</Link>
                                                )}
                                            </span>
                                        </Column>
                                    </div>
                                    <div className='settings'>
                                        <DropdownItem to='/app/settings/general' icon={<AccountCircleIcon width="16px" />}>
                                            {t("navbar.settings")}
                                        </DropdownItem>
                                        <DropdownItem to={SUPPORT_URL} linkType="new-tab" icon={<SupportIcon width="18px" />} gap='12px'>
                                            {t("navbar.support")}
                                        </DropdownItem>
                                        <DropdownItem onClick={logout} icon={<LogoutIcon width="16px" />}>
                                            {t("navbar.logout")}
                                        </DropdownItem>
                                    </div>
                                    <div className='version'>
                                        <span>{t("navbar.version", { version })}</span>
                                        <span>{t("navbar.copyrightFred")}</span>
                                    </div>
                                </Column>
                            )}
                        </div>
                    </div>
                    <main>
                        <TitleProvider value={setTitle}>
                            {children ?? <Outlet />}
                        </TitleProvider>
                    </main>
                </Column>
            </div>
        </SideBarProvider>
    )
}