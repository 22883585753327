import "@/css/components/hashtag-difficulty-bar.scss";
import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import { HashtagDifficulty, hashtagDifficulties, hashtagDifficultyColors } from "../common";
import Tooltip, { TooltipParent } from "./Tooltip";

export default function HashtagDifficultyBar({
    difficulty,
    type = "standalone",
    highlight = false
}: {
    difficulty?: HashtagDifficulty,
    type?: "standalone" | "in-tooltip",
    highlight?: boolean,
}) {
    const { t } = useTranslation();
    return (
        <TooltipParent>
            <div className={classNames("hashtag-difficulty-bar", type, { highlight })}>
                <div className="fill" style={{
                    width: difficulty ? `${(hashtagDifficulties.indexOf(difficulty) + 1) / (hashtagDifficulties.length) * 90}%` : "0%",
                    backgroundColor: difficulty ? hashtagDifficultyColors[difficulty] : "var(--neutral-200)"
                }} />
            </div>
            {difficulty && type === "standalone" && (
                <Tooltip>
                    {t(`hashtagDifficulty.${difficulty}`)}
                </Tooltip>
            )}
        </TooltipParent>
    );
}