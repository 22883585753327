import "@/css/components/status.scss";
import React from "react";

export default function Status({color, children}: {
    color: "green" | "yellow" | "red",
    children: React.ReactNode
}) {
    return (
        <div className={`status ${color}`}>
            <div />
            <span>{children}</span>
        </div>
    )
}